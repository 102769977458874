import React, { ReactElement } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ApiPaymentService } from '@/Service/Api/ApiPaymentService';

interface PaymentCallbackProps {
  paymentStatus: string;
  navigation: NavigateFunction;
}

export default class PaymentCallback extends React.Component
  <PaymentCallbackProps, any>
{
  paymentService: ApiPaymentService;

  constructor(props: any)
  {
    super(props);

    // Service
    this.paymentService = new ApiPaymentService();

    // Document title
    document.title = 'Publisur - Merci pour votre paiement';
  }

  async componentDidMount(): Promise<void>
  {
    const urlParams = new URLSearchParams(window.location.search);
    const requiredParams = [
      'mid', 'PayID', 'XID', 'TransID', 'refnr', 'Type', 'pt', 'CardHolder', 'card', 'PCNr', 'CCExpiry', 'CCBrand', 'Status', 'Code', 'Description', 'MAC'
    ];
    // Check if all required params are present
    const allParamsPresent = requiredParams.every((param:string) => urlParams.has(param));

    if (!allParamsPresent) {
      this.props.navigation('/not-found'); // Redirect to not found page if parameters are missing
      return;
    }

    // Get the TransID from URL params
    const transId = urlParams.get('TransID');

    if (transId) {
      // Call payment route on API
      const response = await this.paymentService.paymentSuccess(parseInt(transId as string), urlParams, this.props.paymentStatus);
      console.log(response);
      // TODO : Handle the response !!
    }
  }

  render(): ReactElement
  {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <h2>Merci pour votre paiement !</h2>
        <p>Vous allez recevoir par mail votre attestion.</p>
        <p>Vous pouvez fermer cette fenêtre</p>
      </div>
    );
  }
}
