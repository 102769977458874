import React, { ReactElement } from 'react';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuPlus } from 'react-icons/lu';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import { FormBuilderInputTypeEnum } from '@/Enum/FormBuilderInputTypeEnum';
import { ApiAdminFormBuilderSectionService } from '@/Service/Admin/ApiAdminFormBuilderSectionService';

interface ComponentProps
{
	parentTypeOption: string | null
	label: string,
	listOptions: any[],
	onUpdateSection: (typeOptionData: FormBuilderInputTypeEnum) => void,
	addButton: {
		onClick: (event: any) => void
	}
}

interface ComponentState
{
	selectedTypeOption: FormBuilderInputTypeEnum | null;
}

export default class FormBuilderSectionMultipleOption extends React.Component<ComponentProps, ComponentState>
{
	formBuilderSectionService: ApiAdminFormBuilderSectionService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.formBuilderSectionService = new ApiAdminFormBuilderSectionService();

		// Bind
		this.handleEditSelectOption = this.handleEditSelectOption.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				<div style={ { width: '100%', display: 'flex', alignItems: 'flex-end', gap: 15 } }>
					<SelectComponent
						label={ 'type de choix' }
						containerDivWidth={ '300px' }
						listOptions={ this.buildListOption() }
						selectedValue={ this.findValue(this.props.parentTypeOption) }
						onSelectedOption={ (option: any) => this.handleEditSelectOption(option) }
						renderOptionLabel={ (option: any) => option.label }
					/>

					<Button
						type={ 'default-blue' }
						label={ 'Ajouter une sous-section' }
						iconLeft={ <LuPlus/> }
						onClick={ this.props.addButton.onClick }
					/>
				</div>

				<div style={{ width: '100%', marginTop: 20 }}>
					{ this.state.selectedTypeOption === FormBuilderInputTypeEnum.SELECTOR &&
            <SelectComponent
							key={ this.props.label }
              label={ this.props.label }
              containerDivWidth={ '300px' }
              listOptions={ this.props.listOptions }
              onSelectedOption={ () => null }
              renderOptionLabel={ (option: any) => option.label }
            />
					}

					{ this.state.selectedTypeOption === FormBuilderInputTypeEnum.CHECKBOX &&
            <div style={ { width: '100%', display: 'flex', gap: 15 } }>
							{ this.props.listOptions && this.props.listOptions.map((option: any, index: number) => (
								<Checkbox
									key={ `checkbox-${option.id}-${index}` }
									label={ option.label }
									options={{
										rowLeftCheckbox: true,
									}}
									name={ `checkbox-${ option.id }` }
									isChecked={ true }
									onCheckedChange={ () => null }
								/>
							)) }
            </div>
					}
				</div>
			</>
		);
	}

	componentDidMount(): void
	{
		this.setState({ selectedTypeOption: this.findValue(this.props.parentTypeOption)?.value });
	}

	private initState(): ComponentState
	{
		return {
			selectedTypeOption: null,
		};
	}

	private buildListOption(): any[]
	{
		return [
			{ label: 'Sélecteur', value: FormBuilderInputTypeEnum.SELECTOR },
			{ label: 'Checkbox', value: FormBuilderInputTypeEnum.CHECKBOX },
			{ label: 'Aucun', value: null }
		];
	}

	private handleEditSelectOption(option: { label: string, value: FormBuilderInputTypeEnum }): void
	{
		this.setState({
			selectedTypeOption: option.value
		});

		this.props.onUpdateSection(option.value);
	}

	private findValue(value: string | null): { label: string, value: FormBuilderInputTypeEnum | null }
	{
		return this.buildListOption().find(option => option.value === value);
	}

}