export default class LegalNoticeFileTypeEnum {
  value: string;
  label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }

  static CERTIFICATE = new LegalNoticeFileTypeEnum('CERTIFICATE', 'Certificat');
  static WEB_CERTIFICATE  = new LegalNoticeFileTypeEnum('WEB_CERTIFICATE', 'Attestation numérique');

  static typeList: LegalNoticeFileTypeEnum[] = [
    LegalNoticeFileTypeEnum.CERTIFICATE,
    LegalNoticeFileTypeEnum.WEB_CERTIFICATE,
  ];

  static findByValue(value: string): LegalNoticeFileTypeEnum|undefined
  {
    return this.typeList.find(option => option.value === value);
  }
}
