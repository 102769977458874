import React, { ReactElement } from 'react';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { NavigateFunction } from 'react-router-dom';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { Alert } from 'react-bootstrap';
import { LuArrowUpRightSquare, LuEye, LuFileOutput } from 'react-icons/lu';
import { PaymentInterface } from '@/Modules/Payment/Interface/PaymentInterface';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';
import { stringToCapitalize } from '@/Utils/StringToCapitalizeUtils';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { CreateLegalNoticeStyle } from '@/Modules/LegalNotice/Style/CreateLegalNoticeStyle';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { LegalNoticeRenderStyle } from '@/Modules/LegalNotice/Style/LegalNoticeRenderStyle';
import LegalNoticePriceComponent from '@/Modules/LegalNotice/Components/LegalNoticePriceComponent';
import OffCanvasComponent from '@/Modules/App/Components/Atom/OffCanvas/OffCanvasComponent';
import LegalNoticeRender from '@/Modules/LegalNotice/Components/Render/LegalNoticeRender';

interface ViewProps
{
  navigate: NavigateFunction,
  location: Location,
  flashMessageContext: FlashMessageContextType,
}

interface ViewState
{
  legalNotice: LegalNoticeInterface|null,
  payment: PaymentInterface|null,
  paymentLink: string|null,
  isRenderOpen: boolean,
  errorMessage: string|null,
}

export default class PaymentView extends React.Component<ViewProps, ViewState>
{
  publicService: ApiPublicService;
  navigate: NavigateFunction;

  constructor(props: any)
  {
    super(props);

    // Services
    this.publicService = new ApiPublicService();

    // Bind
    this.handleError = this.handleError.bind(this);

    // State
    this.state = this.initState();

    // Navigate
    this.navigate = props.navigation;

    // Document title
    document.title = 'Publisur - Validation de votre paiement';
  }

  render(): ReactElement
  {
    return (
      <>
        { this.state.errorMessage
          ? this.errorMessageRender()
          : <>
            <Title type={'h2'} style={{ marginTop: 0 }}>Récaputilatif de votre annonce</Title>
            <div style={ CreateLegalNoticeStyle.sideContainerStyle() }>
              {/* LEFT SIDE */ }
              <div style={ { gridColumn: '1 / 2' } }>
                { this.orderDetailRender() }
              </div>
              {/* RIGHT SIDE */ }
              <div style={ { gridColumn: '2 / 3' } }>
                { this.legalNoticeDetailRender() }
              </div>
            </div>

            {/* OFF CANVAS */ }
            { this.state.legalNotice &&
              <OffCanvasComponent
                show={ this.state.isRenderOpen }
                onHide={ () => this.setState({ isRenderOpen: false }) }
                isNavigateArrow={ false }
                isTabNeeded={ false }
                width={ 620 }
              >
                <div style={ { ...FontStyle.h2(), textAlign: 'center', marginBottom: 30 } }>Aperçu de votre annonce légale</div>
                <LegalNoticeRender
                  legalNotice={ this.state.legalNotice as any }
                  consumer={ this.state.legalNotice?.consumer }
                  isShow={ false }
                />
              </OffCanvasComponent>
            }

          </>
        }
      </>
    );
  }

  //<editor-fold desc="Render method" efaultstate="collapsed">

  private errorMessageRender(): ReactElement
  {
    return (
      <>
        <Alert variant={'danger'} style={{ textAlign: 'center' }}>
          { this.state.errorMessage }
          <br/>
          Vous allez être redirigé vers l'accueil
        </Alert>
      </>
    );
  }

  private orderDetailRender(): ReactElement
  {
    const legalNoticeData: any = this.state.legalNotice;
    if (!legalNoticeData) {
      return <LoaderFullPageComponent/>;
    }

    return (
      <>
        <div style={ { display: 'flex', flexDirection: 'column', gap: 10 } }>
          <div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 } }>
            <div style={ { ...CardStyle.cardContainer() } }>
              <div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Type de l'annonce</div>
              { this.lineDetails('Catégorie principale :', stringToCapitalize(legalNoticeData.formBuilderCategory.parent.label)) }
              { this.lineDetails('Sous-catégorie :', legalNoticeData.formBuilderCategory.label) }
            </div>
            <div style={ { ...CardStyle.cardContainer() } }>
              <div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Configuration de l'annonce</div>
              { this.lineDetails('Type de journal :', stringToCapitalize(legalNoticeData.option.publishType)) }
              { this.lineDetails('Département :', legalNoticeData.publishDepartment.name) }
              { this.lineDetails('Journal :', stringToCapitalize(legalNoticeData.newspaper.name)) }
            </div>
          </div>

          <div style={ { ...CardStyle.cardContainer() } }>
            <div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Société</div>
            { this.lineDetails('Dénomination :', stringToCapitalize(legalNoticeData.consumer?.name)) }
            { this.lineDetails('SIREN :', legalNoticeData.consumer?.siren) }
            { this.lineDetails('RCS :', legalNoticeData.consumer?.rcs) }
            { this.lineDetails('Forme juridique :', legalNoticeData.consumer?.legalStatus) }
            { this.lineDetails('Capital :', `${ legalNoticeData.consumer?.capital } €`) }
            { this.lineDetails('Numéro :', (legalNoticeData.consumer?.address.number) ? legalNoticeData.consumer?.address.number : '') }
            { this.lineDetails('Rue :', stringToCapitalize(legalNoticeData.consumer?.address.street)) }
            { this.lineDetails('Code Postale :', legalNoticeData.consumer?.address.zipCode) }
            { this.lineDetails('Ville :', stringToCapitalize(legalNoticeData.consumer?.address.city)) }
          </div>

          <div style={ { ...CardStyle.cardContainer() } }>
            <div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Adresses de Facturation</div>

            { this.lineDetails('Rue', `${ legalNoticeData.billingAddress?.number } ${ legalNoticeData.billingAddress?.street }`)}
            { this.lineDetails('Code Postale: ', legalNoticeData.billingAddress?.zipCode) }
            { this.lineDetails('Ville: ', legalNoticeData.billingAddress?.city) }
          </div>
        </div>
      </>
    );
  }

  private legalNoticeDetailRender(): ReactElement
  {
    const legalNoticeData: any = this.state.legalNotice;
    if (!legalNoticeData) {
      return <LoaderFullPageComponent/>;
    }

    return (
      <>
        <div style={ { ...CardStyle.cardContainer() } }>
          <Title type={ 'h2' } style={ { marginTop: 0 } }>Récaputilatif du prix de votre annonce</Title>
          <LegalNoticePriceComponent
            legalNotice={ legalNoticeData }
            selectedDepartment={ legalNoticeData.publishDepartment }
            selectedCategory={ legalNoticeData.formBuilderCategory }
            isTableOffCanvas={ true }
            isAdmin={ false }
          />
        </div>
        <div style={ { ...CardStyle.cardContainer(), marginTop: 10, height: '205px' } }>
          <Title type={ 'h2' } style={ { marginTop: 0 } }>Facture / Contenu de l'annonce</Title>
          { this.legalNoticeDocumentRender() }
        </div>
        <div style={ { display: 'flex', justifyContent: 'end' } }>
          <div style={ LegalNoticeRenderStyle.renderStaticMessage() }>
            Rendez-vous sur la page sécurisé de la BNP-Paribas et leurs solution "Axepta"
          </div>
          <Button
            iconLeft={<LuArrowUpRightSquare/>}
            type={ 'success' } style={ { marginTop: 10 } }
            onClick={ () => this.onPaymentLink() }
            disabled={ Boolean(!this.state.paymentLink) }
          >
            Payer l'annonce (AXEPTA)
          </Button>
        </div>
      </>
    );
  }

  private legalNoticeDocumentRender(): ReactElement
  {
    const billings = this.state.legalNotice?.legalNoticeFiles.filter((file: any) => file.type === 'INVOICE');

    return (
      <>
        { billings && billings.length > 0 &&
          <>
            { billings.map((file: any, index: number) => (
              <React.Fragment key={ index }>
                <div style={ { marginTop: 20, width: '33%', gap: 10 } } key={ file.id }>
                  <div
                    style={ { width: '100%', marginBottom: '10px' } }
                    key={ index }>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button type={ 'tag-blue' } onClick={ () => {this.getFile(file) }}>
                        <LuFileOutput/> <span style={{ marginLeft: 10 }}>Voir ma facture</span>
                      </Button>

                      <Button type={ 'default-dark' } onClick={ () => { this.setState({ isRenderOpen: true }) }}>
                        <LuEye/> <span style={{ marginLeft: 10 }}>Aperçu de mon annonce</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )) }
          </>
        }
      </>
    );
  }

  private async getFile(file: any): Promise<void>
  {
    if (file.extSellsyId) {
      window.open(file.file, '_blank');
    } else {
      await this.publicService.getFile(this.state.legalNotice?.id as number, file.type)
        .then((blob: any) =>
        {
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        })
        .catch((error: string) => console.error('Error downloading file:', error))
      ;
    }
  }

  private lineDetails(label: string, data: string | number | null, splitColumn: boolean = true): ReactElement
  {
    return (
      <>
        <div style={ { display: 'grid', gridTemplateColumns: '150px auto', gap: 10 } }>
          <div style={ {
            width: '100%',
            ...FontStyle.littleGrey()
          } }>
            { label }
          </div>
          <div style={ {
            width: '100%',
            ...FontStyle.normalMedium()
          } }>
            { data }
          </div>
        </div>
      </>
    );
  }

  //</editor-fold>

  //<editor-fold desc="Private method" efaultstate="collapsed">

  async componentDidMount(): Promise<any>
  {
    const queryParams = new URLSearchParams(this.props.location.search);
    const getPaymentCode = queryParams.get('code');

    if (getPaymentCode) {
      await this.handleComponentApiCall(getPaymentCode);
    } else {
      this.handleError('Aucun code de paiement.');
    }
  }

  private async handleComponentApiCall(getPaymentCode: string): Promise<any>
  {
    const getPayment = await this.publicService.paymentDetail(getPaymentCode);

    if (getPayment.errorMessage) {
      this.handleError('Paiement introuvable.');
    } else {
      if (getPayment.processed) {
        this.handleError('Le paiement pour cette annonce a déjà été traité.');
      }

      const getLegalNotice = await this.publicService.getLegalNotice(parseInt(getPayment.transId));

      const paymentLink = await this.publicService.generatePaymentLink(
        parseInt(getPayment.transId),
        getLegalNotice.company.id
      );

      // Set State
      this.setState({
        payment: getPayment,
        paymentLink,
        legalNotice: getLegalNotice
      });
    }
  }

  private handleError(message: string): void
  {
    this.setState({ errorMessage: message });

    setTimeout(() =>
    {

      this.props.flashMessageContext.flashMessage(
        'Erreur sur le paiement',
        message,
        'error'
      );

      this.navigate('/auth');
    }, 3000);
  }

  private onPaymentLink(): void
  {
    if (this.state.paymentLink) {
      window.open(this.state.paymentLink, '_blank');
    }
  }

  private initState(): ViewState
  {
    return {
      legalNotice: null,
      payment: null,
      paymentLink: null,
      isRenderOpen: false,
      errorMessage: null
    }
  }

  //</editor-fold>
}
