import React, { CSSProperties, ReactElement } from 'react';
import { Editor, EditorState, RichUtils, Modifier, ContentState, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';

interface WysiwygProps
{
	initialContent: string,
	onContentChange: (content: string) => void,
	style?: CSSProperties,
}

interface WysiwygState
{
	editorState: EditorState;
}

export class Wysiwyg extends React.Component<WysiwygProps, WysiwygState>
{
	constructor(props: WysiwygProps)
	{
		super(props);

		const contentState = ContentState.createFromText(this.props.initialContent);
		const editorState = EditorState.createWithContent(contentState);
		this.state = { editorState };

		// Bind
		this.onChange = this.onChange.bind(this);
		this.handleKeyCommand = this.handleKeyCommand.bind(this);
		this.applyFormatting = this.applyFormatting.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				{ this.renderToolbar() }
				<div style={ this.editorStyle() }>
					<div style={ { padding: '10px', ...this.props.style } }>
						<Editor
							editorState={ this.state.editorState }
							handleKeyCommand={ this.handleKeyCommand }
							onChange={ this.onChange }
						/>
					</div>
				</div>
			</>
		);
	}

	private renderToolbar(): ReactElement
	{
		return (
			<div style={ {
				backgroundColor: CssVariableEnum['--color-grey-100'],
				padding: '5px 10px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				border: `1px solid ${ CssVariableEnum['--color-grey-200']}`,
				marginBottom: '-1px',
				borderTopRightRadius: '8px',
				borderTopLeftRadius: '8px',
			} }>
				<Button
					type={'inline-default'}
					style={{ fontSize: 12 }}
					onMouseDown={ (event) =>
					{
						event.preventDefault();
						this.applyFormatting('BOLD');
					} }
				>
					Bold
				</Button>
				<Button
					type={'inline-default'}
					style={{ fontSize: 12 }}
					onMouseDown={ (event) =>
					{
						event.preventDefault();
						this.transformText((text: any) => text.toUpperCase());
					} }
				>
					AB
				</Button>
				<Button
					type={'inline-default'}
					style={{ fontSize: 12 }}
					onMouseDown={ (event) =>
					{
						event.preventDefault();
						this.transformText((text: any) => text.toLowerCase());
					} }
				>
					ab
				</Button>
			</div>
		);
	}

	private onChange(editorState: EditorState): void
	{
		this.setState({ editorState }, () =>
		{
			const contentState = editorState.getCurrentContent();
			const rawContent = convertToRaw(contentState);
			const stringifiedContent = JSON.stringify(rawContent);
			this.props.onContentChange(stringifiedContent);
		});
	}

	private handleKeyCommand(command: string): 'handled' | 'not-handled'
	{
		const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
		if (newState) {
			this.onChange(newState);
			return 'handled';
		}

		return 'not-handled';
	}

	private applyFormatting(style: string): void
	{
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style));
	}

	private transformText(transform: (text: string) => string): any
	{
		const { editorState } = this.state;
		const selection = editorState.getSelection();
		if (!selection.isCollapsed()) {
			const contentState = editorState.getCurrentContent();
			const startKey = selection.getStartKey();
			const startOffset = selection.getStartOffset();
			const endOffset = selection.getEndOffset();
			const blockWithTransformedText = Modifier.replaceText(
				contentState,
				selection,
				transform(contentState.getBlockForKey(startKey).getText().slice(startOffset, endOffset))
			);

			this.onChange(EditorState.push(editorState, blockWithTransformedText, 'change-inline-style'));
		}
	}

	private editorStyle(): CSSProperties
	{
		return {
			border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			backgroundColor: CssVariableEnum['--color-grey-25'],
			overflow: 'scroll',
			overflowX: 'hidden',
			height: 'calc(100% - 45px)'
		};
	}
}
