import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

export default class LoaderComponent extends React.Component
	<{ timeout?: number, height?: number | string, variant?: string }, { showLoader: boolean }>
{
	private timeoutId: NodeJS.Timeout | null = null;

	constructor(props: any)
	{
		super(props);
		this.state = {
			showLoader: true
		};
	}

	componentDidMount(): void
	{
		const timeout: number = (this.props.timeout) ? this.props.timeout : 2000;

		this.timeoutId = setTimeout(() =>
		{
			this.setState({ showLoader: false });
		}, timeout);
	}

	componentWillUnmount(): void
	{
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
	}

	render(): ReactElement
	{
		return (
			<>
				{ this.state.showLoader && (
					<div style={ {
						width: '100%',
						height: (this.props.height) ? this.props.height : '',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					} }>
						<div>
							<Spinner
								animation="border"
								variant={ (this.props.variant) ? this.props.variant : 'dark' }
							/>
						</div>
					</div>
				) }
			</>
		);
	}
}