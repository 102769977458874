import React from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';

export default class ForgotPasswordView extends React.Component
  <
    {  flashMessageContext: FlashMessageContextType, navigation: NavigateFunction },
    { email: string, newPassword: string, user: UserInterface | null, errorMessage: string|null }
  >
{
  publicService: ApiPublicService;
  state = {
    email: '',
    newPassword: '',
    user: null,
    errorMessage: null
  };

  constructor(props: any)
  {
    super(props);

    // Services
    this.publicService = new ApiPublicService();

    // Bind
    this.submitFormAccountHandler = this.submitFormAccountHandler.bind(this);
    this.formAccountContent = this.formAccountContent.bind(this);

    // Document title
    document.title = 'Publisur - Mot de passe oublié';
  }

  render()
  {
    return (
      <>
        { this.formAccountContent() }

        <Link to="/auth" className="mt-5">
          Annuler
        </Link>
      </>
    );
  }

  private formAccountContent()
  {
    return (
      <>
        <h2>Trouver votre compte</h2>
        <p className="mt-4 mb-4">Veuillez entrer votre e-mail pour rechercher votre compte</p>

        { this.state.errorMessage
          &&<div className="error-message" role="alert">{ this.state.errorMessage }</div>
        }
        <Form className="w-25" method="post" onSubmit={ this.submitFormAccountHandler }>
          <Form.Group className="block-field">
            <Form.Control
              type="text"
              name="email"
              placeholder="Votre email"
              className="input"
              value={ this.state.email }
              onChange={ (event) => this.onChangeEmail(event) }
              required
            />
          </Form.Group>
          <div className="input-submit">
            <Button type="submit" variant="primary" className="btn-primary--blue">
              Trouver son compte
            </Button>
          </div>
        </Form>
      </>
    );
  }

  private onChangeEmail(event: React.ChangeEvent<any>): void
  {
    this.setState({ email: event.target.value });
  }

  private async submitFormAccountHandler(event: React.FormEvent<HTMLFormElement>)
  {
    event.preventDefault();

    try {
      const response = await this.publicService.checkAccountExist(this.state.email);

      if (response.errorMessage) {
        this.setState({ errorMessage: response.errorMessage, user: null });
        return;
      }

      if (response.status === ActiveStatusEnum.INACTIVE.value) {
        // Set Flash message
        this.props.flashMessageContext.flashMessage(
          'Votre compte est inactif',
          'Merci de contacter un admin, pour ré-activer votre compte',
          'error'
        );
        // navigate
        this.props.navigation('/auth');

        return;
      }

      await this.publicService.askForgotPassword({ email: this.state.email });

      // Set Flash message
      this.props.flashMessageContext.flashMessage(
        'Demande de changement mot de passe bien enregistré',
        'Vous allez recevoir un email pour la procédure de changement de mot de passe',
        'success'
      );
      // navigate
      this.props.navigation('/auth');

      this.setState({ errorMessage: null, user: response });
    } catch (error) {
      console.log(error);
    }
  }
}