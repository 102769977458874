import React, { ReactElement } from 'react';
import HeroSection from '@/Modules/App/Components/Atom/Content/HeroSection';
import Content from '@/Modules/App/Components/Atom/Content/Content';
import MainWrapper from '@/Modules/App/Components/Atom/Wrapper/MainWrapper';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { CollaboratorInterface } from '@/Modules/Collaborator/Interface/CollaboratorInterface';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { NewspaperInterface } from '@/Modules/LegalNotice/Interface/NewspaperInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { ConsumerDataInterface } from '@/Modules/LegalNotice/Interface/ConsumerDataInterface';
import { AddressInterface } from '@/Modules/Client/Interface/AddressInterface';
import { ViewLegalNoticeState } from '@/Modules/LegalNotice/Admin/View/CreateLegalNoticeView';
import { Location, NavigateFunction } from 'react-router-dom';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { ModalContextType } from '@/Provider/ModalProvider';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { BilledToTypeEnum } from '@/Modules/Client/Enum/BilledToTypeEnum';
import { AuthClientContextType } from '@/Provider/Interface/AuthClient/AuthClientContextType';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { ApiClientService } from '@/Service/Api/ApiClientService';
import { ApiCollaboratorService } from '@/Service/Api/ApiCollaboratorService';
import { LegalNoticeService } from '@/Service/LegalNoticeService';
import { UserService } from '@/Service/UserService';
import BlockConfigComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockConfigComponent';
import BlockCategoryComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockCategoryComponent';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { InputType } from '@/Modules/App/Components/Atom/Form/Input/Type/InputType';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import { CreateLegalNoticeStyle } from '@/Modules/LegalNotice/Style/CreateLegalNoticeStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import HeaderOptionContentComponent from '@/Modules/LegalNotice/Components/Form/Content/HeaderOptionContentComponent';
import LegalNoticeRender from '@/Modules/LegalNotice/Components/Render/LegalNoticeRender';
import LegalNoticePriceFormComponent from '@/Modules/LegalNotice/Components/Form/LegalNoticePriceFormComponent';
import { FormBuilderInputTypeEnum } from '@/Enum/FormBuilderInputTypeEnum';
import FormBuilderStyle from '@/Modules/App/Style/Components/FormBuilderStyle';
import Textarea from '@/Modules/App/Components/Atom/Form/Textarea';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import BlockSendToComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockSendToComponent';
import { SendToSelections } from '@/Modules/LegalNotice/Components/Form/LegalNoticeSendToFormComponent';
import Radio from '@/Modules/App/Components/Atom/Form/Radio';
import { LegalNoticeUserModal } from '@/Modules/LegalNotice/Components/LegalNoticeUserModal';
import { LuPlus, LuTrash2, LuXCircle } from 'react-icons/lu';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { ApiLegalNoticeService } from '@/Service/Api/ApiLegalNoticeService';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import Swal from 'sweetalert2';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import SirenInputComponent from '@/Modules/App/Components/Atom/Form/SirenInputComponent';

interface ViewProps
{
	navigate: NavigateFunction,
	location: Location,
	flashMessageContext: FlashMessageContextType,
	modalContext: ModalContextType,
	navigation: NavigateFunction,
	clientContext: AuthClientContextType,
	authContext: AuthContextType,
}

interface ViewState
{
	isDataLoading: boolean,
	formData: ViewStateFormData,
	departmentList: DepartmentInterface[],
	newspaperList: NewspaperInterface[],
	formBuilderCategoryList: FormBuilderCategoryInterface[],
	formBuilderSections: FormBuilderSectionInterface[],
	isConsumerFormComplete: boolean
	legalNoticeContent: ViewCmsContentState[],
	subSectionsVisible: {
		parentId: number,
		index: number | null,
	}[],
	progressProcess: number,
	duplicateSection: {
		realSectionId: number,
		items: FormBuilderSectionInterface[],
	}[];
}

interface ViewCmsContentState
{
	parentId: number | null,
	sectionId: number,
	inputId: number,
	isRequired: boolean,
	initialValue: string | null,
	updatedValue: string | null,
	selectedValue: any,
	error: string | null,
}

interface ViewStateFormData
{
	selectedClient: ClientInterface | null,
	selectedCollaborator: CollaboratorInterface | null,
	selectedDepartment: DepartmentInterface | null,
	selectedNewspaper: NewspaperInterface | null,
	selectedPrimaryCategory: FormBuilderCategoryInterface | null,
	selectedSecondaryCategory: FormBuilderCategoryInterface | null,
	newspapers: NewspaperInterface[],
	consumer: ConsumerDataInterface | null,
	billingAddress: AddressInterface | null,
	headerCharacterCount: number,
	sendTo: {},
	legalNotice: ViewLegalNoticeState,
}

export default class CreateLegalNoticeGuideView extends React.Component
	<ViewProps, ViewState>
{
	//<editor-fold desc="Ctr" defaultstate="collapsed">

	// Properties
	clientService: ApiClientService;
	collaboratorService: ApiCollaboratorService;
	legalNoticeService: LegalNoticeService;
	apiLegalNoticeService: ApiLegalNoticeService;
	appService: ApiAppService;
	legalNoticePriceService: LegalNoticePriceService;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.legalNoticeService = new LegalNoticeService();
		this.clientService = new ApiClientService();
		this.collaboratorService = new ApiCollaboratorService();
		this.appService = new ApiAppService();
		this.legalNoticePriceService = new LegalNoticePriceService(false);
		this.apiLegalNoticeService = new ApiLegalNoticeService();

		// Bind
		this.prepareLegalNoticeContent = this.prepareLegalNoticeContent.bind(this);
		this.handleSubSectionSelectorVisibility = this.handleSubSectionSelectorVisibility.bind(this);
		this.onChangeLegalNoticeTitle = this.onChangeLegalNoticeTitle.bind(this);
		this.setLegalNoticeContentWithDynamicInputs = this.setLegalNoticeContentWithDynamicInputs.bind(this);
		this.handleDeleteDuplicateSection = this.handleDeleteDuplicateSection.bind(this);
		this.resetFormData = this.resetFormData.bind(this);
		this.concatenatedContent = this.concatenatedContent.bind(this);
		this.resetFormDataModifySiren = this.resetFormDataModifySiren.bind(this);
	}

	//</editor-fold>

	render(): ReactElement
	{

		if (this.state.isDataLoading) {
			return <LoaderFullPageComponent/>;
		}

		console.log(this.state.formData.selectedPrimaryCategory);

		const formData = this.state.formData;
		return (
			<>
				<HeroSection
					title={ 'Nouvelle annonce légale' }
					icon={ null }
				/>
				<Content>
					<MainWrapper>
						<div style={ {
							display: 'grid',
							gridTemplateColumns: '3fr 2fr',
							gap: '20px',
							marginBottom: 20,
							marginTop: 20,
							height: '100%'
						} }>
							<div style={ { gridColumn: '1 / 2', height: '100%' } }>

								<BlockConfigComponent
									key={ this.state.formData.selectedNewspaper?.name }
									isDisplayBlock={ Boolean(formData.selectedClient && formData.selectedCollaborator) }
									departmentList={ this.state.departmentList }
									newspaperList={ this.state.newspaperList }
									newspaperListRequest={ this.newspaperListRequest.bind(this) }
									selectedNewspaperType={ NewspaperTypeEnum.findByValue(formData.legalNotice.option.publishType) }
									onSelectedNewspaperType={ this.onSelectedNewspaperType.bind(this) }
									selectedDepartment={ formData.selectedDepartment }
									onSelectedDepartment={ this.onSelectedDepartment.bind(this) }
									selectedNewspaper={ formData.selectedNewspaper }
									onSelectedNewspaper={ this.onSelectedNewspaper.bind(this) }
									publishDate={ formData.legalNotice.publishDate }
									isForcePublishDate={ false }
									onChangePublishDate={ this.onChangePublishDate.bind(this) }
									onForcePublishDate={ () => null }
									numberOfCopies={ formData.legalNotice.numberOfCopies }
									onChangeNumberOfCopies={ this.onChangeNumberOfCopies.bind(this) }
									reference={ formData.legalNotice.reference }
									onChangeReference={ this.onChangeReference.bind(this) }
									isInputRefNeeded={ false }
									isAdmin={ false }
								/>

								<BlockCategoryComponent
									isDisplayBlock={ Boolean(formData.selectedCollaborator
										&& formData.selectedNewspaper
										&& formData.legalNotice.publishDate
									) }
									FormBuilderCategoryList={ this.state.formBuilderCategoryList }
									selectedPrimaryCategory={ formData.selectedPrimaryCategory }
									onSelectedPrimaryCategory={ this.onSelectedPrimaryCategory.bind(this) }
									selectedSecondaryCategory={ formData.selectedSecondaryCategory }
									onSelectedSecondaryCategory={ this.onSelectedSecondaryCategory.bind(this) }
									onReset={ this.onResetBlockCategory.bind(this) }
								/>

								{ this.state.formData.selectedPrimaryCategory
									&& this.state.formData.selectedPrimaryCategory.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
									&& this.state.formData.selectedSecondaryCategory
									&&
                  <div style={ {
										width: '100%',
										padding: 20,
										display: 'flex',
										alignItems: 'center',
										backgroundColor: CssVariableEnum['--color-blue-light-100'],
										borderStyle: 'solid',
										borderWidth: 1,
										borderColor: CssVariableEnum['--color-blue-light-300'],
										borderRadius: 15,
										marginBottom: 20,
									} }>
                    <div style={ { display: 'flex', alignItems: 'center', gap: 10 } }>
                      <SirenInputComponent
                        sirenData={ this.initStateFromSirenResponse.bind(this) }
                        sirenValue={ this.state.formData.consumer?.siren || '' }
                        isInseeApi={ false }
												isDisabled={ Boolean(this.state.formData.consumer && this.state.formData.consumer.siren !== '') }
                      />
                      <Button
                        label={ 'Modifier' }
                        type={ 'inline-default-blue' }
                        onClick={ () => this.resetFormDataModifySiren() }
                        style={ { marginTop: 5 } }
                      />
                    </div>
                  </div>
								}

								{
									(this.state.formData.consumer?.siren !== '' && this.state.formData.consumer) ||
									(this.state.formData.selectedPrimaryCategory?.categoryType === 'CONSTITUTION'
										&& this.state.formData.selectedSecondaryCategory !== null)  ? (
										<>
											{ this.renderDynamicFormBuilder() }

											{ this.billingAddressRender() }

											<BlockSendToComponent
												isDisplayBlock={ Boolean(formData.selectedNewspaper && formData.billingAddress) }
												selectedClient={ this.state.formData.selectedClient }
												onSelectionMail={ this.onSendToSelected.bind(this) }
												sendTo={ this.state.formData.sendTo }
											/>
										</>
									) : null
								}

							</div>
							<div style={ { gridColumn: '2 / 3', height: '100%' } }>
								{ this.legalNoticeRender() }
							</div>
						</div>
					</MainWrapper>
				</Content>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.setStartingFormDataProcess();
		this.setState({ isDataLoading: false });
	}

	async componentDidUpdate(prevProps: ViewProps, prevState: any): Promise<void>
	{
		if (prevState.formData.legalNotice.option.publishType !== this.state.formData.legalNotice.option.publishType) {
			this.setState({ legalNoticeContent: [], formBuilderSections: [] });
			this.onSelectedNewspaper(null);
			console.log(this.state.formData);
		}

		if (prevState.formBuilderSections !== this.state.formBuilderSections) {
			const orderSectionList = this.getSortedSections(this.state.formBuilderSections);

			let subSectionList: { parentId: number, index: number | null }[] = [];
			orderSectionList.forEach((section: FormBuilderSectionInterface) =>
			{
				if (section.sectionParentTypeOption !== null) {
					subSectionList.push({
						parentId: section.id,
						index: null,
					});
				}
			});

			this.setState({ subSectionsVisible: subSectionList });
		}
	}

	private initState(): ViewState
	{
		return {
			formData: {
				selectedClient: null,
				selectedCollaborator: null,
				selectedDepartment: null,
				selectedNewspaper: null,
				selectedPrimaryCategory: null,
				selectedSecondaryCategory: null,
				newspapers: [],
				consumer: null,
				billingAddress: null,
				headerCharacterCount: 0,
				sendTo: {},
				legalNotice: {
					id: null,
					tag: null,
					title: '',
					content: '',
					signature: '',
					logo: '',
					publishDate: null,
					isForcePublishDate: false,
					discount: 0,
					discountPreference: null,
					numberOfCopies: 0,
					reference: null,
					status: null,
					quoteStatus: null,
					paymentStatus: null,
					option: {
						publishType: NewspaperTypeEnum.WEB,
						billingType: BilledToTypeEnum.ORDER_GIVER,
						isHeader: false,
						isLogo: false,
						isBodacc: false
					}
				},
			},
			departmentList: [],
			newspaperList: [],
			formBuilderCategoryList: [],
			isDataLoading: true,
			isConsumerFormComplete: false,
			formBuilderSections: [],
			legalNoticeContent: [],
			subSectionsVisible: [],
			progressProcess: 0,
			duplicateSection: [],
		};
	}

	private async setStartingFormDataProcess(): Promise<void>
	{
		// Set Department and Category List
		const departments: DepartmentInterface[] = await this.departmentListRequest();
		const formBuilderCategoryList = await this.formBuilderCategoryListRequest();

		this.setState({
			departmentList: departments,
			formBuilderCategoryList: formBuilderCategoryList
		});

		// Set formData with Auth Client
		if (this.props.clientContext && this.props.authContext) {
			const authData = this.setClientAndCollaborator();

			// Set Client options
			if (authData) {
				const selectedDepartment: DepartmentInterface | null = this.setFormDataWithClientOptions(authData.selectedClient);
				const collaboratorEmail: string = authData.selectedCollaborator.email;

				this.onSendToSelected({
					invoice: [collaboratorEmail],
					credit: [collaboratorEmail],
					receipt: [collaboratorEmail],
					certificate: [collaboratorEmail],
				});

				// Set NewspaperList with Selected department from options
				if (selectedDepartment) {
					this.newspaperListRequest(selectedDepartment, this.state.formData.legalNotice.option.publishType);
				}
			}
		}
	}

	private setFormData(update: Partial<ViewState['formData']>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				...update
			}
		}), callback);
	}

	private setStateCustom(update: Partial<ViewState>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			...prevState,
			...update
		}), callback);
	}

	private setFormDataLegalNotice(update: Partial<ViewState['formData']['legalNotice']>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					...update
				}
			}
		}), callback);
	}

	private setFormDataLegalNoticeOption(update: Partial<ViewState['formData']['legalNotice']['option']>, callback?: () => void): void
	{
		this.setState((prevState) => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					option: {
						...prevState.formData.legalNotice.option,
						...update
					}
				}
			}
		}), callback);
	}

	private async newspaperListRequest(department: DepartmentInterface, newspaperType: NewspaperTypeEnum | string): Promise<void>
	{
		const newspaperByDepartment = await this.legalNoticeService.newspaperList(
			department,
			newspaperType
		);
		this.setStateCustom({ newspaperList: newspaperByDepartment });
		if (!this.state.formData.selectedNewspaper) {
			this.setFormData({ selectedNewspaper: null });
		}
	}

	private async formBuilderCategoryListRequest(): Promise<any>
	{
		return await this.appService.categoryList();
	}

	//</editor-fold>

	//<editor-fold desc="Block Config methods" defaultstate="collapsed">

	private async onSelectedNewspaperType(newspaperType: NewspaperTypeEnum): Promise<void>
	{
		this.setFormDataLegalNoticeOption({ publishType: newspaperType });
		this.setFormData({ selectedNewspaper: null });
		this.setFormDataLegalNotice({ numberOfCopies: 0 });

		if (this.state.formData.selectedDepartment) {
			await this.newspaperListRequest(this.state.formData.selectedDepartment, newspaperType);
		}
	}

	private async onSelectedDepartment(department: DepartmentInterface): Promise<void>
	{
		const newspaperByDepartment: NewspaperInterface[] = await this.legalNoticeService.newspaperList(
			department,
			this.state.formData.legalNotice.option.publishType
		);

		this.setFormData({ selectedDepartment: department, selectedNewspaper: null });
		this.setStateCustom({ newspaperList: newspaperByDepartment });
	}

	private onSelectedNewspaper(newspaper: NewspaperInterface | null): void
	{
		if (this.state.legalNoticeContent.length > 0 && this.state.formBuilderSections.length > 0) {
			Swal.fire({
				title: 'Êtes-vous sûr?',
				text: 'Si vous modifiez le journal, votre formulaire sera perdu',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Oui, modifier le journal!',
				cancelButtonText: 'Annuler'
			}).then((result) =>
			{
				if (result.isConfirmed && newspaper) {
					this.resetFormData(newspaper);
				}
			});
		} else {
			this.setFormData({ selectedNewspaper: newspaper });
		}
	}

	private onChangePublishDate(date: Date | null): void
	{
		this.setFormDataLegalNotice({ publishDate: date });
	}

	private onChangeNumberOfCopies(numberOfCopies: number): void
	{
		this.setFormDataLegalNotice({ numberOfCopies });
	}

	private onChangeReference(reference: string): void
	{
		this.setFormDataLegalNotice({ reference });
	}

	//</editor-fold>

	//<editor-fold desc="Block Category methods" defaultstate="collapsed">

	private onSelectedPrimaryCategory(category: FormBuilderCategoryInterface): void
	{
		this.setFormData({ selectedPrimaryCategory: category, selectedSecondaryCategory: null });
		this.setFormDataLegalNoticeOption({ isBodacc: false });
	}

	private onSelectedSecondaryCategory(category: FormBuilderCategoryInterface): void
	{
		this.setFormData({ selectedSecondaryCategory: category }, async () =>
		{
			this.setState({ isDataLoading: true });
			await this.setFormBuilderSections();
		});
	}

	private onResetBlockCategory(): void
	{
		this.setFormData({
			selectedPrimaryCategory: null,
			selectedSecondaryCategory: null,
			consumer: null,
			billingAddress: null,
		});
		this.setState({ isConsumerFormComplete: false });
	}

	//</editor-fold>

	//<editor-fold desc="Block Siren methods" defaultstate="collapsed">

	private initStateFromSirenResponse(sirenResponse: any): void
	{
		const consumerData = {
			siren: sirenResponse.siren || '',
			name: sirenResponse.name || '',
			capital: sirenResponse.capital || '',
			legalStatus: sirenResponse.legalStatus || '',
			rcs: this.state.formData.consumer?.rcs || '',
			extSellsyId: null,
			address: {
				name: null,
				street: sirenResponse.address.street || '',
				number: sirenResponse.address.number || '',
				phone: null,
				additionalData: sirenResponse.address.additionalData || '',
				zipCode: sirenResponse.address.zipCode || '',
				city: sirenResponse.address.city || '',
				country: sirenResponse.address.country || '',
				extSellsyId: null,
				isBillingAddress: true
			}
		};

		this.setFormData({ consumer: consumerData });
		this.setInputsWithSirenResponse(['Société', 'Siège social'], consumerData);
	}

	private setInputsWithSirenResponse(sectionTitles: string[], consumerData: any): void
	{
		const sectionsToUpdate = this.state.formBuilderSections.filter((section: FormBuilderSectionInterface) =>
			sectionTitles.includes(section.title)
		);

		let updatedLegalNoticeContent = [...this.state.legalNoticeContent];

		sectionsToUpdate.forEach((section: FormBuilderSectionInterface) =>
		{
			section.formBuilderInputs.forEach((input: FormBuilderInputsInterface) =>
			{
				updatedLegalNoticeContent = this.updateInputDataWithSirenResponse(input, consumerData, updatedLegalNoticeContent);
			});
		});

		this.setState({ legalNoticeContent: updatedLegalNoticeContent }, () => this.concatenatedContent(this.state.legalNoticeContent));
	}

	//</editor-fold>

	//<editor-fold desc="Block Consumer methods" defaultstate="collapsed">

	private updateInputDataWithSirenResponse(input: FormBuilderInputsInterface, consumerData: any, legalNoticeContent: ViewCmsContentState[]): ViewCmsContentState[]
	{
		let value = '';

		switch (input.label) {
			case 'Siren':
				value = consumerData.siren;
				break;
			case 'Dénomination':
				value = consumerData.name;
				break;
			case 'Capital':
				value = consumerData.capital;
				break;
			case 'Forme juridique':
				value = consumerData.legalStatus;
				break;
			case 'Lieu d\'immatriculation (RCS)':
				value = consumerData.rcs;
				break;
			case 'Nom':
				value = consumerData.address.name;
				break;
			case 'Adresse':
				value = consumerData.address.street;
				break;
			case 'Numéro':
				value = consumerData.address.number;
				break;
			case 'Complément d\'adresse':
				value = consumerData.address.additionalData;
				break;
			case 'Code postal':
				value = consumerData.address.zipCode;
				break;
			case 'Ville':
				value = consumerData.address.city;
				break;
		}

		return legalNoticeContent.map(content =>
		{
			if (content.inputId === input.id) {
				return {
					...content,
					updatedValue: value,
					selectedValue: value,
				};
			}
			return content;
		});
	}

	private setConsumerFormWithDynamicSection(input: FormBuilderInputsInterface, value: any): void
	{
		const { consumer, legalNotice } = this.state.formData;

		// Initialize variables to hold the extracted values
		let siren: string = consumer?.siren ?? '';
		let name: string = consumer?.name ?? '';
		let capital: string = consumer?.capital ?? '';
		let legalStatus: string = consumer?.legalStatus ?? '';
		let rcs: string = consumer?.rcs ?? '';
		let extSellsyId: number | null = consumer?.extSellsyId ?? null;
		let addressName: string = consumer?.address?.name ?? '';
		let street: string = consumer?.address?.street ?? '';
		let number: number | null = consumer?.address?.number ?? null;
		let additionalData: string = consumer?.address?.additionalData ?? '';
		let zipCode: string = consumer?.address?.zipCode ?? '';
		let city: string = consumer?.address?.city ?? '';
		let addressExtSellsyId: number | null = consumer?.address?.extSellsyId ?? null;
		let signature: string = legalNotice?.signature ?? null;

		if (
			Boolean(name) &&
			Boolean(capital) &&
			Boolean(legalStatus) &&
			Boolean(street) &&
			Boolean(number) &&
			Boolean(zipCode) &&
			Boolean(rcs) &&
			Boolean(city)
		) {
			this.setState({ isConsumerFormComplete: true });
		}

		switch (input.label) {
			case 'Siren':
				siren = value ?? '';
				break;
			case 'Dénomination':
				name = value ?? '';
				break;
			case 'Capital':
				capital = value ?? '';
				break;
			case 'Forme juridique':
				legalStatus = value ?? '';
				break;
			case 'Lieu d\'immatriculation (RCS)':
				rcs = value ?? '';
				break;
			case 'Nom':
				addressName = value ?? '';
				break;
			case 'Adresse':
				street = value ?? '';
				break;
			case 'Numéro':
				number = Number(value) ?? null;
				break;
			case 'Complément d\'adresse':
				additionalData = value ?? '';
				break;
			case 'Code postal':
				zipCode = value ?? '';
				break;
			case 'Ville':
				city = value ?? '';
				break;
			case 'Signature':
				signature = value ?? '';
				break;
		}

		// Update the state with the extracted values
		this.setState((prevState: ViewState) => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					signature: signature,
				},
				consumer: {
					...prevState.formData.consumer,
					siren: siren,
					name: name,
					capital: capital,
					legalStatus: legalStatus,
					rcs: rcs,
					extSellsyId: extSellsyId,
					address: {
						...prevState.formData.consumer?.address,
						name: addressName,
						street: street,
						number: number,
						additionalData: additionalData,
						zipCode: zipCode,
						city: city,
						country: 'FR',
						phone: null,
						extSellsyId: addressExtSellsyId,
						isBillingAddress: true
					}
				}
			}
		}));
	}

	//</editor-fold>

	//<editor-fold desc="Block Billing Address methods" defaultstate="collapsed">

	private billingAddressRender(): ReactElement
	{
		return (
			<>
				<div style={ FormBuilderStyle.section().sectionCardContainer }>
					<div style={ { ...FontStyle.h4(), padding: 10 } }> Préférence de facturation</div>
					<div style={ FormBuilderStyle.section().sectionInputsContainer }>
						<div style={ FormBuilderStyle.section().sectionViewInputsContainer }>
							<div style={ FormBuilderStyle.section().inputsGrid }>
								<Radio
									label="Choisir l'adresse de facturation ?"
									options={ this.buildRadioListOption() }
									name="billingOption-user"
									selectedValue={ null }
									onSelectedOption={ (event: any) => this.onSelectedBillingType(event) }
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	private onSelectedBillingType(billingTypeStr: string): void
	{
		this.setFormData({ billingAddress: this.prepareBillingAddress(billingTypeStr) });
		this.setFormDataLegalNoticeOption({ billingType: BilledToTypeEnum.findByValue(billingTypeStr) });
	}

	private prepareBillingAddress(billingTypeStr: string): AddressInterface
	{
		let address = null;
		if (billingTypeStr === BilledToTypeEnum.ORDER_GIVER.value) {
			address = this.state.formData.selectedClient?.address;
			if (address) {
				address.name = this.state.formData.selectedClient?.name || '';
				address.phone = this.state.formData.selectedClient?.phone;
				address.isBillingAddress = true;
			}
		} else {
			address = this.state.formData.consumer?.address;
			if (address) {
				address.name = this.state.formData.consumer?.name || '';
				address.isBillingAddress = true;
			}
		}

		return address as AddressInterface;
	}

	private buildRadioListOption(): { label: string, value: string }[]
	{
		return [
			{ label: 'Donneur d\'ordre', value: BilledToTypeEnum.ORDER_GIVER.value },
			{ label: 'Client final', value: BilledToTypeEnum.FINAL_CUSTOMER.value }
		];
	}

	//</editor-fold>

	//<editor-fold desc="Block SendTo" defaultstate="collapsed">

	private onSendToSelected(selections: SendToSelections): void
	{
		this.setFormData({ sendTo: selections });
	}

	//</editor-fold>

	//<editor-fold desc="Block FormBuilder methods" defaultstate="collapsed">

	private async setFormBuilderSections(): Promise<any>
	{
		// Get FormBuilder Sections
		let formBuilderSections = await this.appService.formBuilderSectionList(this.state.formData.selectedSecondaryCategory?.id as number);
		formBuilderSections = this.getSortedSections(formBuilderSections);
		this.setState({ formBuilderSections }, () =>
		{
			this.setState({ isDataLoading: false });
			this.setLegalNoticeContentWithDynamicInputs(formBuilderSections);
		});
	}

	private renderDynamicFormBuilder(): ReactElement
	{
		return (
			<div style={ { display: this.state.formData.selectedSecondaryCategory ? 'block' : 'none' } }>
				{ this.state.formBuilderSections.map((section: FormBuilderSectionInterface) => (
					this.sectionsAndSubSectionsRender(section)
				)) }
			</div>
		);
	}

	private formBuilderSectionRender(section: FormBuilderSectionInterface): ReactElement
	{
		return (
			<div key={ section.id } style={ FormBuilderStyle.section().sectionCardContainer }>
				<div style={ { ...FontStyle.h4(), padding: 10 } }>{ section.title }</div>
				<div style={ FormBuilderStyle.section().sectionInputsContainer }>
					<div style={ FormBuilderStyle.section().sectionViewInputsContainer }>
						<div style={ FormBuilderStyle.section().inputsGrid }>
							{ this.renderDynamicInputs(section) }
							{ (section.subSections.length > 0 && section.sectionParentTypeOption) &&
                <div style={ { width: '100%' } }>
									{ section.sectionParentTypeOption === FormBuilderInputTypeEnum.SELECTOR &&
                    <SelectComponent
                      key={ section.id }
                      label={ section.title }
                      containerDivWidth={ '300px' }
                      listOptions={ this.subSectionTitleList(section) }
                      onSelectedOption={ (subSection: any) => this.handleSubSectionSelectorVisibility(subSection) }
                      renderOptionLabel={ (option: any) => option.label }
                      required={ true }
                    />
									}
									{ section.sectionParentTypeOption === FormBuilderInputTypeEnum.CHECKBOX &&
                    <div style={ { width: '100%', display: 'flex', gap: 15 } }>
											{ this.subSectionTitleList(section).map((option: any, index: number) => (
												<Checkbox
													key={ option.id }
													label={ option.label }
													options={ {
														rowLeftCheckbox: true,
													} }
													name={ `checkbox-${ index }` }
													isChecked={ Boolean(this.state.subSectionsVisible.find((subSection: any) => subSection.parentId === section.id && subSection.index === index)) }
													onCheckedChange={ (event: any) => this.handleSubSectionCheckboxVisibility(section, index, event.target.checked) }
												/>
											)) }
                    </div>
									}
                </div>
							}
						</div>
					</div>
					{ (section.isDuplicable) && this.duplicateSectionRender(section.id) }
					{
						section.isDuplicable &&
            <div style={ { width: '100%', display: 'flex', justifyContent: 'center' } }>
              <Button
                iconLeft={ <LuPlus/> }
                type={ 'default-blue' }
                onClick={ () => this.handleCreateDuplicateSection(section) }
              />
            </div>
					}
				</div>
			</div>
		);
	}

	private renderDynamicInputs(section: FormBuilderSectionInterface): ReactElement
	{
		const { legalNoticeContent } = this.state;
		return (
			<>
				{ section.formBuilderInputs.map((input: FormBuilderInputsInterface) =>
				{
					const inputContent = legalNoticeContent.find(content => content.inputId === input.id);
					const hasError = !!inputContent?.error;

					return (
						<React.Fragment key={ input.id }>
							<div style={ { display: 'flex', flexDirection: 'column', width: '50%', } }>
								<div style={ { display: 'flex', gap: 15, padding: '10px 10px', alignItems: 'flex-end' } }>
									{ (input.type !== FormBuilderInputTypeEnum.SELECTOR
											&& input.type !== FormBuilderInputTypeEnum.CHECKBOX
											&& input.type !== FormBuilderInputTypeEnum.TEXTAREA
										) &&
                    <Input
                      key={ input.id }
                      type={ input.type.toLowerCase() as InputType }
                      width={ (input.type === 'DATE') ? '200px' : '100%' }
                      label={ input.label }
                      name={ `input-${ input.type }-${ input.id }` }
                      placeholder={ input.placeholder }
                      required={ Boolean(input.isRequired) }
                      containerDivWidth={ '100%' }
                      value={ this.state.legalNoticeContent.find(content => content.sectionId === section.id && content.inputId === input.id)?.selectedValue || '' }
                      onChange={ (event: any) => this.prepareLegalNoticeContent(event, input.id as number, section.id, section.parentId as number) }
                      isError={ hasError }
                    />
									}
								</div>
								{ inputContent?.error && (
									<div style={ {
										color: 'red',
										fontSize: 12,
										padding: '0px 10px',
										display: 'flex',
										alignItems: 'center',
										gap: 5
									} }>
										<LuXCircle/> { inputContent.error }
									</div>
								) }
							</div>
							{
								(input.type === FormBuilderInputTypeEnum.SELECTOR && input.formBuilderOptions) &&
                <div style={ { padding: 10, width: '100%', display: 'flex', gap: 15, alignItems: 'flex-end' } }>
                  <SelectComponent
                    key={ input.id }
                    label={ input.label }
                    buttonWidth={ 300 }
                    listOptions={ input.formBuilderOptions }
                    onSelectedOption={ (selectedOption: any) => this.prepareLegalNoticeContent({ target: { value: selectedOption.label } }, input.id as number, section.id, section.parentId as number) }
                    renderOptionLabel={ (option) => option.label }
                    required={ Boolean(input.isRequired) }
                    containerDivWidth={ '45%' }
                  />
                </div>
							}
							{
								(input.type === FormBuilderInputTypeEnum.TEXTAREA) &&
                <div style={ { padding: 10, width: '100%', display: 'flex', gap: 15, alignItems: 'center' } }>
                  <Textarea
                    label={ input.label }
                    name={ `textarea-section-${ input.id }` }
                    width={ '100%' }
                    value={ this.state.legalNoticeContent.find(content => content.sectionId === section.id && content.inputId === input.id)?.selectedValue || '' }
                    onChange={ (event: any) => this.prepareLegalNoticeContent(event, input.id as number, section.id, section.parentId as number) }
                  />
                </div>
							}
							{ (input.type === FormBuilderInputTypeEnum.CHECKBOX) &&
                <div style={ { padding: 10, width: '100%', display: 'flex', gap: 15, alignItems: 'flex-end' } }>
                  <Checkbox
                    options={ {
											rowLeftCheckbox: true
										} }
                    label={ input.label as string }
                    name={ `checkbox-section-${ input.id }` }
                    onCheckedChange={ (event: any) => this.handleCheckboxChange(event, input.id as number, section.id, section.parentId as number) }
                    isChecked={ Boolean(this.state.legalNoticeContent.find(content => content.sectionId === section.id && content.inputId === input.id && content.updatedValue)) }
                  />
                </div>
							}
						</React.Fragment>
					);
				}) }
			</>
		);
	}

	private handleCreateDuplicateSection(section: FormBuilderSectionInterface): void
	{
		// Init Duplicate Sections
		const duplicateSectionList: {
			realSectionId: number,
			items: FormBuilderSectionInterface[]
		}[] = [...this.state.duplicateSection];

		// Build duplicate Section
		const duplicateSection: FormBuilderSectionInterface = {
			...section,
			id: this.generateUniqueId(),
			title: `${ section.title } (duplicate)`,
			parentId: (section.parentId) ? section.parentId : null,
			formBuilderInputs: section.formBuilderInputs.map(input => ({
				...input,
				id: this.generateUniqueId(),
			})),
		};

		// Push and Set duplicate section list
		duplicateSectionList.push({ realSectionId: section.id, items: [duplicateSection] });

		const getRealSectionInContentCount: number = this.state.legalNoticeContent.filter((content: any) => content.sectionId === section.id).length;
		const getRealSectionInContentIndex: number = this.state.legalNoticeContent.findIndex((content: any) => content.sectionId === section.id);
		const calculateIndex: number = getRealSectionInContentIndex + getRealSectionInContentCount;

		// Create a new array for legalNoticeContent to avoid direct state mutation
		const tempLegalNoticeContentList = [...this.state.legalNoticeContent];

		// Insert duplicate inputs into tempLegalNoticeContentList
		duplicateSection.formBuilderInputs.forEach((input: FormBuilderInputsInterface, index: number) =>
		{
			tempLegalNoticeContentList.splice(calculateIndex + index, 0, {
				parentId: section.parentId,
				sectionId: duplicateSection.id,
				inputId: input.id as number,
				isRequired: input?.isRequired || false,
				initialValue: input?.cmsContent || '',
				updatedValue: null,
				selectedValue: null,
				error: null,
			});
		});

		// Set the state with updated duplicateSectionList and tempLegalNoticeContentList
		this.setState({
			duplicateSection: duplicateSectionList,
			legalNoticeContent: tempLegalNoticeContentList
		});
	}

	private handleDeleteDuplicateSection(duplicateSectionId: number): void
	{
		this.setState(prevState =>
		{
			const updatedDuplicateSection = prevState.duplicateSection
				.map(duplicate => ({
					...duplicate,
					items: duplicate.items.filter(item => item.id !== duplicateSectionId)
				}))
				.filter(duplicate => duplicate.items.length > 0);

			const updatedLegalNoticeContent = prevState.legalNoticeContent.filter(content => content.sectionId !== duplicateSectionId);

			return {
				duplicateSection: updatedDuplicateSection,
				legalNoticeContent: updatedLegalNoticeContent
			};
		});
	}

	private duplicateSectionRender(sectionId: number): ReactElement[]
	{
		const duplicateSections = this.state.duplicateSection
			.filter(duplicate => duplicate.realSectionId === sectionId)
			.flatMap(duplicate => duplicate.items);

		return duplicateSections.map((duplicateSection: FormBuilderSectionInterface) => (
			<div key={ duplicateSection.id } style={ FormBuilderStyle.section().sectionViewInputsContainer }>
				<div style={ FormBuilderStyle.section().inputsGrid }>
					<div style={ { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } }>
						<Button label={ 'supprimer' } type={ 'danger' } iconLeft={ <LuTrash2/> }
										onClick={ () => this.handleDeleteDuplicateSection(duplicateSection.id) }/>
					</div>
					{ this.renderDynamicInputs(duplicateSection) }
				</div>
			</div>
		));
	}

	private generateUniqueId(): number
	{
		return Math.floor(Math.random() * 1000000);
	}

	//</editor-fold>

	//<editor-fold desc="Block LegalNotice Render methods" defaultstate="collapsed">

	private legalNoticeRender(): ReactElement
	{
		return (
			<div style={ CreateLegalNoticeStyle.mainContainerStyle() }>
				<div style={ CreateLegalNoticeStyle.headerStickyStyle() }>
					<div style={ CreateLegalNoticeStyle.optionsContainerStyle() }>
						<div style={ CreateLegalNoticeStyle.displayFlexBetween() }>
							<div style={ FontStyle.h4() }> Contenu de l'annonce</div>
						</div>
						<div style={ {
							backgroundColor: CssVariableEnum['--color-white'],
							borderRadius: 15,
							maxHeight: '100%',
							overflow: 'hidden',
							display: 'grid',
							gridTemplateRows: '125px 80px calc(100% - 295px) 90px',
						} }>
							{ /* OPTIONS */ }
							<div style={ CreateLegalNoticeStyle.contentContainerStyle() }>
								<HeaderOptionContentComponent
									isConsumerFormFilled={ this.state.isConsumerFormComplete }
									selectedClient={ this.state.formData.selectedClient }
									isHeader={ this.state.formData.legalNotice.option.isHeader }
									isLogo={ this.state.formData.legalNotice.option.isLogo }
									onCheckIsHeader={ this.onCheckContentHeaderIsHeader.bind(this) }
									onCheckIsLogo={ this.onCheckContentHeaderIsLogo.bind(this) }
								/>
							</div>

							{/* TITLE */ }

							<div style={ CreateLegalNoticeStyle.contentContainerStyle() }>
								<Input
									type={ 'text' }
									label="Titre de l'annonce"
									width={ '100%' }
									name="legalNoticeData.title"
									value={ this.state.formData.legalNotice.title || '' }
									onChange={ (event: any) => this.onChangeLegalNoticeTitle(event.target.value) }
								/>
							</div>

							{/* LEGAL NOTICE */ }

							{ this.legalNoticeContentRender() }

							{/* PRICE */ }
							{ this.state.formData.selectedDepartment && this.state.formData.selectedSecondaryCategory &&
                <div style={ { height: '100%' } }>
                  <LegalNoticePriceFormComponent
                    legalNotice={ this.state.formData.legalNotice }
                    selectedDepartment={ this.state.formData.selectedDepartment }
                    selectedCategory={ this.state.formData.selectedSecondaryCategory }
                    validateLegalNotice={ this.validateLegalNotice.bind(this) }
                    isFormComplete={ this.isFormComplete() }
                    isHeaderCharacterCount={ this.state.formData.headerCharacterCount }
                    isCompactRender={ true }
                    isAdmin={ false }
                  />
                </div>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}

	private legalNoticeContentRender(): ReactElement
	{
		return (
			<div style={ { ...CreateLegalNoticeStyle.contentContainerStyle(), overflowY: 'scroll' } }>
				<div style={ { ...FontStyle.h4(), textAlign: 'center', marginBottom: 10 } }>Annonce légale</div>
				<LegalNoticeRender
					key={ this.state.legalNoticeContent.toString() }
					legalNotice={ this.state.formData.legalNotice }
					consumer={ this.state.formData.consumer }
					isShow={ true }
				/>
			</div>
		);
	}

	private setLegalNoticeContentWithDynamicInputs(sections: FormBuilderSectionInterface[]): void
	{
		const legalNoticeContent: ViewCmsContentState[] = [];

		const processSection = (section: FormBuilderSectionInterface, parentSectionId: number, subSectionParentId: number | null) =>
		{
			section.formBuilderInputs.forEach((input: FormBuilderInputsInterface) =>
			{
				legalNoticeContent.push({
					parentId: subSectionParentId,
					inputId: input.id as number,
					isRequired: input.isRequired || false,
					initialValue: input.cmsContent || '',
					updatedValue: null,
					sectionId: parentSectionId,
					selectedValue: null,
					error: null
				});
			});

			section.subSections.forEach((subSection: FormBuilderSectionInterface) =>
			{
				processSection(subSection, subSection.id, section.id);
			});
		};

		sections.forEach((section: FormBuilderSectionInterface) =>
		{
			processSection(section, section.id, null);
		});

		this.setState({ legalNoticeContent });
	}

	private prepareLegalNoticeContent(event: any, inputId: number, sectionId: number, parentId: number): void
	{
		const legalNoticeContent: ViewCmsContentState[] = [...this.state.legalNoticeContent];

		const updateContent = (section: FormBuilderSectionInterface, parentSectionId: number) =>
		{
			section.formBuilderInputs.forEach((input: FormBuilderInputsInterface) =>
			{
				if (input.id === inputId) {
					const index: number = legalNoticeContent.findIndex(content => content.sectionId === parentSectionId && content.inputId === inputId);
					let value = input.type === FormBuilderInputTypeEnum.CHECKBOX ? event.target.checked : event.target.value;

					if (index !== -1) {
						if ((input.type === FormBuilderInputTypeEnum.CHECKBOX && !event.target.checked) || value === '') {
							// Set updatedValue to null if checkbox is unchecked or value is empty
							legalNoticeContent[index] = {
								...legalNoticeContent[index],
								updatedValue: null,
								selectedValue: null,
							};
						} else {
							// Update existing entry
							legalNoticeContent[index] = {
								...legalNoticeContent[index],
								updatedValue: this.updatedValue(input.cmsContent as string, value),
								selectedValue: value,
							};
						}

						if (section.title === 'Société'
							|| section.title === 'Siège social'
							|| section.title === 'Capital non variable'
							|| section.title === 'Signature') {
							this.setConsumerFormWithDynamicSection(input, value);
						}

					} else if ((input.type !== FormBuilderInputTypeEnum.CHECKBOX || event.target.checked) && value !== '') {
						// Add new entry if value is not empty and checkbox is checked
						legalNoticeContent.push({
							parentId,
							sectionId: parentSectionId,
							inputId: inputId,
							isRequired: input.isRequired || false,
							initialValue: input.cmsContent as string,
							updatedValue: this.addNonBreakingSpaces(this.updatedValue(input.cmsContent as string, value)),
							selectedValue: value,
							error: null,
						});
					}
				}
			});

			// Recursive call for sub-sections
			section.subSections.forEach((subSection: FormBuilderSectionInterface) =>
			{
				updateContent(subSection, subSection.id);
			});
		};

		// Iterate over all sections and their sub-sections
		const iterateSections = (sections: FormBuilderSectionInterface[]) =>
		{
			sections.forEach((section: FormBuilderSectionInterface) =>
			{
				if (section.id === sectionId) {
					updateContent(section, section.id);
				}
				if (section.subSections && section.subSections.length > 0) {
					iterateSections(section.subSections);
				}
			});
		};

		iterateSections(this.state.formBuilderSections);

		this.state.duplicateSection.map((duplicateSectionItem: any) =>
		{
			return iterateSections(duplicateSectionItem.items);
		});

		// Concatenate the updated content and wrap it with a single <p> tag
		this.concatenatedContent(legalNoticeContent);
	}

	private concatenatedContent(legalNoticeContent: any): void
	{
		const concatenatedContent = `<p>${ legalNoticeContent.reduce((acc: any, item: any) =>
		{
			if (item.updatedValue) {
				return acc + item.updatedValue + ' ';
			}
			return acc;
		}, '').trim() }</p>`;

		// Update the state with the concatenated content and the legalNoticeContent array
		this.setState(prevState => ({
			formData: {
				...prevState.formData,
				legalNotice: {
					...prevState.formData.legalNotice,
					content: concatenatedContent
				}
			},
			legalNoticeContent
		}));
	}

	private addNonBreakingSpaces(text: string): string
	{
		return text.replace(/: /g, '\u00A0: ');
	}

	private updatedValue(initialValue: string, replaceContent: string): string
	{
		return initialValue.replace('{{var}}', replaceContent);
	}

	private onChangeLegalNoticeTitle(title: string): void
	{
		this.setFormDataLegalNotice({ title });
	}


	//</editor-fold>

	//<editor-fold desc="Private Methods" defaultstate="collapsed">

	private async departmentListRequest(): Promise<DepartmentInterface[]>
	{
		return await this.legalNoticeService.departmentList();
	}

	private setClientAndCollaborator(): {
		selectedClient: ClientInterface,
		selectedCollaborator: CollaboratorInterface
	} | null
	{
		const selectedClient: ClientInterface = this.props.clientContext.authClient;
		const selectedCollaborator: CollaboratorInterface | null = UserService.getCollaboratorFromUser(this.props.authContext.user, selectedClient.id);

		if (selectedClient && selectedCollaborator) {
			this.setState((prevState: ViewState) => ({
				formData: {
					...prevState.formData,
					selectedClient,
					selectedCollaborator,
				},
			}));

			return { selectedClient, selectedCollaborator };
		} else {
			return null;
		}

	}

	private setFormDataWithClientOptions(selectedClient: ClientInterface | null): DepartmentInterface | null
	{
		if (selectedClient?.options && selectedClient.options.department) {

			this.setFormData({
				selectedDepartment: selectedClient.options.department,
				selectedNewspaper: selectedClient.options.newspaper,
			});

			return selectedClient.options.department;
		} else {
			return null;
		}
	}

	private onCheckContentHeaderIsHeader(event: any): void
	{
		this.setFormDataLegalNoticeOption({ isHeader: event.target.checked });
	}

	private onCheckContentHeaderIsLogo(event: any): void
	{
		this.setFormDataLegalNoticeOption({ isLogo: event });
	}

	private validateLegalNotice(event: any): void
	{
		event.stopPropagation();

		const formData = this.state.formData;

		if (this.checkIfValueIsRequired()) {
			return;
		}

		this.props.modalContext.content(
			`Récapitulatif : ${ formData.selectedPrimaryCategory?.label } - ${ formData.selectedSecondaryCategory?.label }`,
			<LegalNoticeUserModal
				modalContext={ this.props.modalContext }
				legalNotice={ formData.legalNotice }
				selectedClient={ formData.selectedClient as ClientInterface }
				selectedDepartment={ formData.selectedDepartment as DepartmentInterface }
				selectedNewspaper={ formData.selectedNewspaper as NewspaperInterface }
				consumer={ formData.consumer as ConsumerDataInterface }
				billingAddress={ formData.billingAddress as AddressInterface }
				sendTo={ formData.sendTo }
				selectedPrimaryCategory={ formData.selectedPrimaryCategory as FormBuilderCategoryInterface }
				selectedSecondaryCategory={ formData.selectedSecondaryCategory as FormBuilderCategoryInterface }
				onCreate={ this.onCreate.bind(this) }
			/>
		);
	}

	private isFormComplete(): boolean
	{
		const { formData } = this.state;
		const {
			legalNotice,
			selectedClient,
			selectedCollaborator,
			selectedDepartment,
			selectedNewspaper,
			selectedPrimaryCategory,
			selectedSecondaryCategory,
			billingAddress,
			consumer,
			sendTo
		} = formData;

		// Add checks for all required fields
		const isLegalNoticeComplete = legalNotice.content && legalNotice.content !== '<p></p>' && legalNotice.publishDate;
		const isClientComplete = selectedClient !== null;
		const isCollaboratorComplete = selectedCollaborator !== null;
		const isDepartmentComplete = selectedDepartment !== null;
		const isNewspaperComplete = selectedNewspaper !== null;
		const isCategoryComplete = selectedPrimaryCategory !== null && selectedSecondaryCategory !== null;
		const isBillingAddressComplete = billingAddress !== null;
		const isSendToComplete = Object.keys(sendTo).length > 0;
		const isConsumerComplete = consumer !== null;

		return Boolean(
			isLegalNoticeComplete
			&& isClientComplete
			&& isCollaboratorComplete
			&& isDepartmentComplete
			&& isNewspaperComplete
			&& isCategoryComplete
			&& isConsumerComplete
			&& isBillingAddressComplete
			&& isSendToComplete
		);
	}

	private subSectionTitleList(section: FormBuilderSectionInterface): any[]
	{
		const subSectionTitleList: any[] = [];
		section.subSections && section.subSections.map((subSection: FormBuilderSectionInterface, index: number) =>
			(
				subSectionTitleList.push({
					id: subSection.id,
					parentId: section.id,
					label: subSection.title,
					index: index
				})
			));

		return subSectionTitleList;
	}

	private sectionsAndSubSectionsRender(section: FormBuilderSectionInterface): ReactElement[]
	{
		const elements: ReactElement[] = [this.formBuilderSectionRender(section)];

		if (section.subSections && section.subSections.length > 0) {
			section.subSections.forEach((subSection: FormBuilderSectionInterface, index: number) =>
			{
				const isVisible = this.state.subSectionsVisible.find(visibleSubSection =>
					visibleSubSection.parentId === section.id && visibleSubSection.index === index
				);

				if (isVisible) {
					elements.push(...this.sectionsAndSubSectionsRender(subSection));
				}
			});
		}

		return elements;
	}

	private handleSubSectionSelectorVisibility(subSectionOption: any): void
	{
		this.setState(prevState =>
		{
			let updatedLegalNoticeContent = [...prevState.legalNoticeContent];
			let updatedSubSectionsVisible = [...prevState.subSectionsVisible];

			// Update the visibility of the sub-sections
			const updatedSubSections = updatedSubSectionsVisible.map(subSection =>
			{
				if (subSection.parentId === subSectionOption.parentId) {
					return { ...subSection, index: subSectionOption.index };
				}
				return subSection;
			});

			const parentIdExists: boolean = updatedSubSections.some(subSection => subSection.parentId === subSectionOption.parentId);
			if (!parentIdExists) {
				updatedSubSections.push(subSectionOption);
			}

			// Update isRequired for inputs in the now visible sub-section
			updatedLegalNoticeContent = updatedLegalNoticeContent.map(content =>
			{
				if (content.sectionId === subSectionOption.index) {
					// Use subSectionOption.index instead of subSectionOption.parentId
					const section = this.state.formBuilderSections.find(section => section.id === subSectionOption.parentId);
					const subSection = section?.subSections.find(subSec => subSec.id === subSectionOption.index);

					if (subSection) {
						const input = subSection.formBuilderInputs.find(input => input.id === content.inputId);
						if (input) {
							return { ...content, isRequired: input.isRequired || false };
						}
					}
				}
				return content;
			});

			return {
				subSectionsVisible: updatedSubSections,
				legalNoticeContent: updatedLegalNoticeContent
			};
		});
	}

	private handleSubSectionCheckboxVisibility(sectionOption: any, index: number, isChecked: boolean): void
	{
		const subSectionOption = {
			parentId: sectionOption.id,
			index: index
		};

		this.setState(prevState =>
		{
			let updatedSubSectionsVisible = [...prevState.subSectionsVisible];

			const existingIndex: number = updatedSubSectionsVisible.findIndex(subSection =>
				subSection.parentId === subSectionOption.parentId && subSection.index === subSectionOption.index
			);

			const parentIndex: number = updatedSubSectionsVisible.findIndex(subSection =>
				subSection.parentId === subSectionOption.parentId
			);

			if (isChecked) {
				if (parentIndex !== -1 && updatedSubSectionsVisible[parentIndex].index === null) {
					updatedSubSectionsVisible[parentIndex].index = subSectionOption.index;
				} else if (existingIndex === -1) {
					updatedSubSectionsVisible.push(subSectionOption);
				}
			} else {
				if (existingIndex !== -1) {
					updatedSubSectionsVisible.splice(existingIndex, 1);
				}
			}
			return { subSectionsVisible: updatedSubSectionsVisible };
		});
	}

	private handleCheckboxChange(event: any, inputId: number, sectionId: number, parentId: number): void
	{
		this.prepareLegalNoticeContent(event, inputId, sectionId, parentId);
	}

	private getSortedSections(sections: FormBuilderSectionInterface[]): FormBuilderSectionInterface[]
	{
		return sections.slice().sort((a, b) =>
		{
			const aSortOrder = a.sortOrders[0]?.sortOrder || 0;
			const bSortOrder = b.sortOrders[0]?.sortOrder || 0;
			return aSortOrder - bSortOrder;
		});
	}

	private checkIfValueIsRequired(): boolean
	{
		const { legalNoticeContent, subSectionsVisible } = this.state;
		const errorMessage: string = 'Ce champs est obligatoire';
		let isError: boolean = false;

		const subSectionForErrors = subSectionsVisible
			.filter(subSectionItem => subSectionItem.index !== null)
			.map(subSectionItem =>
			{
				const section = this.state.formBuilderSections.find(section => section.id === subSectionItem.parentId);
				return section?.subSections[subSectionItem.index as number];
			});

		const updatedLegalNoticeContent = legalNoticeContent.map(content =>
		{
			if (content.parentId !== null) {
				const subSection: any = subSectionForErrors.find((subSectionItem) => subSectionItem?.id === content.sectionId);

				if (subSection) {
					if (content.isRequired && !content.updatedValue) {
						isError = true;
						return { ...content, error: errorMessage };
					}
				}
			} else {
				if (content.isRequired && !content.updatedValue) {
					isError = true;
					return { ...content, error: errorMessage };
				}
			}

			return { ...content, error: null };
		});

		this.setState({ legalNoticeContent: updatedLegalNoticeContent });
		return isError;
	}

	private async onCreate(type: string): Promise<void>
	{
		// Int vars
		let progressInterval;

		try {
			// Start ProgressBar
			let progressProcess: number = 0;
			progressInterval = setInterval(() =>
			{
				if (progressProcess < 95) {
					progressProcess += 10;
					this.setState({ progressProcess });
				}
			}, 450);

			// Call API
			const response: any = await this.apiLegalNoticeService.create(await this.prepareFormData(type))
			;

			if (response.errorMessage) {
				throw new Error(response.errorMessage);
			}

			// Keep the ProgressBar
			await response;
			clearInterval(progressInterval);
			this.setState({ progressProcess: 100 });

			// Flash message
			this.props.flashMessageContext.flashMessage(
				'Mise à jour réussie',
				`L'annonce légale a bien été créée avec succès`,
				'success'
			);
			// Navigate
			this.props.navigation('/legal-notices?reload=true');
		} catch (error) {
			clearInterval(progressInterval);
			this.setState({ progressProcess: 0 });

			this.props.flashMessageContext.flashMessage(
				'Erreur',
				`Une erreur est survenue lors de la modification création  de l'annonce légale.`,
				'error'
			);
		}
	}

	private async prepareFormData(type: string): Promise<any>
	{
		const formData = this.state.formData;

		return {
			departmentId: formData.selectedDepartment?.id,
			newspaperId: formData.selectedNewspaper?.id,
			clientId: formData.selectedClient?.id,
			collaboratorId: formData.selectedCollaborator?.id,
			categoryId: formData.selectedSecondaryCategory?.id,
			legalNotice: {
				...formData.legalNotice,
				price: await this.calculatedPrice(),
				nbCharacters: await this.nbCharacters(),
				status: PublishStateEnum.findByValue(type)?.value,
				paymentStatus: PaymentStateEnum.PENDING.value,
				quoteStatus: (type === PublishStateEnum.QUOTE.value) ? QuoteStatusEnum.PENDING.value : null,
				option: {
					...formData.legalNotice.option,
					publishType: typeof formData.legalNotice.option.publishType === 'string'
						? formData.legalNotice.option.publishType
						: formData.legalNotice.option.publishType.value,
					billingType: formData.legalNotice.option.billingType.value,
				}
			},
			billingAddress: formData.billingAddress,
			sendTo: formData.sendTo,
			consumer: formData.consumer,
			status: PublishStateEnum.findByValue(type)?.value
		};
	}

	private async calculatedPrice(): Promise<number>
	{
		const VAT_RATE = 0.2;
		const PRINT_VAT_RATE = 0.021;

		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(
			this.state.formData.selectedDepartment,
			this.state.formData.selectedSecondaryCategory
		);
		const annexPrice: number = this.legalNoticePriceService.getAnnexPrice(
			this.state.formData.selectedDepartment as DepartmentInterface,
			this.state.formData.selectedSecondaryCategory as FormBuilderCategoryInterface,
			isFixedPrice
		);
		const getPrintPrice = await this.legalNoticePriceService.getPrintPrice();
		const getShippingCostPrice = await this.legalNoticePriceService.getShippingCostPrice(
			this.state.formData.legalNotice.numberOfCopies
		);
		const getBodaccPrice = await this.legalNoticePriceService.getBodacPrice();

		const nbCharacters = isFixedPrice
			? 1
			: (
				stripHtmlTags(this.state.formData.legalNotice.content).length +
				stripHtmlTags(this.state.formData.legalNotice.title).length +
				stripHtmlTags(this.state.formData.legalNotice.signature).length +
				(this.state.formData.headerCharacterCount || 0)
			);

		const legalNoticePrice = annexPrice * nbCharacters;

		const printPrice = this.state.formData.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value
			? getPrintPrice
			: 0;

		const shippingCostPrice = this.state.formData.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value
			? getShippingCostPrice
			: 0;

		const bodaccPrice = this.state.formData.legalNotice.option.isBodacc
			? getBodaccPrice
			: 0;

		const legalNoticePriceIncVat = legalNoticePrice * (1 + VAT_RATE);
		const printPriceIncVat = printPrice * (1 + PRINT_VAT_RATE);
		const shippingCostPriceIncVat = shippingCostPrice * (1 + VAT_RATE);
		const bodaccPriceIncVat = bodaccPrice * (1 + VAT_RATE);
		// TOTAL
		const totalPriceInclVat = legalNoticePriceIncVat + printPriceIncVat + shippingCostPriceIncVat + bodaccPriceIncVat;

		return parseFloat(totalPriceInclVat.toFixed(2));
	}

	private async nbCharacters(): Promise<number>
	{
		const isFixedPrice: boolean = this.legalNoticePriceService.isFixedPrice(
			this.state.formData.selectedDepartment,
			this.state.formData.selectedSecondaryCategory
		);

		return isFixedPrice
			? 1
			: (
				stripHtmlTags(this.state.formData.legalNotice.content).length +
				stripHtmlTags(this.state.formData.legalNotice.title).length +
				stripHtmlTags(this.state.formData.legalNotice.signature).length +
				(this.state.formData.headerCharacterCount || 0)
			);
	}

	private resetFormDataModifySiren(): void
	{
		if (this.state.legalNoticeContent.length > 0 && this.state.formBuilderSections.length > 0) {
			Swal.fire({
				title: 'Êtes-vous sûr?',
				text: 'Si vous modifiez le SIREN, le formulaire sera réinitialisé',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Modifier',
				cancelButtonText: 'Annuler'
			}).then((result) =>
			{
				if (result.isConfirmed) {
					this.setState({
							isDataLoading: true,
							legalNoticeContent: [],
						});
					this.setFormData({
						selectedSecondaryCategory: this.state.formData.selectedSecondaryCategory,
						consumer: null,
						billingAddress: null,
						headerCharacterCount: 0,
						sendTo: {},
						legalNotice: {
							...this.state.formData.legalNotice,
							id: null,
							tag: null,
							title: '',
							content: '',
							signature: '',
							logo: '',
							publishDate: null,
							numberOfCopies: 0,
							status: null,
							quoteStatus: null,
							paymentStatus: null,
						},
					},  async () =>
					{
						this.setState({ isDataLoading: true });
						await this.setFormBuilderSections();
					});
				}
			});
		}
	}

	private resetFormData(newspaper?: NewspaperInterface): void
	{
		this.setFormData({
			selectedNewspaper: newspaper,
			selectedPrimaryCategory: null,
			selectedSecondaryCategory: null,
			consumer: null,
			billingAddress: null,
			headerCharacterCount: 0,
			sendTo: {},
			legalNotice: {
				...this.state.formData.legalNotice,
				id: null,
				tag: null,
				title: '',
				content: '',
				signature: '',
				logo: '',
				publishDate: null,
				numberOfCopies: 0,
				status: null,
				quoteStatus: null,
				paymentStatus: null,
			},
		});
		this.setState({
			isDataLoading: true,
			legalNoticeContent: [],
			formBuilderSections: [],
		}, () =>
		{
			this.setState({ isDataLoading: false });
			Swal.fire(
				'Modifié!',
				'Le journal a été modifié.',
				'success'
			);
		});
	}


	//</editor-fold>
}