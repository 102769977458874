import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { FormBuilderSectionInterface } from '@/Modules/FormBuilder/Interface/FormBuilderSectionInterface';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuPencilLine, LuPlus, LuTrash2 } from 'react-icons/lu';
import SelectComponent from '@/Modules/App/Components/Atom/Form/Select/SelectComponent';
import FormBuilderStyle from '@/Modules/App/Style/Components/FormBuilderStyle';
import { FormBuilderInputsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderInputsInterface';
import { InputType } from '@/Modules/App/Components/Atom/Form/Input/Type/InputType';
import { ModalContextType } from '@/Provider/ModalProvider';
import FormBuilderSectionInputComponent from '@/Modules/FormBuilder/Components/FormBuilderSectionInputComponent';
import Skeleton from 'react-loading-skeleton';
import FormBuilderCreateSubSectionFormComponent
	from '@/Modules/FormBuilder/Components/FormBuilderCreateSubSectionFormComponent';
import { FormBuilderInputTypeEnum } from '@/Enum/FormBuilderInputTypeEnum';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import Textarea from '@/Modules/App/Components/Atom/Form/Textarea';
import { FormBuilderOptionsInterface } from '@/Modules/FormBuilder/Interface/FormBuilderOptionsInterface';
import { ApiAdminFormBuilderInputService } from '@/Service/Admin/ApiAdminFormBuilderInputService';
import { ApiAdminFormBuilderSectionService } from '@/Service/Admin/ApiAdminFormBuilderSectionService';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import FormBuilderSectionMultipleOption from '@/Modules/FormBuilder/Components/FormBuilderSectionMultipleOption';

interface ComponentProps
{
	section: FormBuilderSectionInterface,
	isLoading: boolean,
	modalContext: ModalContextType,
	refreshSections: (sectionId?: number) => Promise<FormBuilderSectionInterface[] | FormBuilderSectionInterface | undefined>,
	refreshInputs: (sectionId: number) => Promise<any>,
	onUpdateSection: (updatedSection: FormBuilderSectionInterface) => void,
	onDeleteSection: (sectionId: number) => Promise<void>,
	onCreateSubSection: (parentSectionId: number, formBuilderSection: Partial<FormBuilderSectionInterface>) => Promise<any>,
	onCreateInput: (inputData: Partial<FormBuilderInputsInterface>, sectionId: number) => Promise<any>,
	onUpdateInput: (editInputData: Partial<FormBuilderInputsInterface>, inputId: number) => Promise<any>
	onDeleteInput: (inputId: number, sectionId: number) => Promise<any>
	onCreateInputOption: (optionLabel: {
		label: string
	}, inputId: number) => Promise<FormBuilderOptionsInterface | null | undefined>
	onEditInputOption: (editInputOptionData: any, formBuilderInputId: number, formBuilderInputOptionId: number) => Promise<any>
	onDeleteInputOption: (inputId: number, optionId: number) => Promise<any>
	isOpenModal: (isOpen: boolean) => void,
}

interface ComponentState
{
	section: FormBuilderSectionInterface,
	isLoading: boolean,
}

export default class FormBuilderEditSectionComponent extends React.Component<ComponentProps, ComponentState>
{
	formBuilderInputService: ApiAdminFormBuilderInputService;
	formBuilderSectionService: ApiAdminFormBuilderSectionService;
	styles = FormBuilderStyle.section();
	createInputModal = this.props.modalContext;
	createSubSectionModal = this.props.modalContext;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Services
		this.formBuilderInputService = new ApiAdminFormBuilderInputService();
		this.formBuilderSectionService = new ApiAdminFormBuilderSectionService();
	}

	render(): ReactElement
	{
		return (
			<div style={ this.styles.sectionModalContainer }>
				<div style={ { display: 'grid', gridTemplateRows: 'auto 50px', height: '100%' } }>
					<div style={ { maxHeight: 'calc(650px - 80px)', overflow: 'hidden', overflowY: 'scroll' } }>
						{ this.sectionInputContentRender(this.state.section) }
						{ (this.state.section.subSections && this.state.section.subSections.length > 0) &&
							this.state.section.subSections && this.state.section.subSections.map((subSection: FormBuilderSectionInterface) =>
								(
									<React.Fragment key={ subSection.id }>
										{ this.sectionInputContentRender(subSection) }
									</React.Fragment>
								))
						}
					</div>
					<div style={ { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } }>
						{ this.state.section.status !== PublishStateEnum.PUBLISH.value
							? <Button
								label={ 'Publier' }
								type={ 'success' }
								onClick={ () => this.publishSection(this.state.section, true) }
							/>
							: <Button
								label={ 'Brouillon' }
								type={ 'warning' }
								onClick={ () => this.publishSection(this.state.section, false) }
							/>
						}
					</div>
				</div>
			</div>
		);
	}

	// <editor-fold desc="State / Component Mount" defaultstate="collapsed">

	componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState): void
	{
		if (prevProps.section !== this.props.section) {
			this.setState({ section: this.props.section }, () => this.setState({ isLoading: false }));
		}
	}

	componentDidMount(): void
	{
		this.setState({ section: this.props.section }, () => this.setState({ isLoading: false }));
	}

	private initState(): ComponentState
	{
		return {
			section: {} as FormBuilderSectionInterface,
			isLoading: true,
		};
	}

	// </editor-fold>

	// <editor-fold desc="Append Content Methods" defaultstate="collapsed">

	private sectionInputContentRender(section: FormBuilderSectionInterface): ReactElement
	{
		return (
			<div key={ section.id } style={ this.styles.sectionCardContainer }>
				<div style={ this.styles.sectionTitle }>
					<Input
						type={ 'text' }
						name={ `title-edit-section-${ this.props.section.id }` }
						label={ 'Titre' }
						width={ '100%' }
						value={ section.title || '' }
						onChange={ (event: any) => this.onChangeSectionTitle(event, section.id) }
					/>
					<Button
						label={ 'Modifier' }
						type={ 'inline-default-blue' }
						onClick={ () => this.handleUpdateSectionTitle(section.id) }
					/>
				</div>

				<div style={ this.styles.sectionInputsContainer }>
					<div style={ this.styles.sectionViewInputsContainer }>
						<div style={ this.styles.inputsGrid }>
							{ (section.subSections && section.subSections.length > 0)
								?
								<FormBuilderSectionMultipleOption
									label={ section.title }
									parentTypeOption={ section.sectionParentTypeOption }
									listOptions={ this.subSectionTitleList() }
									onUpdateSection={ this.handleEditSectionParentTypeOption.bind(this) }
									addButton={ {
										onClick: (event: any) => this.createNewSubSectionFormRender(event)
									} }
								/>
								: <div style={ {
									position: 'relative',
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									height: 50
								} }>
									<div style={ { fontWeight: 600 } }> Liste des champs</div>
									<div style={ { display: 'flex', gap: 10, alignItems: 'center' } }>
										<Checkbox
											label={ 'Section duplicable' }
											options={ { rowLeftCheckbox: true } }
											name={ `duplicable-section-${ section.id }` }
											isChecked={ section.isDuplicable }
											onCheckedChange={ (event: any) => this.handleSectionDuplicable(event.target.checked, section.id) }
										/>
										<Button
											type={ 'default-blue' }
											label={ 'Ajouter un champ' }
											iconLeft={ <LuPlus/> }
											onClick={ (event: any) => this.handleInputFormRender(event, null, section.id) }
										/>
									</div>
								</div>
							}
							{ this.sectionInputListRender(section) }
						</div>
					</div>
				</div>
				<div style={ this.styles.sectionCardFooter }>
					{ (section.parentId) &&
            <Button
              type={ 'danger' }
              label={ 'Supprimer' }
              iconLeft={ <LuTrash2/> }
              onClick={ async () => await this.handleDeleteSection(section.id) }
            />
					}
				</div>

			</div>
		);
	}

	private sectionInputListRender(section: FormBuilderSectionInterface): ReactElement[]
	{
		return (
			section.formBuilderInputs && section.formBuilderInputs.map((input: FormBuilderInputsInterface) => (
				(this.state.isLoading)
					?
					<div key={ input.id } style={ { width: '50%', display: 'flex', gap: 15, padding: '10px 10px' } }>
						<Skeleton height={ 32 } width={ 350 }/>
						<Skeleton height={ 32 } width={ 32 }/>
					</div>
					:
					<React.Fragment key={ input.id }>
						{ (
							input.type !== FormBuilderInputTypeEnum.SELECTOR
								&& input.type !== FormBuilderInputTypeEnum.CHECKBOX
								&& input.type !== FormBuilderInputTypeEnum.TEXTAREA
							) &&
              <div style={ { width: '50%', display: 'flex', gap: 15, padding: '10px 10px', alignItems: 'flex-end' } }>
                <Input
                  key={ input.id }
                  type={ input.type.toLowerCase() as InputType }
                  width={ (input.type === 'DATE') ? '200px' : '100%' }
                  label={ input.label }
                  name={ `input-${ input.type }-${ input.id }` }
                  placeholder={ input.placeholder }
                  required={ Boolean(input.isRequired) }
                  containerDivWidth={ '100%' }
                  value={ '' }
                  onChange={ () => null }
                />
                <div style={ { display: 'flex', gap: 2 } }>
                  <Button
                    type={ 'default' }
                    iconLeft={ <LuPencilLine/> }
                    onClick={ async (event: any) => this.handleInputFormRender(event, input, null) }
                  />
                  <Button
                    type={ 'danger' }
                    iconLeft={ <LuTrash2/> }
                    onClick={ async () => await this.handleDeleteInput(input.id as number, this.state.section.id) }
                  />
                </div>
              </div>
						}
						{ (input.type === FormBuilderInputTypeEnum.CHECKBOX) &&
              <div style={ { padding: 10, display: 'flex', gap: 15, alignItems: 'center' } }>
                <Checkbox
                  options={ {
										rowLeftCheckbox: true
									} }
                  label={ input.label as string }
                  name={ `checkbox-section-${ input.id }` }
                  onCheckedChange={ (event: any) => null }
                  isChecked={ false }
                />
                <div style={ { display: 'flex', gap: 2 } }>
                  <Button
                    type={ 'default' }
                    iconLeft={ <LuPencilLine/> }
                    onClick={ async (event: any) => this.handleInputFormRender(event, input, null) }
                  />
                  <Button
                    type={ 'danger' }
                    iconLeft={ <LuTrash2/> }
                    onClick={ async () => await this.handleDeleteInput(input.id as number, this.state.section.id) }
                  />
                </div>
              </div>
						}
						{
							(input.type === FormBuilderInputTypeEnum.TEXTAREA) &&
              <div style={ { padding: 10, width: '100%', display: 'flex', gap: 15, alignItems: 'center' } }>
                <Textarea
                  label={ input.label }
                  name={ `textarea-section-${ input.id }` }
                  width={ '100%' }
                />
                <div style={ { display: 'flex', gap: 2 } }>
                  <Button
                    type={ 'default' }
                    iconLeft={ <LuPencilLine/> }
                    onClick={ async (event: any) => this.handleInputFormRender(event, input, null) }
                  />
                  <Button
                    type={ 'danger' }
                    iconLeft={ <LuTrash2/> }
                    onClick={ async () => await this.handleDeleteInput(input.id as number, this.state.section.id) }
                  />
                </div>
              </div>
						}
						{
							(input.type === FormBuilderInputTypeEnum.SELECTOR) &&
              <div style={ { padding: 10, width: '100%', display: 'flex', gap: 15, alignItems: 'flex-end' } }>
                <SelectComponent
                  key={ input.id }
                  label={ input.label }
                  buttonWidth={ 350 }
                  listOptions={ (input.formBuilderOptions) ? input.formBuilderOptions : [] }
                  onSelectedOption={ () => null }
                  renderOptionLabel={ (option) => option.label }
                  required={ Boolean(input.isRequired) }
                  containerDivWidth={ '45%' }
                />
                <div style={ { display: 'flex', gap: 2 } }>
                  <Button
                    type={ 'default' }
                    iconLeft={ <LuPencilLine/> }
                    onClick={ async (event: any) => this.handleInputFormRender(event, input, null) }
                  />
                  <Button
                    type={ 'danger' }
                    iconLeft={ <LuTrash2/> }
                    onClick={ async () => await this.handleDeleteInput(input.id as number, this.state.section.id) }
                  />
                </div>
              </div>
						}
					</React.Fragment>
			))
		);
	}

	private handleInputFormRender(event: any, input: FormBuilderInputsInterface | null, sectionId: number | null): void
	{
		event.stopPropagation();

		this.createInputModal.content(
			'Ajouter un champs',
			<FormBuilderSectionInputComponent
				sectionId={ sectionId }
				input={ input }
				isOpen={ () => true }
				refreshInputs={ this.props.refreshInputs }
				onCreateInput={ this.handleCreateInput.bind(this) }
				onUpdateInput={ this.handleEditInput.bind(this) }
				onCreateInputOption={ this.props.onCreateInputOption }
				onEditInputOption={ this.props.onEditInputOption }
				onDeleteInputOption={ this.props.onDeleteInputOption }
				onDelete={ () => null }
			/>
		);
	}

	private async handleSectionDuplicable(isChecked: boolean, sectionId: number): Promise<void> {
		this.setState({ isLoading: true });
		this.props.modalContext.isLoading(true);

		const updatedSection = { ...this.state.section };

		if (updatedSection.id === sectionId) {
			updatedSection.isDuplicable = isChecked;
			this.setState({ section: updatedSection });
		} else {
			const updatedSubSections = updatedSection.subSections.map(subSection =>
				subSection.id === sectionId ? { ...subSection, isDuplicable: isChecked } : subSection
			);
			updatedSection.subSections = updatedSubSections;
			this.setState({ section: updatedSection });
		}

		await this.formBuilderSectionService.editIsDuplicable(isChecked, sectionId);
		await this.props.refreshSections();

		this.setState({ isLoading: false });
		this.props.modalContext.isLoading(false);
	}

	private createNewSubSectionFormRender(event: any): void
	{
		event.stopPropagation();

		this.createSubSectionModal.content(
			'Ajouter Sous-section',
			<FormBuilderCreateSubSectionFormComponent
				section={ this.state.section }
				onCreateSubSection={ this.handleCreateSubSection.bind(this) }
			/>
		);
	}

	// </editor-fold>

	// <editor-fold desc="CRUD Section Methods" defaultstate="collapsed">

	private onChangeSectionTitle(event: any, sectionId: number): void
	{
		const newTitle = event.target.value;

		// Vérifier si l'ID de la section correspond à la section principale ou à une sous-section
		if (this.state.section.id === sectionId) {
			// Mise à jour de la section principale
			this.setState((prevState: ComponentState) => ({
				section: {
					...prevState.section,
					title: newTitle,
				}
			}));
		} else {
			// Mise à jour d'une sous-section
			this.setState((prevState: ComponentState) => ({
				section: {
					...prevState.section,
					subSections: prevState.section.subSections.map(subSection =>
						subSection.id === sectionId
							? { ...subSection, title: newTitle }
							: subSection
					)
				}
			}));
		}
	}

	private handleUpdateSectionTitle(sectionId: number): void
	{
		this.setState({ isLoading: true });
		this.props.modalContext.isLoading(true);

		const updatedSection = {
			...this.props.section,
			title: this.state.section.title,
		};

		if (this.state.section.id === sectionId) {
			this.props.onUpdateSection(updatedSection);
			this.props.modalContext.isLoading(false);
		} else {
			const updatedSubSection = this.state.section.subSections.find(subSection => subSection.id === sectionId);
			if (updatedSubSection) {
				this.props.onUpdateSection(updatedSubSection);
				this.props.modalContext.isLoading(false);
			}
		}

		this.setState({ isLoading: false });
	}

	private handleEditSectionParentTypeOption(typeOptionData: FormBuilderInputTypeEnum | null): void
	{
		this.setState({ isLoading: true });
		this.props.modalContext.isLoading(true);

		const updatedSection = {
			...this.props.section,
			sectionParentTypeOption: (typeOptionData !== null) ? typeOptionData : null,
		};

		this.props.onUpdateSection(updatedSection);

		this.props.modalContext.isLoading(false);
		this.setState({ isLoading: false });
	}

	private async handleDeleteSection(sectionId: number): Promise<void>
	{
		this.setState({ isLoading: true });
		this.props.modalContext.isLoading(true);

		await this.props.onDeleteSection(sectionId);

		await this.handleRefreshSection();

		this.props.isOpenModal(true);
	}

	private async handleCreateSubSection(parentSectionId: number, formBuilderSection: Partial<FormBuilderSectionInterface>): Promise<void>
	{
		this.setState({ isLoading: true });
		this.createSubSectionModal.isOpen(false);
		this.props.modalContext.isLoading(true);

		await this.props.onCreateSubSection(parentSectionId, formBuilderSection);

		await this.handleRefreshSection();

		this.props.isOpenModal(true);
	}

	private async handleRefreshSection(): Promise<void>
	{
		this.setState({ isLoading: true });

		const response = await this.props.refreshSections(this.state.section.id);

		if (response) {
			this.setState({ section: response as FormBuilderSectionInterface },
				() =>
				{
					this.setState({ isLoading: false });
					this.props.modalContext.isLoading(false);
				});
		}
	}

	private async publishSection(section: FormBuilderSectionInterface, isPublish: boolean): Promise<any>
	{
		try {
			this.setState({ isLoading: true });
			this.props.modalContext.isLoading(true);

			const publishStatus = isPublish ? PublishStateEnum.PUBLISH.value : PublishStateEnum.DRAFT.value;

			await this.formBuilderSectionService.edit(
				{
					title: section.title,
					sectionParentTypeOption: section.sectionParentTypeOption,
					sortOrder: this.props.section.sortOrder,
					status: publishStatus
				},
				section.id
			);

			if (section.subSections.length > 0) {
				const subSectionPromises = section.subSections.map((subSection: FormBuilderSectionInterface) =>
				{
					return this.formBuilderSectionService.edit(
						{
							title: subSection.title,
							sectionParentTypeOption: subSection.sectionParentTypeOption,
							sortOrder: subSection.sortOrder,
							status: publishStatus
						},
						subSection.id
					);
				});

				await Promise.all(subSectionPromises);
			}

			this.setState({ isLoading: false });
			this.props.refreshSections();
			this.props.modalContext.isOpen(false);

		} catch (error: any) {
			console.log('Something wrong with publish section', error.message);
			this.setState({ isLoading: false });
		}
	}


	// </editor-fold>

	// <editor-fold desc="CRUD Input Methods" defaultstate="collapsed">

	private async handleCreateInput(inputData: Partial<FormBuilderInputsInterface>, sectionId: number): Promise<void>
	{
		// Set Loading
		this.setState({ isLoading: true });
		this.createInputModal.isOpen(true);

		const newInput = await this.props.onCreateInput(inputData, sectionId);

		if (newInput) {
			this.setState(prevState => ({
				section: {
					...prevState.section,
					formBuilderInputs: [...prevState.section.formBuilderInputs, newInput]
				},
			}), async () =>
			{
				await this.handleRefreshSection();
				this.setState({ isLoading: false });
			});

		} else {
			this.setState({ isLoading: false });
			// Handle error if newInput is undefined or null
			console.error('Failed to create new input');
		}
	}

	private async handleEditInput(editInputData: Partial<FormBuilderInputsInterface>, inputId: number): Promise<void>
	{
		// Set Loading
		this.setState({ isLoading: true });
		this.createInputModal.isOpen(true);

		const editInput = await this.props.onUpdateInput(editInputData, inputId);

		if (editInput) {
			this.setState(prevState => ({
				section: {
					...prevState.section,
					formBuilderInputs: prevState.section.formBuilderInputs.map(input =>
						input.id === inputId ? { ...input, ...editInputData } : input
					)
				},
			}), async () =>
			{
				await this.handleRefreshSection();
				this.setState({ isLoading: false });
			});
		} else {
			this.setState({ isLoading: false });
			// Handle error if newInput is undefined or null
			console.error('Failed to edit input');
		}
	}

	private async handleDeleteInput(inputId: number, sectionId: number): Promise<void>
	{
		// Set Loading
		this.setState({ isLoading: true });

		// Delete Input
		const newInputList = await this.props.onDeleteInput(inputId, sectionId);

		if (newInputList) {
			this.setState(prevState => ({
				section: {
					...prevState.section,
					formBuilderInputs: newInputList
				}
			}), async () =>
			{
				await this.handleRefreshSection();
				this.setState({ isLoading: false });
			});
		} else {
			this.setState({ isLoading: false });
			// Handle error if newInput is undefined or null
			console.error('Failed to delete new input');
		}
	}

	// </editor-fold>

	// <editor-fold desc="CRUD Input Options Methods" defaultstate="collapsed">


	// </editor-fold>

	// <editor-fold desc="Private Methods" defaultstate="collapsed">

	private subSectionTitleList(): any[]
	{
		const subSectionTitleList: any[] = [];
		this.state.section.subSections && this.state.section.subSections.map((subSection: FormBuilderSectionInterface) =>
			(
				subSectionTitleList.push({ label: subSection.title })
			));

		return subSectionTitleList;
	}

	// </editor-fold>
}