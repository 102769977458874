import React, { ReactElement } from 'react';
import Checkbox from '@/Modules/App/Components/Atom/Form/Checkbox';
import { LegalNoticeContentHeader } from '@/Modules/LegalNotice/Style/LegalNoticeContentHeader';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { Alert } from 'react-bootstrap';

interface ComponentProps
{
	isConsumerFormFilled: boolean,
	selectedClient: ClientInterface | null,
	isHeader: boolean,
	isLogo: boolean,
	onCheckIsHeader: (event: React.ChangeEvent<HTMLInputElement>) => void
	onCheckIsLogo: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default class HeaderOptionContentComponent extends React.Component<ComponentProps, any>
{
	render(): ReactElement
	{
		return (
			<>
				<div style={ LegalNoticeContentHeader.mainContainerStyle() }>
					<div style={ LegalNoticeContentHeader.mainContainerBodyStyle() }>
						<div>
							Options d'annonce
						</div>
					</div>
					{ (!this.props.isConsumerFormFilled || !this.props.selectedClient)
						? this.renderAlert()
						: this.renderOptions()
					}
				</div>
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private renderAlert(): ReactElement
	{
		return (
			<>
				{ (!this.props.isConsumerFormFilled || !this.props.selectedClient) &&
          <Alert style={ { fontSize: '12px', padding: '5px', margin: 0 } } variant={ 'warning' }>
						Pour accéder aux options, merci de remplir les selections suivantes : <br/>
						{(!this.props.selectedClient)
							? <span> • Client </span>
							: ''
						} 
						{(!this.props.isConsumerFormFilled)
							? <span> • Société (client final) </span>
							: '' 
						}
          </Alert>
				}
			</>
		);
	}

	private renderOptions(): ReactElement
	{
		return (
			<>
				<div style={ { display: 'flex', justifyContent: 'space-between', position: 'relative' } }>
					<div className={ this.props.isConsumerFormFilled ? '' : 'disabled-item' }>
						<Checkbox
							label="Entête d'annonce ?"
							name="isHeader"
							isChecked={ this.props.isHeader }
							onCheckedChange={ (event: any) => this.props.onCheckIsHeader(event) }
						/>
					</div>

					<div
						className={ (this.props.selectedClient && this.props.selectedClient.logos.length > 0) ? '' : 'disabled-item' }
					>
						{ (this.props.selectedClient?.logos.length === 0)
							? <>
								<Alert style={ { fontSize: '12px', padding: '3px', marginBottom: '0px' } } variant={ 'warning' }>
									Aucun logo disponible.
								</Alert>
							</>
							: <Checkbox
								label="Voulez vous mettre le logo ?"
								name="isLogo"
								isChecked={ this.props.isLogo }
								onCheckedChange={ (event: any) => this.props.onCheckIsLogo(event) }
							/>
						}
					</div>
				</div>
			</>
		);
	}

	//</editor-fold>
}