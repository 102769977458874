import React, { ReactElement } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { ComponentLayoutProps } from '@/Modules/App/Components/Layout/Layout';

export default class LayoutAuth extends React.Component
	<ComponentLayoutProps>
{
	render(): ReactElement
	{
		return (
			<>
				<div className="publisur-app-inner">
					<div style={ {
						width: '100%',
						height: '100vh',
						display: 'grid',
						gridTemplateColumns: 'repeat(2, 1fr)'
					} }>
						<div style={ {
							backgroundColor: CssVariableEnum['--color-grey-800'],
							borderRadius: 25,
							margin: 20,
							backgroundImage: 'url("/img/background-image-auth-publisur-3.jpg")',
							backgroundPosition: 'center',
							overlay: 'auto',
							backgroundSize: 'cover'
						} }>
							<div style={ {
								height: '100%',
								width: '100%',
								display: 'grid',
								gridTemplateRows: 'repeat(2, 1fr)',

							} }>
								<div style={{ padding: 40, fontSize: 14, fontWeight: 600, color: CssVariableEnum['--color-white'], display: 'flex'}}>
									<div style={{ height: 50, display: 'flex', width: '100%', alignItems:'center', }}>
										<div style={{ width: 200 }}>Hello world</div>
										<div style={{ width: '100%', height: 1, backgroundColor: CssVariableEnum['--color-white'], alignSelf: 'center'}}></div>
									</div>
								</div>
								<div style={ {
									display: 'flex',
									alignItems: 'flex-end',
									height: '100%',
									backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
									backgroundSize: 'cover',
									borderBottomLeftRadius: 25,
									borderBottomRightRadius: 25,
									padding: 40
								} }>
									<div>
										<h1 style={ {
											color: CssVariableEnum['--color-white'],
											fontSize: 72,
											lineHeight: '65px',
											letterSpacing: '-1.5px'
										} }>
											Publication & Gestion <br/> de vos annonces légales <br/> Pros
										</h1>
										<h2 style={ { fontSize: 26, marginTop: 20, color: CssVariableEnum['--color-white'] } }>
											Publiez vos annonces légales en toute autonomie, rapidement et sous l'aide des conseils de nos
											experts.
										</h2>
									</div>
								</div>
							</div>
						</div>
						<main style={{ display: 'grid', gridTemplateRows: '150px auto'}}>
							<div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: 150, alignItems: 'center'}}>
								<img src="/img/logo-publisur-noir.svg" alt="logo publisur" style={{ width: 150}}/>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<div style={{ width: 400}}>
									{ this.props.children }
								</div>
							</div>
						</main>
					</div>
				</div>
			</>
		);
	}
}