import React, { ReactElement } from 'react';
import { LegalNoticePriceStyle } from '@/Modules/LegalNotice/Style/LegalNoticePriceStyle';
import { ViewLegalNoticeState } from '@/Modules/LegalNotice/Admin/View/CreateLegalNoticeView';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { LuChevronsDown, LuChevronsUp } from 'react-icons/lu';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { Alert } from 'react-bootstrap';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

interface ComponentProps
{
  legalNotice: ViewLegalNoticeState,
  selectedDepartment: DepartmentInterface,
  selectedCategory: FormBuilderCategoryInterface,
  validateLegalNotice: (event: any) => void,
  isHeaderCharacterCount?: number,
  isFormComplete: boolean,
  isCompactRender: boolean
  isAdmin: boolean,
}

interface ComponentState
{
  isOpen: boolean,
  isFixedPrice: boolean,
  annexPrice: number,
  printPrice: number,
  shippingCostPrice: number,
  bodaccPrice: number,
  isPriceLoaded: boolean
}

export default class LegalNoticePriceFormComponent extends React.Component<ComponentProps, ComponentState>
{
  legalNoticePriceService: LegalNoticePriceService;

  constructor(props: any)
  {
    super(props);

    // Service
    this.legalNoticePriceService = new LegalNoticePriceService(this.props.isAdmin);

    // State
    this.state = {
      isOpen: !this.props.isCompactRender,
      isFixedPrice: false,
      annexPrice: 0,
      printPrice: 0,
      shippingCostPrice: 0,
      bodaccPrice: 0,
      isPriceLoaded: false
    };
  }

  render(): ReactElement
  {
    return (
      <>
        <div style={ LegalNoticePriceStyle.mainContainerStyle(this.state.isOpen) }>
          <div style={ LegalNoticePriceStyle.bodyContainerStyle() }>
            <div style={{ flexGrow: 1 }}>
              { this.state.isOpen
                ? this.fullPriceRender(this.state.isFixedPrice)
                : this.compactPriceRender()
              }
            </div>
          </div>
        </div>
      </>
    );
  }

  //<editor-fold desc="Render methods" defaultstate="collapsed">

  private fullPriceRender(isFixedPrice: boolean): ReactElement
  {
    const calculatedPrice = this.calculatedPrice();

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '350px' }}>
            <div style={ FontStyle.h4() }>Prix de l'annonce</div>
            <div style={ LegalNoticePriceStyle.fullPriceFormLineStyle() }>
              <span style={ { fontWeight: 500, color: CssVariableEnum['--color-grey-400'] } }>
                Prix Unitaire <span style={{ fontSize: '13px' }}>(HT)</span>:
              </span>
              <b>{ this.state.annexPrice }  €</b>
            </div>

            { !isFixedPrice &&
              <div style={ LegalNoticePriceStyle.fullPriceFormLineStyle() }>
                <span style={ { fontWeight: 500, color: CssVariableEnum['--color-grey-400'] } }>Nombre de caractères :</span>
                <b>{ calculatedPrice.nbCharacter }</b>
              </div>
            }

            <Alert style={{ fontSize: '12px', padding: '3px', marginTop: '10px', marginBottom: '10px'  }} variant={'info'}>
              Détails du prix en validant l'annonce
            </Alert>

            { this.subTotalRender(calculatedPrice.price, false) }
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'  }}>
            { this.buttonOpenRender() }
            { this.state.isOpen &&
              <Button
                type={ 'default-blue' }
                disabled={ !this.props.isFormComplete }
                onClick={ (event: any) => this.props.validateLegalNotice(event) }
              >
                Valider l'annonce
              </Button>
            }
          </div>
        </div>
      </>
    );
  }

  private compactPriceRender(): ReactElement
  {
    const calculatedPrice = this.calculatedPrice();

    return (
      <>
        <div style={ LegalNoticePriceStyle.displayFlexBetween() }>
          { this.subTotalRender(calculatedPrice.price, true) }
          { this.props.isCompactRender && this.buttonOpenRender() }
        </div>
      </>
    );
  }

  private buttonOpenRender(): ReactElement
  {
    return (
      <>
        <Button
          style={ { justifyContent: 'end' } }
          type={ 'pagination' }
          onClick={ () => (this.setState({ isOpen: !this.state.isOpen })) }
        >
          { (this.state.isOpen) ? <LuChevronsDown/> : <LuChevronsUp/> }
        </Button>
      </>
    );
  }

  private subTotalRender(price: number, isCompact: boolean): ReactElement
  {
    return (
      <>
        <div style={ LegalNoticePriceStyle.compactContainerStyle((isCompact) ? '80%' : '100%') }>
          <div style={ { fontSize: 16, fontWeight: 500, letterSpacing: 0.6 } }>
            Total <span style={{ fontSize: '13px' }}>(HT)</span>:
          </div>
          <div style={ { fontSize: 16, fontWeight: 500, letterSpacing: 0.6 } }>
            { price } €
          </div>
        </div>
      </>
    );
  }

  //</editor-fold>

  //<editor-fold desc="State methods" defaultstate="collapsed">

  async componentDidMount() {
    await this.updateState();
  }

  async componentDidUpdate(prevProps: Readonly<ComponentProps>): Promise<void>
  {
    if (
      prevProps.legalNotice !== this.props.legalNotice ||
      prevProps.legalNotice.numberOfCopies !== this.props.legalNotice.numberOfCopies ||
      prevProps.selectedDepartment !== this.props.selectedDepartment ||
      prevProps.selectedCategory !== this.props.selectedCategory
    ) {
      if (!this.state.isPriceLoaded) {
        await this.updateState({ isOpen: this.state.isOpen });
      }
    }
  }

  private async updateState(partialState: Partial<ComponentState> = {}): Promise<void>
  {
    this.setState({isPriceLoaded: true});

    // Init vars price
    const isFixedPrice = this.legalNoticePriceService.isFixedPrice(
      this.props.selectedDepartment,
      this.props.selectedCategory
    );
    const annexPrice = this.legalNoticePriceService.getAnnexPrice(
      this.props.selectedDepartment,
      this.props.selectedCategory,
      isFixedPrice
    );
    const printPrice = await this.legalNoticePriceService.getPrintPrice();

    // Calculated
    const calculatedShippingCostPrice = await this.legalNoticePriceService.getShippingCostPrice(this.props.legalNotice.numberOfCopies);
    const bodaccPrice = this.props.legalNotice.option.isBodacc ? await this.legalNoticePriceService.getBodacPrice() : 0;

    this.setState((prevState: any) => ({
      ...prevState,
      ...partialState,
      isFixedPrice,
      annexPrice,
      shippingCostPrice: calculatedShippingCostPrice,
      printPrice,
      bodaccPrice,
      isOpen: partialState.isOpen ?? prevState.isOpen,
      isPriceLoaded: false
    }));
  }

  //</editor-fold>

  //<editor-fold desc="Private methods" defaultstate="collapsed">

  private calculatedPrice(): { price: number, nbCharacter?: number }
  {
    let totalPrice = this.state.isFixedPrice
      ? this.state.annexPrice
      : this.state.annexPrice * (
        stripHtmlTags(this.props.legalNotice.content).length
      + stripHtmlTags(this.props.legalNotice.title).length
      + stripHtmlTags(this.props.legalNotice.signature).length
      + (this.props.isHeaderCharacterCount || 0)
    );

    // Additional Price for PAPER
    if (this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value) {
      totalPrice += this.state.printPrice;
      totalPrice += this.state.shippingCostPrice;
    }

    if (this.props.legalNotice.option.isBodacc) {
      totalPrice += this.state.bodaccPrice;
    }

    return {
      price: parseFloat((totalPrice).toFixed(2)),
      nbCharacter: this.state.isFixedPrice
        ? undefined
        : (
          stripHtmlTags(this.props.legalNotice.content).length
          + stripHtmlTags(this.props.legalNotice.title).length
          + stripHtmlTags(this.props.legalNotice.signature).length
          + (this.props.isHeaderCharacterCount || 0)
        )
    };
  }

  //</editor-fold>
}
