import BaseService from '@/Service/Common/BaseService';
import { HttpVerbEnum } from '@/Enum/HttpVerbEnum';

export class ApiPaymentService extends BaseService {
  paymentUrl: string = `${ BaseService.API_URL }/public/payment`;
  // paymentUrl: string = `https://f46b088f72af46e9ec55fda4ebb55c22.serveo.net/public/payment`;

  async generatePaymentLink(legalNoticeId: number, companyId: number): Promise<any>
  {
    try {
      const response = await fetch(
        this.paymentUrl + '/generate-link/' + legalNoticeId + '/' + companyId,
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async createPayment(legalNoticeId: number, companyId: number, paymentData: any): Promise<any>
  {
    try {
      const response = await fetch(
        this.paymentUrl + '/create/' + legalNoticeId + '/' + companyId,
        {
          method: HttpVerbEnum.POST,
          headers: this.buildHeaders(),
          body: JSON.stringify(paymentData)
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async paymentSuccess(transId: number, params: URLSearchParams, paymentStatus: string): Promise<any>
  {
    try {
      const response = await fetch(
        this.paymentUrl + '/callback/'+ paymentStatus + '/' + transId + '?' + params.toString(),
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async paymentFailure(): Promise<any>
  {
    try {
      const response = await fetch(
        this.paymentUrl + '/callback/failure',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async paymentNotify(): Promise<any>
  {
    try {
      const response = await fetch(
        this.paymentUrl + '/callback/notify',
        {
          method: HttpVerbEnum.GET,
          headers: this.buildHeaders(),
        }
      );

      // Response data
      return await response.json();
    } catch (error: any) {
      console.error(error.message);
    }
  }
}