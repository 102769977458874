import React, { ReactElement } from 'react';
import { LegalNoticePriceStyle } from '@/Modules/LegalNotice/Style/LegalNoticePriceStyle';
import { ViewLegalNoticeState } from '@/Modules/LegalNotice/Admin/View/CreateLegalNoticeView';
import { DepartmentInterface } from '@/Modules/LegalNotice/Interface/DepartmentInterface';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';
import { LegalNoticePriceService } from '@/Service/LegalNoticePriceService';
import { PricingLineInterface } from '@/Modules/Pricing/Interface/PricingLineInterface';
import { NewspaperTypeEnum } from '@/Enum/NewspaperTypeEnum';
import { stripHtmlTags } from '@/Utils/StripHtmlTags';
import TableComponent, { TableHeaderListInterface } from '@/Modules/App/Components/Atom/Table/TableComponent';
import { PaginateInterface } from '@/Modules/App/Interface/PaginateInterface';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

interface ComponentProps
{
	legalNotice: ViewLegalNoticeState,
	selectedDepartment: DepartmentInterface,
	selectedCategory: FormBuilderCategoryInterface,
	isHeaderCharacterCount?: number,
	isTableOffCanvas?: boolean,
	isAdmin: boolean
}

interface ComponentState
{
	isFixedPrice: boolean,
	annexPrice: number,
	printPrice: PricingLineInterface | null,
	shippingCostPrice: PricingLineInterface | null,
	bodaccPrice: PricingLineInterface | null,
	isPriceLoaded: boolean,
}

export default class LegalNoticePriceComponent extends React.Component<ComponentProps, ComponentState>
{
	legalNoticePriceService: LegalNoticePriceService;

	constructor(props: any)
	{
		super(props);

		// Service
		this.legalNoticePriceService = new LegalNoticePriceService(this.props.isAdmin);

		// State
		this.state = {
			isFixedPrice: false,
			annexPrice: 0,
			printPrice: null,
			shippingCostPrice: null,
			bodaccPrice: null,
			isPriceLoaded: false,
		};
	}

	render(): ReactElement
	{
		const calculatedPrice = this.calculatedPrice();
		const pricesDetails = this.detailedPrices();

		return (
			<>
				<div style={ { width: '100%' } }>
					<TableComponent
						key={ Math.random().toString(36).substr(2, 9) }
						paginateList={ this.tableDataPrices(pricesDetails).items }
						onClickRow={ () => null }
						notFoundValuesText={ 'Aucune valeur trouvée' }
						params={ () => null }
						tableHeaderList={ this.buildHeaderTable() }
						isStickyHeader={ false }
						isResizeColumns={ false }
					/>
				</div>
				<div style={ { width: '100%' } }>
					<div style={ LegalNoticePriceStyle.bodyContainerStyle() }>
						<div style={ { flexGrow: 1 } }>
							<div>
								{ this.subTotalRender(calculatedPrice.priceInclVat, calculatedPrice.priceExclVat) }
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="Render methods" defaultstate="collapsed">

	private subTotalRender(priceInclVat: number, priceExclVat: number): ReactElement
	{
		return (
			<>
				<div style={ {
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end',
					flexDirection: 'column',
					alignItems: 'end'
				} }>
					<div style={ { display: 'grid', gridTemplateColumns: '200px 100px', gap: 20 } }>
						<div style={ { textAlign: 'right', padding: 8 } }>
							<div style={ FontStyle.littleGrey() }>Total (HT)</div>
							<div style={ FontStyle.h4() }>Montant total</div>
						</div>
						<div style={ { textAlign: 'left', padding: 8 } }>
							<div style={ FontStyle.littleGrey() }>{ priceExclVat } €</div>
							<div style={ FontStyle.h4() }>{ priceInclVat } €</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	private buildHeaderTable(): TableHeaderListInterface[]
	{
		return [
			{
				columnTitle: 'Libellé',
				orderTag: null,
				minWidth: 100,
				width: (this.props.isTableOffCanvas) ? 485 : '100%',
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string',
				dataTag: 'label'
			},
			{
				columnTitle: 'Prix',
				orderTag: null,
				minWidth: 50,
				width: 80,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string',
				dataTag: 'priceUnit'
			},
			{
				columnTitle: 'Qte',
				orderTag: null,
				minWidth: 50,
				width: 50,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string',
				dataTag: 'quantity'
			},
			{
				columnTitle: 'Tva',
				orderTag: null,
				minWidth: 50,
				width: 50,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string',
				dataTag: 'vatRate'
			},
			{
				columnTitle: 'Total',
				orderTag: null,
				minWidth: 100,
				width: 100,
				justifyContent: 'left',
				fontWeight: 400,
				type: 'string',
				dataTag: 'priceInclVat'
			}
		];
	}

	private tableDataPrices(priceDetails: any): PaginateInterface
	{
		const priceItems: any[] = [{
			label: 'Annonce légale',
			priceUnit: priceDetails.legalNoticePrice.priceUnit,
			priceInclVat: priceDetails.legalNoticePrice.priceInclVat,
			priceExclVat: priceDetails.legalNoticePrice.priceExclVat,
			quantity: (priceDetails.legalNoticePrice.nbCharacters) ? priceDetails.legalNoticePrice.nbCharacters : 1,
			vatRate: priceDetails.legalNoticePrice.vatRate,
		}];

		if (this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value) {
			priceItems.push({
					label: 'Journal Papier',
					priceUnit: priceDetails.printPrice.priceUnit,
					priceInclVat: priceDetails.printPrice.priceInclVat,
					priceExclVat: priceDetails.printPrice.priceExclVat,
					vatRate: priceDetails.printPrice.vatRate,
					quantity: null,
				},
				{
					label: 'Frais d\'envoi',
					priceUnit: priceDetails.shippingCostPrice.priceUnit,
					priceInclVat: priceDetails.shippingCostPrice.priceInclVat,
					priceExclVat: priceDetails.shippingCostPrice.priceExclVat,
					vatRate: priceDetails.shippingCostPrice.vatRate,
					quantity: this.props.legalNotice.numberOfCopies,
				});
		}

		if (this.props.legalNotice.option.isBodacc) {
			priceItems.push(
				{
					label: 'Bodacc',
					priceUnit: priceDetails.bodaccPrice.priceUnit,
					priceInclVat: priceDetails.bodaccPrice.priceInclVat,
					priceExclVat: priceDetails.bodaccPrice.priceExclVat,
					vatRate: priceDetails.bodaccPrice.vatRate,
					quantity: null,
				}
			);
		}

		return {
			items: priceItems,
			itemsPerPage: 10,
			totalCount: 20,
			totalPages: 1
		};
	}

	//</editor-fold>

	//<editor-fold desc="State methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.updateState();
	}

	async componentDidUpdate(prevProps: Readonly<ComponentProps>): Promise<void>
	{
		if (
			prevProps.legalNotice !== this.props.legalNotice ||
			prevProps.selectedDepartment !== this.props.selectedDepartment ||
			prevProps.selectedCategory !== this.props.selectedCategory
		) {
			if (!this.state.isPriceLoaded) {
				await this.updateState();
			}
		}
	}

	private async updateState(): Promise<void>
	{
		if (this.state.isPriceLoaded) {
			return;
		}

		// Init vars price
		const isFixedPrice = this.legalNoticePriceService.isFixedPrice(
			this.props.selectedDepartment,
			this.props.selectedCategory
		);
		const annexPrice = this.legalNoticePriceService.getAnnexPrice(
			this.props.selectedDepartment,
			this.props.selectedCategory,
			isFixedPrice
		);
		const printPrice = await this.legalNoticePriceService.getPrintPriceFull();
		const shippingCostPrice = await this.legalNoticePriceService.getShippingCostPriceFull(this.props.legalNotice.numberOfCopies);
		const bodaccPrice = this.props.legalNotice.option.isBodacc ? await this.legalNoticePriceService.getBodacPriceFull() : 0;

		this.setState((prevState: any) => ({
			...prevState,
			isFixedPrice,
			annexPrice,
			shippingCostPrice,
			printPrice,
			bodaccPrice,
			isPriceLoaded: true
		}));
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private calculatedPrice(): { priceInclVat: number, priceExclVat: number, nbCharacter?: number }
	{
		const VAT_RATE = 0.2;
		const PRINT_VAT_RATE = 0.021;

		const nbCharacters = this.state.isFixedPrice
			? 1
			: (
				stripHtmlTags(this.props.legalNotice.content).length +
				stripHtmlTags(this.props.legalNotice.title).length +
				stripHtmlTags(this.props.legalNotice.signature).length +
				(this.props.isHeaderCharacterCount || 0)
			);

		const legalNoticePrice = this.state.annexPrice * nbCharacters;

		const printPrice = this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value
			? (this.state.printPrice?.price || 0)
			: 0;

		const shippingCostPrice = this.props.legalNotice.option.publishType.toString() === NewspaperTypeEnum.PAPER.value
			? (this.state.shippingCostPrice?.price || 0)
			: 0;

		const bodaccPrice = this.props.legalNotice.option.isBodacc
			? this.state.bodaccPrice?.price || 0
			: 0;

		const legalNoticePriceIncVat = legalNoticePrice * (1 + VAT_RATE);
		const printPriceIncVat = printPrice * (1 + PRINT_VAT_RATE);
		const shippingCostPriceIncVat = shippingCostPrice * (1 + VAT_RATE);
		const bodaccPriceIncVat = bodaccPrice * (1 + VAT_RATE);

		const totalPriceExclVat = legalNoticePrice + printPrice + shippingCostPrice + bodaccPrice;
		const totalPriceInclVat = legalNoticePriceIncVat + printPriceIncVat + shippingCostPriceIncVat + bodaccPriceIncVat;

		return {
			priceInclVat: parseFloat(totalPriceInclVat.toFixed(2)),
			priceExclVat: parseFloat(totalPriceExclVat.toFixed(2)),
			nbCharacter: this.state.isFixedPrice ? undefined : nbCharacters
		};
	}

	private detailedPrices(): any
	{
		const calculatedPrice = this.calculatedPrice();

		return {
			legalNoticePrice: {
				priceUnit: this.state.annexPrice,
				priceInclVat: (this.state.annexPrice * (calculatedPrice.nbCharacter ?? 1) * 1.2).toFixed(2),
				priceExclVat: (this.state.annexPrice * (calculatedPrice.nbCharacter ?? 1)).toFixed(2),
				nbCharacters: calculatedPrice.nbCharacter,
				vatRate: 20
			},
			printPrice: {
				priceUnit: this.state.printPrice ? this.state.printPrice.price.toFixed(2) : '0.00',
				priceInclVat: this.state.printPrice ? ((this.state.printPrice.price * 1.021)).toFixed(2) : '0.00',
				priceExclVat: this.state.printPrice ? (this.state.printPrice.price).toFixed(2) : '0.00',
				vatRate: this.state.printPrice ? this.state.printPrice.vatRate : 0
			},
			shippingCostPrice: {
				priceUnit: this.state.shippingCostPrice ? this.state.shippingCostPrice.price.toFixed(2) : '0.00',
				priceInclVat: this.state.shippingCostPrice ? (this.state.shippingCostPrice.price * 1.2).toFixed(2) : '0.00',
				priceExclVat: this.state.shippingCostPrice ? this.state.shippingCostPrice.price.toFixed(2) : '0.00',
				vatRate: this.state.shippingCostPrice ? this.state.shippingCostPrice.vatRate : 0
			},
			bodaccPrice: {
				priceUnit: this.state.bodaccPrice ? this.state.bodaccPrice.price.toFixed(2) : '0.00',
				priceInclVat: this.state.bodaccPrice ? (this.state.bodaccPrice.price * 1.2).toFixed(2) : '0.00',
				priceExclVat: this.state.bodaccPrice ? this.state.bodaccPrice.price.toFixed(2) : '0.00',
				vatRate: this.state.bodaccPrice ? this.state.bodaccPrice.vatRate : 0
			}
		};
	}

	//</editor-fold>
}
