import React, { ReactElement } from 'react';
import { ComponentLayoutProps } from '@/Modules/App/Components/Layout/Layout';

export default class LayoutPublic extends React.Component<ComponentLayoutProps>
{
	render(): ReactElement
	{
		return (
			<>
				<div className="publisur-app-inner">
					<div style={ { display: 'grid', gridTemplateRows: '150px auto' } }>
						<div
							style={ { width: '100%', display: 'flex', justifyContent: 'center', height: 150, alignItems: 'center' } }>
							<img src="/img/logo-publisur-noir.svg" alt="logo publisur" style={ { width: 150 } }/>
						</div>
						<div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
							<div style={ { width: '95%' } }>
								{ this.props.children }
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}