import React, { ReactElement } from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import AuthFormComponent, { AuthViewState } from '../Components/Form/AuthFormComponent';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { AuthModel } from '@/Modules/Auth/Model/AuthModel';
import { UserRoleEnum } from '@/Enum/UserRoleEnum';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { Helmet } from 'react-helmet';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuUser } from 'react-icons/lu';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { AuthViewStyle } from '@/Modules/Auth/Style/AuthViewStyle';
import { BaseStyle } from '@/Style/BaseStyle';

interface ViewState {
	authModel: AuthModel,
	errorMessage: string | null,
	isLoading: boolean
}

export default class AuthView extends React.Component
	<any, ViewState>
{
	static contextType = AuthContext;
	context!: AuthContextType;
	navigation: NavigateFunction;

	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Navigate
		this.navigation = props.navigation;

		// Bind
		this.handleAuthForm = this.handleAuthForm.bind(this);

		// Document title
		document.title = 'Publisur - Auth';
	}

	render(): ReactElement
	{
		return (
			<>
				<Helmet>
					<meta name="robots" content="noindex"/>
				</Helmet>
				<h2 style={ AuthViewStyle.h2() }>Connexion</h2>
				{ this.state.errorMessage && (
					<div style={{ textAlign: 'center' }} className="error-message" role="alert">
						{ this.state.errorMessage }
					</div>
				)}
				{ (this.state.isLoading)
					? <LoaderComponent timeout={ 20000 } height={ 200 }/>
					: <AuthFormComponent onFormSubmit={ this.handleAuthForm } email={ this.state.authModel?.email }/>
				}
				<div style={ AuthViewStyle.mainContainer() }>
					<div style={ AuthViewStyle.border() }></div>
					<div style={ AuthViewStyle.flexJustifyContentCenter() }>ou</div>
					<div style={ AuthViewStyle.border() }></div>
				</div>
				<div style={ AuthViewStyle.textUnderForm() }>
					Je suis un entrepreneur, un
					particulier. Je ne souhaite pas de compte
				</div>
				<div style={ BaseStyle.displayFlex('center', 'center') }>
					<Button type={ 'default' } iconLeft={ <LuUser/> }
									onClick={ () => this.props.navigation('/particular/legal-notice') }>
						Publier sans compte
					</Button>
				</div>
				{ this.authFooterJsx() }
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		this.setState({ isLoading: false });
		LocalStorageService.clearLocalStorage();
	}

	private initState(): ViewState
	{
		return {
			authModel: new AuthModel('', ''),
			errorMessage: null,
			isLoading: true,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private authFooterJsx(): ReactElement
	{
		return (
			<>
				<div className="auth-footer" style={ { marginTop: 100 } }>
					<div className="forget-password">
						<Link to="/ask-forgot-password" className="text-small">Mot de passe oublié ?</Link>
					</div>
					<div className="text-small">
						Vous souhaitez créer un compte ? Contacter notre équipe.
					</div>
					<div className="auth-footer-terms">
						<small className="text-small">
							En cliquant sur les boutons de connexion ci-dessus, vous reconnaissez avoir lu, compris et accepté
							les <Link to=""> Conditions générales</Link> et la <Link to=""> Politique de confidentialité </Link> de
							Publisur.
						</small>
					</div>
				</div>
			</>
		);
	}

	private async handleAuthForm(formData: AuthViewState)
	{
		this.setState({ isLoading: true });

		await this.context.login(formData.email, formData.password)
			.then((response) =>
			{
				// Error message reset
				this.setState({ errorMessage: null, authModel: { email: formData.email, password: '' } });
				// Navigate by user role
				this.navigateByUserRole();
				this.setState({ isLoading: false });
			})
			.catch((error: any) =>
			{
				// Set error message
				this.setState({ errorMessage: error.message, isLoading: false, authModel: { email: formData.email, password: '' } });
			})
		;
	}

	private navigateByUserRole(): void
	{
		const userLocalStorageData: any = LocalStorageService.getUserLocalStorage();

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnum.ROLE_USER) {
			this.navigation('/auth/client');
		}

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnum.ROLE_SUPER_ADMIN) {
			this.navigation('/auth/company');
		}

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnum.ROLE_ADMIN) {
			this.navigation('/admin');
		}

		if (userLocalStorageData && userLocalStorageData.userRole === UserRoleEnum.ROLE_DEVELOPER) {
			this.navigation('/admin');
		}
	}

	//</editor-fold>
}
