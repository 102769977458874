export function formatDateUtils(dateString: Date) {
  const date = new Date(dateString);
  return date.toLocaleDateString('fr-FR',
    { year: 'numeric', month: 'short', day: 'numeric' }
  );
}

export function dateHeader(): string
{
  const date = new Date();

  return date.toLocaleDateString('fr-FR', {
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  });
}

export function dateToStr(date: Date): string
{
  const dateFormat = new Date(date);
  let month = '' + (dateFormat.getMonth() + 1);
  let day = '' + dateFormat.getDate();
  const year = dateFormat.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function dateFormat(dateParam: string | Date): string
{
  let date;

  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  } else {
    date = dateParam;
  }

  return date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
}

export function dateFormatDatePicker(dateParams: string | null): string
{
  const newDate = dateParams ? new Date(dateParams) : new Date();
  const offset = newDate.getTimezoneOffset();

  const offsetHours = Math.abs(offset / 60);
  const offsetMinutes = Math.abs(offset % 60);
  const timezone = (offset > 0 ? '-' : '+') +
    (offsetHours.toString().padStart(2, '0')) +
    (offsetMinutes.toString().padStart(2, '0'));

  return newDate.toDateString() + ' ' +
    newDate.toLocaleTimeString('fr-FR') + ' GMT' +
    timezone;
}

export function dateFormatFull(dateParam: string | Date): string
{
  let date;

  if (typeof dateParam === 'string') {
    date = new Date(dateParam);
  } else {
    date = dateParam;
  }

  return date.toLocaleDateString('fr-FR', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
}