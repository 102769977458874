import React, { ReactElement } from 'react';
import { isPast, parseISO } from 'date-fns';
import { LegalNoticeInterface } from '@/Modules/LegalNotice/Interface/LegalNoticeInterface';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { Link, NavigateFunction } from 'react-router-dom';
import { LuFileOutput, LuInfo, LuMail, LuEuro } from 'react-icons/lu';
import { ApiAdminLegalNoticeService } from '@/Service/Admin/ApiAdminLegalNoticeService';
import PublishStateEnum from '@/Enum/PublishStateEnum';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { dateFormat } from '@/Utils/DateUtils';
import { stringToCapitalize } from '@/Utils/StringToCapitalizeUtils';
import { BsArrowUpLeft } from 'react-icons/bs';
import LegalNoticeFileTypeEnum from '@/Enum/LegalNoticeFileTypeEnum';
import LegalNoticePriceComponent from '@/Modules/LegalNotice/Components/LegalNoticePriceComponent';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import QuoteStatusEnum from '@/Enum/QuoteStatusEnum';
import { LegalNoticeModalStyle } from '@/Modules/LegalNotice/Style/LegalNoticeModalStyle';
import { Alert } from 'react-bootstrap';
import LegalNoticeRender from '@/Modules/LegalNotice/Components/Render/LegalNoticeRender';
import { ModalContextType } from '@/Provider/ModalProvider';
import BlockSendToComponent from '@/Modules/LegalNotice/Components/Form/Admin/BlockSendToComponent';
import { HttpStatusCodeEnum } from '@/Enum/HttpStatusCode';
import LoaderAwaitSendMailComponent from '@/Modules/App/Components/LoaderAwaitSendMailComponent';
import PaymentStateEnum from '@/Enum/PaymentStateEnum';
import CreatePaymentFormComponent from '@/Modules/Payment/Components/CreatePaymentFormComponent';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';

interface ComponentProps
{
	selectedLegalNotice: LegalNoticeInterface,
	selectedLegalNoticeFiles: any,
	modalContext: ModalContextType,
	flashMessageContext: FlashMessageContextType,
	navigation: NavigateFunction,
	selectedTabOffCanvas: number,
	currentItemId: number | null,
	onDeleted: () => void,
	fromUrl?: string | null
	onDuplicate: () => void,
	onAnswerQuote: () => void,
}

interface ComponentState
{
	sendBackSendTo: {},
	isSendMailLoading: boolean,
	progressBar: number
}

export default class LegalNoticeShowComponent extends React.Component<ComponentProps, ComponentState>
{
	apiLegalNoticeService: ApiAdminLegalNoticeService;
	apiLegalNoticeFileService: ApiAdminLegalNoticeFileService;

	constructor(props: any)
	{
		super(props);

		// Service
		this.apiLegalNoticeService = new ApiAdminLegalNoticeService();
		this.apiLegalNoticeFileService = new ApiAdminLegalNoticeFileService();

		// Bind
		this.sendBackEmailApi = this.sendBackEmailApi.bind(this);

		// State
		this.state = {
			sendBackSendTo: {},
			isSendMailLoading: false,
			progressBar: 0
		};
	}

	render(): ReactElement
	{
		const isPublicationDatePast: boolean = isPast(parseISO(this.props.selectedLegalNotice.publishDate));
		const isDraft: boolean = this.props.selectedLegalNotice.status.toString() === PublishStateEnum.DRAFT.value;
		const isCancelled: boolean = this.props.selectedLegalNotice.status.toString() === PublishStateEnum.CANCEL.value;
		const isQuote: boolean = this.props.selectedLegalNotice.status.toString() === PublishStateEnum.QUOTE.value;
		const isQuotePending: boolean = this.props.selectedLegalNotice.quoteStatus && this.props.selectedLegalNotice.quoteStatus.toString() === QuoteStatusEnum.PENDING.value;
		const isQuoteAnswerRefused: boolean = this.props.selectedLegalNotice.quoteStatus && this.props.selectedLegalNotice.quoteStatus.toString() === QuoteStatusEnum.REFUSED.value;
		const isQuoteAnswerAccepted: boolean = this.props.selectedLegalNotice.quoteStatus && this.props.selectedLegalNotice.quoteStatus.toString() === QuoteStatusEnum.ACCEPTED.value;
		const canEdit: boolean = isDraft || isQuote || (!isCancelled && !isPublicationDatePast);

		return (
			<>
				<div>
					{ (this.props.selectedTabOffCanvas === 1) &&
            <div style={ { display: 'flex', position: 'absolute', top: '20px', right: '30px', } }>
							{ this.backToPreviousUrl() }
            </div>
					}
					{ canEdit &&
            <div style={ { display: 'flex', justifyContent: 'space-between', marginTop: '20px' } }>
              <div style={ { ...CardStyle.cardContainer(), width: '100%' } }>
                <div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
                  <div>
                    Date de publication : &nbsp;
                    <span style={ FontStyle.underline() }>
											{ dateFormat(this.props.selectedLegalNotice.publishDate) }
										</span>
                  </div>
                  <div style={{ display: 'flex' }}>
										{ !isQuote &&
                      <Button
                        style={ { marginRight: '10px' } }
                        type="danger"
                        onClick={ () => this.onDeleted() }
                      >
                        Annuler l'annonce
                      </Button>
										}
										{ (isQuote && isQuotePending) &&
											<>
												<Button
													style={ { marginRight: '10px' } }
													type="default-dark"
													onClick={ () => this.answerQuote() }
												>
													Gérer le devis
												</Button>
											</>
										}

										<div style={{ display: 'flex' }}>
											{ (isQuote && isQuoteAnswerAccepted) &&
												<Alert
													variant={ 'success'}
													style={ LegalNoticeModalStyle.alertContainerStyle(true) }
												>
													<div className="d-flex align-items-center">
														<LuInfo className="me-2" />
														<div style={{ fontWeight: 'bold' }}>
															Devis Accepté, en attente de planification
														</div>
													</div>
												</Alert>
											}

											{ (isQuote && isQuoteAnswerRefused) &&
												<Alert
													variant={ 'danger'}
													style={ LegalNoticeModalStyle.alertContainerStyle(true) }
												>
													<div className="d-flex align-items-center">
														<LuInfo className="me-2" />
														<div style={{ fontWeight: 'bold' }}>
															Devis refusé, annonce légale bloquée.
														</div>
													</div>
												</Alert>
											}

											{(!isQuoteAnswerRefused || isPublicationDatePast) && (
												<Button
													type="default-blue"
													onClick={() => this.props.navigation(`/admin/legal-notice/create?legalNoticeId=${this.props.selectedLegalNotice.id}`)}
												>
													Modifier l'annonce
												</Button>
											)}
										</div>
                  </div>
                </div>
              </div>
            </div>
					}

					{ isPublicationDatePast && !isDraft && !isCancelled && !isQuote && (
						<div style={ { display: 'flex', justifyContent: 'space-between', marginTop: '20px' } }>
							<div style={ { ...CardStyle.cardContainer(), width: '100%' } }>
								<div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
									<div>
										Annonce légale publiée le : &nbsp;
										<span style={ FontStyle.underline() }>
										{ dateFormat(this.props.selectedLegalNotice.publishDate) }
									</span>
									</div>
								</div>
							</div>
						</div>
					) }

					{ isCancelled && (
						<div style={ { display: 'flex', justifyContent: 'space-between', marginTop: '20px' } }>
							<div style={ {
								...CardStyle.cardContainer(CssVariableEnum['--color-error-100']),
								width: '100%',
								border: `1px solid ${ CssVariableEnum['--color-error-200'] }`
							} }>
								<div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
									<div style={ { ...FontStyle.h4(CssVariableEnum['--color-error-500']) } }>
										Annonce légale annulée
									</div>
									<Button
										style={ { alignSelf: 'center', marginBottom: '5px' } }
										type="default-dark"
										onClick={ () => this.onDuplicate() }
									>
										Dupliquer l'annonce
									</Button>
								</div>
							</div>
						</div>
					) }
				</div>

				{ this.props.selectedTabOffCanvas === 1 && this.legalNoticeTabResume() }
				{ this.props.selectedTabOffCanvas === 2 && this.legalNoticeTabText() }
				{ this.props.selectedTabOffCanvas === 3 && this.legalNoticeTabDocument() }
				{ this.props.selectedTabOffCanvas === 4 && this.legalNoticeTabBilling() }

				{ this.state.isSendMailLoading &&
					<LoaderAwaitSendMailComponent progress={ this.state.progressBar }/>
				}
			</>
		);
	}

	//<editor-fold desc="Content (RESUME) methods" defaultstate="collapsed">

	private legalNoticeTabResume(): ReactElement
	{
		const legalNoticeData: any = this.props.selectedLegalNotice;

		return (
			<>
				<div style={ { display: 'flex', flexDirection: 'column', gap: 10, marginTop: '20px' } }>
					<div style={ { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10 } }>
						<div style={ { ...CardStyle.cardContainer() } }>
							<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Type de l'annonce</div>
							{ this.lineDetails('Catégorie principale :', stringToCapitalize(legalNoticeData.formBuilderCategory.parent.label)) }
							{ this.lineDetails('Sous-catégorie :', legalNoticeData.formBuilderCategory.label) }
						</div>
						<div style={ { ...CardStyle.cardContainer() } }>
							<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Configuration de l'annonce</div>
							{ this.lineDetails('Type de journal :', stringToCapitalize(legalNoticeData.option.publishType)) }
							{ this.lineDetails('Département :', legalNoticeData.publishDepartment.name) }
							{ this.lineDetails('Journal :', stringToCapitalize(legalNoticeData.newspaper.name)) }
							{ this.lineDetails('Référence :', legalNoticeData.reference) }
						</div>
					</div>

					<div style={ { ...CardStyle.cardContainer() } }>
						<div style={ { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' } }>
							<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Donneur d'ordre</div>
							<Button
								style={ { marginBottom: '10px', marginLeft: '0' } }
								type="inline-default-blue"
								onClick={ () => this.props.navigation(`/admin/clients?clientId=${ legalNoticeData.client.id }&from=/admin/legal-notice?legalNoticeId=${ legalNoticeData.id }`) }
								disabled={ true }
							>
								Voir la fiche donneur d'ordre
							</Button>
						</div>
						{ this.lineDetails('Dénomination :', stringToCapitalize(legalNoticeData.client.name)) }
						{ this.lineDetails('Numéro :', (legalNoticeData.client?.address.number) ? legalNoticeData.client?.address.number : '') }
						{ this.lineDetails('Rue :', stringToCapitalize(legalNoticeData.client.address.street)) }
						{ this.lineDetails('Code Postale :', legalNoticeData.client.address.zipCode) }
						{ this.lineDetails('Ville :', stringToCapitalize(legalNoticeData.client.address.city)) }
					</div>
					{ legalNoticeData.collaborator &&
            <div style={ { ...CardStyle.cardContainer() } }>
              <div style={ { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' } }>
                <div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Collaborateur</div>
              </div>
							{ this.lineDetails('Email :', stringToCapitalize(legalNoticeData.collaborator.email)) }
							{ this.lineDetails('Nom / Prénom :', stringToCapitalize(legalNoticeData.collaborator.user.lastname) + ' ' + stringToCapitalize(legalNoticeData.collaborator.user.firstname)) }
            </div>
					}

					<div style={ { ...CardStyle.cardContainer() } }>
						<div style={ { ...FontStyle.h4(), marginBottom: 10 } }> Client final</div>
						{ this.lineDetails('Dénomination :', stringToCapitalize(legalNoticeData.consumer?.name)) }
						{ this.lineDetails('SIREN :', legalNoticeData.consumer?.siren) }
						{ this.lineDetails('RCS :', legalNoticeData.consumer?.rcs) }
						{ this.lineDetails('Forme juridique :', legalNoticeData.consumer?.legalStatus) }
						{ this.lineDetails('Capital :', `${ legalNoticeData.consumer?.capital } €`) }
						{ this.lineDetails('Numéro :', (legalNoticeData.consumer?.address.number) ? legalNoticeData.consumer?.address.number : '') }
						{ this.lineDetails('Rue :', stringToCapitalize(legalNoticeData.consumer?.address.street)) }
						{ this.lineDetails('Code Postale :', legalNoticeData.consumer?.address.zipCode) }
						{ this.lineDetails('Ville :', stringToCapitalize(legalNoticeData.consumer?.address.city)) }
					</div>

					<div style={ { ...CardStyle.cardContainer() } }>
						<LegalNoticePriceComponent
							legalNotice={ legalNoticeData }
							selectedDepartment={ legalNoticeData.publishDepartment }
							selectedCategory={ legalNoticeData.formBuilderCategory }
							isTableOffCanvas={ true }
							isAdmin={ true }
						/>
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between'}} >
					<div>
						{ (this.props.selectedLegalNotice.status.value === PublishStateEnum.PLANNED.toString()
								|| this.props.selectedLegalNotice.status.value === PublishStateEnum.DRAFT.toString()) &&
              <Link to={ `/admin/legal-notice/fast/create?legalNoticeId=${ legalNoticeData.id }` }>
                <Button
                  style={ { marginTop: '10px', marginLeft: '0' } }
                  type="success"
                >
                  Editer l'annonce légale
                </Button>
              </Link>
						}
					</div>
				</div>
			</>
		);
	}

	private lineDetails(label: string, data: string | number | null, splitColumn: boolean = true): ReactElement
	{
		return (
			<>
				<div style={ { display: 'grid', gridTemplateColumns: '150px auto', gap: 10 } }>
					<div style={ {
						width: '100%',
						...FontStyle.littleGrey()
					} }>
						{ label }
					</div>
					<div style={ {
						width: '100%',
						...FontStyle.normalMedium()
					} }>
						{ data }
					</div>
				</div>
			</>
		);
	}

	private backToPreviousUrl(): ReactElement | null
	{
		if (this.props.fromUrl) {
			return (
				<Button
					type={ 'default' }
					iconLeft={ <BsArrowUpLeft/> }
					onClick={ (): void | null => (this.props.fromUrl) ? this.props.navigation(this.props.fromUrl.toString()) : null }
				>
					Retour
				</Button>
			);
		} else
			return null;
	}

	//</editor-fold>

	//<editor-fold desc="Content (TEXT) methods" defaultstate="collapsed">

	private legalNoticeTabText(): ReactElement
	{
		return (
			<>
				<div style={ { marginTop: 20 } }>
					<LegalNoticeRender
						legalNotice={ this.props.selectedLegalNotice as any }
						consumer={ this.props.selectedLegalNotice.consumer }
						isShow={ true }
					/>
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Content (DOCUMENT) methods" defaultstate="collapsed">

	private legalNoticeTabDocument(): ReactElement
	{
		const quotes = this.props.selectedLegalNoticeFiles.filter((file: any) => file.type === 'QUOTE');
		const billings = this.props.selectedLegalNoticeFiles.filter((file: any) => file.type === 'INVOICE');
		const creditNotes = this.props.selectedLegalNoticeFiles.filter((file: any) => file.type === 'CREDIT_NOTE');
		const isPlanned = this.props.selectedLegalNotice.status.toString() === PublishStateEnum.PLANNED.value;
		const isPublish = this.props.selectedLegalNotice.status.toString() === PublishStateEnum.PUBLISH.value;
		const isPaid = this.props.selectedLegalNotice.paymentStatus.toString() === PaymentStateEnum.PAID.value;

		return (
			<>
				<div style={ {
					display: 'flex',
					justifyContent: (isPlanned) ? 'space-between' : 'flex-end',
					marginTop: 20,
					marginBottom: 10
				} }>
					<Button
						iconLeft={ <LuMail/> } type={ 'default-dark' }
						onClick={ () => this.sendBackEMail({ label: 'Tous les documents', value: 'all' }) }
					>
						Renvoyer l'ensemble des document(s)
					</Button>
				</div>

				{ this.legalNoticeTabFiles() }
				<div style={ { ...CardStyle.cardContainer(), marginTop: 10 } }>
					<div style={ { display: 'flex', justifyContent: 'space-between' } }>
						<div style={ { ...FontStyle.h4(), marginTop: 0 } }> Devis</div>
						{ quotes.length > 0 &&
							<Button
								iconLeft={ <LuMail/> }
								onClick={ () => this.sendBackEMail({ label: 'Devis', value: 'estimate' }) }
							>
								Renvoyer le Devis
							</Button>
						}
					</div>
					{ quotes.length > 0 ? (
						quotes.map((file: any, index: number) => (
							<div style={ { ...CardStyle.cardContainer(), marginTop: 20, width: '33%', gap: 10 } } key={ file.id }>
								<div
									style={ { width: '100%', marginBottom: '10px' } }
									key={ index }>
									<Button type={ 'tag-blue' } onClick={ () =>
									{
										this.getFile(file);
									} }>
										<LuFileOutput/>
									</Button>
									<div style={ { ...FontStyle.normalMedium(), marginTop: 10, marginBottom: 10 } }>
										{ LegalNoticeFileTypeEnum.findByValue(file.type)?.label }
									</div>
									<div style={ FontStyle.littleGrey() }>Nom:&nbsp;<b>{ file.name }</b></div>
									<div style={ FontStyle.littleGrey() }>Créé le:&nbsp;{ dateFormat(file.createdAt) }</div>
									<div style={ FontStyle.littleGrey() }>Modifié le:&nbsp;{ dateFormat(file.updatedAt) }</div>
								</div>
							</div>
						))
					) : (
						<div style={ { marginTop: '8px' } }>
							Aucun devis disponible
						</div>
					) }
				</div>
				<div style={ { ...CardStyle.cardContainer(), marginTop: 10 } }>
					<div style={ { display: 'flex', justifyContent: 'space-between' } }>
						<div style={ { ...FontStyle.h4(), marginTop: 0 } }>Factures</div>
						{ billings.length > 0&&
							<>
								<div>
									{ (isPlanned||isPublish)&&
										<>
											<Button
												iconLeft={ <LuEuro/> }
												type={ 'success' }
												onClick={ () =>
												{
													this.onClickPaymentInvoice(billings[0])
												} }
												disabled={ Boolean(isPaid) }
											>
												{ isPaid ? 'Facture payée' : 'Régler la facture' }
											</Button>
										</>
									}

									<Button
										iconLeft={ <LuMail/> }
										onClick={ () => this.sendBackEMail({ label: 'Facture', value: 'invoice' }) }
									>
										Renvoyer la Facture
									</Button>
								</div>
							</>
						}

					</div>
					{ billings.length > 0 ? (
						billings.map((file: any, index: number) => (
							<div style={ { ...CardStyle.cardContainer(), marginTop: 10, width: '33%', gap: 10 } } key={ file.id }>
								<div
									style={ { width: '100%', marginBottom: '10px' } }
									key={ index }>
									<Button type={ 'tag-blue' } onClick={ () =>
									{
										this.getFile(file);
									} }>
										<LuFileOutput/>
									</Button>
									<div style={ { ...FontStyle.normalMedium(), marginTop: 30, marginBottom: 10 } }>
										{ LegalNoticeFileTypeEnum.findByValue(file.type)?.label }
									</div>
									<div style={ FontStyle.littleGrey() }>Nom:&nbsp;<b>{ file.name }</b></div>
									<div style={ FontStyle.littleGrey() }> Créé le:&nbsp;{ dateFormat(file.createdAt) }</div>
									<div style={ FontStyle.littleGrey() }>Modifié le:&nbsp;{ dateFormat(file.updatedAt) }</div>
								</div>
							</div>
						))
					) : (
						<div style={ { marginTop: '8px' } }>
							Aucune facture disponible
						</div>
					) }
				</div>


				<div style={ { ...CardStyle.cardContainer(), marginTop: 10 } }>
					<div style={ { display: 'flex', justifyContent: 'space-between' } }>
						<div style={ { ...FontStyle.h4(), marginTop: 0 } }> Avoirs</div>
						{ creditNotes.length > 0&&
							<>
								<div>
									<Button
										iconLeft={ <LuMail/> }
										onClick={ () => this.sendBackEMail({ label: 'Avoir', value: 'credit' }) }
									>
										Renvoyer l'avoir
									</Button>
								</div>
							</>
						}

					</div>
					{ creditNotes.length > 0 ? (
						creditNotes.map((file: any, index: number) => (
							<div style={ { ...CardStyle.cardContainer(), marginTop: 10, width: '33%', gap: 10 } } key={ file.id }>
								<div
									style={ { width: '100%', marginBottom: '10px' } }
									key={ index }>
									<Button type={ 'tag-blue' } onClick={ () =>
									{
										this.getFile(file);
									} }>
										<LuFileOutput/>
									</Button>
									<div style={ { ...FontStyle.normalMedium(), marginTop: 30, marginBottom: 10 } }>
										{ LegalNoticeFileTypeEnum.findByValue(file.type)?.label }
									</div>
									<div style={ FontStyle.littleGrey() }>Nom:&nbsp;<b>{ file.name }</b></div>
									<div style={ FontStyle.littleGrey() }> Créé le:&nbsp;{ dateFormat(file.createdAt) }</div>
									<div style={ FontStyle.littleGrey() }>Modifié le:&nbsp;{ dateFormat(file.updatedAt) }</div>
								</div>
							</div>
						))
					) : (
						<div style={ { marginTop: '8px' } }>
							Aucun avoir disponible
						</div>
					) }
				</div>

			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Billing methods" defaultstate="collapsed">

	private legalNoticeTabBilling(): ReactElement
	{
		return (
			<>
				<div style={ {
					width: '100%',
					backgroundColor: CssVariableEnum['--color-white'],
					borderRadius: 15,
					border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
					padding: 20,
					justifyContent: 'space-between',
					marginTop: 30
				} }>
					<div style={ { ...FontStyle.h4(), marginBottom: 15 } }> Envoie des Justificatifs</div>
					{ this.lineDetailsSendTo('Facture : ', this.props.selectedLegalNotice.sendTo.invoice) }
					{ this.lineDetailsSendTo('Attestation : ', this.props.selectedLegalNotice.sendTo.certificate) }
					{ this.lineDetailsSendTo('Justificatif : ', this.props.selectedLegalNotice.sendTo.receipt) }
					{ this.lineDetailsSendTo('Avoir : ', this.props.selectedLegalNotice.sendTo.credit) }
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Content (FILES) methods" defaultstate="collapsed">

	private legalNoticeTabFiles(): ReactElement
	{
		const filteredFiles = this.props.selectedLegalNotice.legalNoticeFiles
			.filter((file: any) => file.type !== 'QUOTE' && file.type !== 'INVOICE' && file.type !== 'CREDIT_NOTE')
		;

		return (
			<>
				<div style={ { ...CardStyle.cardContainer() } }>
					<div style={ { display: 'flex', justifyContent: 'space-between' } }>
						<div style={ { ...FontStyle.h4(), marginTop: 0 } }> Justificatifs (Document PDF)</div>
						{ filteredFiles.length > 0&&
							<Button iconLeft={ <LuMail/> }
											onClick={ () => this.sendBackEMail({ label: 'Les certificats', value: 'certificate' }) }>
								Renvoyer les justificatifs
							</Button>
						}
					</div>
					{ filteredFiles.map((file: any, index: number) => (
						<div style={ { ...CardStyle.cardContainer(), marginTop: 20, width: '33%', gap: 10 } } key={ index }>
							<div style={ { width: '100%', marginBottom: '10px' } }>
									<Button
										type={ 'tag-blue' }
										iconLeft={ <LuFileOutput/> }
										onClick={ () =>
									{
										this.getFile(file);
									} }>
									</Button>
									<div style={ { ...FontStyle.normalMedium(), marginTop: 30, marginBottom: 10 } }>
										{ LegalNoticeFileTypeEnum.findByValue(file.type)?.label }
									</div>
									<div style={ FontStyle.littleGrey() }> Créé le:&nbsp;{ dateFormat(file.createdAt) }</div>
									<div style={ FontStyle.littleGrey() }>Modifié le:&nbsp;{ dateFormat(file.updatedAt) }</div>
								</div>
							</div>
						)) }
				</div>
			</>
		);
	}

	private async getFile(file: any): Promise<void>
	{
		if (file.extSellsyId) {
			window.open(file.file, '_blank');
		} else {
			await this.apiLegalNoticeFileService.viewFilePDF(this.props.selectedLegalNotice.id, file.type)
				.then(blob =>
				{
					const url = window.URL.createObjectURL(blob);
					window.open(url, '_blank');
				})
				.catch(error => console.error('Error downloading file:', error))
			;
		}
	}

	//</editor-fold>

	//<editor-fold desc="Sending Back Emails" defaulstate="collapsed">

	private sendBackEMail(data: { label: string, value: string }): void
	{
		const fieldsMap: { [key: string]: string[] } = {
			all: ['invoice', 'certificate', 'receipt', 'credit'],
			estimate: ['invoice', 'quote'],
			certificate: ['certificate'],
			invoice: ['invoice'],
			credit: ['credit']
		};

		const fieldsToShow = fieldsMap[data.value] || ['estimate', 'invoice', 'certificate', 'receipt', 'credit'];

		this.props.modalContext.content(
			`Renvoie des documents (${data.label})`,
			<>
				<div style={ { padding: '0 15px', marginBottom: 10, width: 800 } }>
					<BlockSendToComponent
						isDisplayBlock={ true }
						selectedClient={ this.props.selectedLegalNotice.client }
						sendTo={ this.props.selectedLegalNotice.sendTo }
						onSelectionMail={ this.onSelectionMail.bind(this) }
						fieldsToShow={ fieldsToShow }
					/>
				</div>

				<div style={ { display: 'flex', justifyContent: (this.state.isSendMailLoading) ? 'space-between' : 'end', marginTop: 20, marginBottom: 10, marginRight: 15 } }>
					<Button
						type={ 'success' }
						iconLeft={ <LuMail/> }
						onClick={ () => this.sendBackEmailApi(data) }
						disabled={ this.state.isSendMailLoading }
					>
						Renvoyer les mails
					</Button>
				</div>
			</>
		);
	}

	private async sendBackEmailApi(data: { label: string, value: string }): Promise<any>
	{
		this.setState({ isSendMailLoading: true }, async () => {
			let progressProcess: number = 0;
			const progressInterval = setInterval(() => {
				if (progressProcess < 95) {
					progressProcess += 5;
					this.setState({ progressBar: progressProcess });
				}
			}, 1000);

			try {
				this.props.modalContext.isOpen(false);
				const sendBackEmailCallApi = await this.apiLegalNoticeService.sendBackEmail(
					this.props.selectedLegalNotice.id,
					{
						type: data.value,
						sendTo: this.state.sendBackSendTo
					}
				);

				clearInterval(progressInterval);
				this.setState({ progressBar: 100 });

				if (sendBackEmailCallApi.statusCode === HttpStatusCodeEnum.HTTP_OK) {
					this.props.flashMessageContext.flashMessage(sendBackEmailCallApi.message);
				}
			} catch (error) {
				console.error('Error sending email:', error);
			} finally {
				this.setState({ isSendMailLoading: false });
			}
		});
	}

	private onSelectionMail(sendToData: any): void
	{
		this.setState({ sendBackSendTo: sendToData });
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	componentDidMount(): void
	{
		this.setState({
			sendBackSendTo: this.props.selectedLegalNotice.sendTo
		});
	}

	private onDeleted(): void
	{
		this.props.onDeleted();
	}

	private onDuplicate(): void
	{
		this.props.onDuplicate();
	}

	private answerQuote(): void
	{
		this.props.onAnswerQuote();
	}

	private lineDetailsSendTo(label: string, emails: string[] | null): ReactElement
	{
		return (
			<React.Fragment key={ label }>
				<div style={ { display: 'grid', gridTemplateColumns: '90px auto', gap: 5} }>
					<div style={ { fontSize: 12, fontWeight: 500, color: CssVariableEnum['--color-grey-400'], minWidth: 90 } }>
						{ label }
					</div>
					<div style={ {
						fontSize: 12,
						fontWeight: 500,
						color: CssVariableEnum['--color-grey-900'],
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
						gap: 5
					} }>
						{ emails && emails.map((email: string, index: number) =>
							{
								return (
									<span
										style={ {
											padding: '2px 7px',
											background: CssVariableEnum['--color-blue-light-200'],
											color: CssVariableEnum['--color-blue-light-800'],
											borderRadius: '4px',
											height: '22px'
										} }
										key={ index }
									>
										{ email }
									</span>
								);
							}
						) }
					</div>
				</div>
			</React.Fragment>
		);
	}

	private onClickPaymentInvoice(billing: any): void
	{
		this.props.modalContext.content(
			'Régler la facture : ' + billing.name,
			<CreatePaymentFormComponent
				legalNotice={ this.props.selectedLegalNotice }
				onCreate={ this.onCreatePayment }
				billing={ billing }
			/>
		);
	}

	private onCreatePayment(responsePayment: any): void
	{
		this.props.modalContext.isOpen(false);
		this.props.flashMessageContext.flashMessage(responsePayment);
	}

	//</editor-fold>
}