import React from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';

export default class FormBuilderStyle
{
	static view(): { [key: string]: React.CSSProperties }
	{
		return {
			actionButtonHead: { width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: 25 },
			sectionContainerList: {
				maxHeight: 'calc(100vh - 200px)',
				overflowY: 'scroll',
				display: 'flex',
				flexDirection: 'column',
				gap: 10
			},
		};
	}

	static section(): { [key: string]: React.CSSProperties }
	{
		return {
			container: { backgroundColor: CssVariableEnum['--color-white'], padding: '5px 15px', borderRadius: 8 },
			cardGrid: { display: 'grid', gridTemplateColumns: 'auto 100px 150px', alignItems: 'center' },
			cardTitle: { ...FontStyle.h4() },
			cardButtonAction: { display: 'flex', width: '100%', justifyContent: 'flex-end' },
			sectionModalContainer: { width: 1000, padding: 20, maxHeight: 650 },
			sectionCardContainer: {
				width: '100%',
				backgroundColor: 'rgba(228, 231, 236, 0.8)',
				borderRadius: '15px',
				padding: 5,
				marginBottom: 10
			},
			sectionCardFooter: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-end',
				marginTop: 10,
			},
			sectionTitle: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: 10 },
			sectionInputsContainer: { backgroundColor: CssVariableEnum['--color-white'], borderRadius: 15, padding: 10 },
			inputsGrid: { padding: 10, display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end' },
			sectionModalFooter: { width: '100%', display: 'flex', justifyContent: 'flex-end' },
		};
	}

	static input(): { [key: string]: React.CSSProperties }
	{
		return {
			modalContainer: { padding: 25 },
			formGrid: { display: 'grid', gridTemplateColumns: '350px auto' },
			formContainer: { display: 'flex', flexDirection: 'column', gap: 15 },
			cmsContentFooterAction: { width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 10 },
			modalFooter: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}
		};
	}
}