import React, { ReactElement } from 'react';
import '../../../Assets/sass/Components/_dashboard-developer.scss';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { LuSearch } from 'react-icons/lu';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ApiAdminLegalNoticeFileService } from '@/Service/Admin/ApiAdminLegalNoticeFileService';
import { ApiAppService } from '@/Service/Api/ApiAppService';

export default class DashboardDeveloperView extends React.Component
  <any, { responseCallPayment: string|null }>
{
  apiLegalNoticeFileService: ApiAdminLegalNoticeFileService;
  apiAppService: ApiAppService;

  constructor(props: any)
  {
    super(props);

    this.state = {
      responseCallPayment: null
    };

    // Service
    this.apiLegalNoticeFileService = new ApiAdminLegalNoticeFileService();
    this.apiAppService = new ApiAppService();

    // Document title
    document.title = 'Publisur - DEVELOPER';
  }

  render(): ReactElement
  {
    return (
      <>
        <div className="dashboard-container">
          <h1 className="h1">
            <div className="pacman">
              <div className="pacman-body">
                <div className="pacman-mouth"></div>
              </div>
            </div>
            <div className="dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            👾 WELCOME DEVELOPER 😍
          </h1>
        </div>

        <hr/>

        <div style={{ marginLeft: '10px' }}>
          <Title type={ 'h6' }>Test FTP</Title>
          <Button
            style={{ marginLeft: '0' }}
            type={'default-blue'}
            iconLeft={<LuSearch />}
            onClick={ () => this.testCreateCertificate() }
          >
            Test création certificate
          </Button>
        </div>
      </>
    );
  }

  private async testCreateCertificate(): Promise<void>
  {
    try {
      const legalNoticeList = await this.apiAppService.legalNoticeList();
      await this.apiLegalNoticeFileService.createCertificateFile(legalNoticeList[0].id, legalNoticeList[0]).catch(console.error);
    } catch (error) {
      console.error('Error during payment test:', error);
      // Handle network errors here
    }
  }
}
