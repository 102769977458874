import React, { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

interface LoaderFullPageComponentProps {
	timeout?: number;
}

interface LoaderFullPageComponentState {
	showLoader: boolean;
}

export default class LoaderFullPageComponent extends React.Component<LoaderFullPageComponentProps, LoaderFullPageComponentState> {
	constructor(props: LoaderFullPageComponentProps) {
		super(props);
		this.state = {
			showLoader: true
		};
	}

	componentDidMount(): void {
		// This example does not use timeout logic,
		// as requested, but you can add your data loading logic here.
	}

	render(): ReactElement {
		return (
			<>
				{this.state.showLoader && (
					<div className="loader">
						<div className="loader__container">
							<Spinner animation="border" variant="dark" />
						</div>
					</div>
				)}
			</>
		);
	}
}
