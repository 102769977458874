import React, { ReactElement } from 'react';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { AuthCompanyProviderState } from '@/Provider/Interface/AuthCompany/AuthCompanyProviderState';
import { ApiAdminCompanyService } from '@/Service/Admin/ApiAdminCompanyService';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';

export const AuthCompanyContext = React.createContext<AuthCompanyContextType|null>(null);

export class AuthCompanyProvider extends React.Component<any, AuthCompanyProviderState>
{
  companyService: ApiAdminCompanyService;
  userService: ApiAdminUserService;

  constructor(props: any)
  {
    super(props);

    this.state = {
      companies: [],
      authCompany: null,
      isCompanyFetch: false
    };

    // Services
    this.companyService = new ApiAdminCompanyService();
    this.userService = new ApiAdminUserService();

    // Bind
    this.fetchCompanies = this.fetchCompanies.bind(this);
    this.refreshCompany = this.refreshCompany.bind(this);
  }

  async componentDidMount() {
    await this.loadInitialData();
  }

  async loadInitialData(): Promise<any>
  {
    const storedCompany = LocalStorageService.getAuthCompanyLocalStorage();
    if (storedCompany && !this.state.isCompanyFetch) {
      try {
        const getAuthCompany = await this.companyService.show(storedCompany);
        this.setState({ authCompany: getAuthCompany, isCompanyFetch: true });
        await this.fetchCompanies();
      } catch (error) {
        console.error('[AuthCompanyProvider] - Error on load initial data:', error);
      }
    }
  }

  async fetchCompanies(): Promise<any>
  {
    try {
      const response = await this.companyService.list();
      this.setState({ companies: response });
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  async refreshCompany(companyId: number): Promise<any>
  {
    try {
      const getAuthCompany = await this.companyService.show(companyId);
      // Switch company for USER
      await this.userService.switchCompany(LocalStorageService.getUserLocalStorage().userId, getAuthCompany.id);

      this.setState({ authCompany: getAuthCompany });
      // Set local storage auth company id
      localStorage.setItem('authCompanyId', companyId.toString());
    } catch (error) {
      console.error('Error selecting company:', error);
    }
  }

  render(): ReactElement
  {
    return (
      <AuthCompanyContext.Provider value={{
        authCompany: this.state.authCompany,
        refreshCompany: this.refreshCompany,
        companies: this.state.companies,
        storedCompany: this.loadInitialData,
        fetchCompanies: this.fetchCompanies
      }}>
        { this.props.children }
      </AuthCompanyContext.Provider>
    );
  }
}