import React, { createRef, ReactElement } from 'react';
import { BsChevronExpand } from 'react-icons/bs';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { NavigateFunction } from 'react-router-dom';
import { ApiAdminCompanyService } from '@/Service/Admin/ApiAdminCompanyService';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { CompanyInterface } from '@/Modules/Company/Interface/CompanyInterface';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import HeaderSwitchCompanyStyle from '@/Modules/App/Style/HeaderSwitchCompanyStyle';
import TransitionStyle from '@/Modules/App/Style/Base/TransitionStyle';
import CardStyle from '@/Modules/App/Style/Components/CardStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { MenuAdminStyle } from '@/Modules/App/Style/Menus/MenuAdminStyle';

interface ComponentProps
{
	flashMessageContext: FlashMessageContextType,
	companyContext: AuthCompanyContextType,
	authContext: AuthContextType,
	navigation: NavigateFunction
	isThemeDark: boolean
}

interface ComponentState
{
	isOpen: boolean,
	isLoading: boolean,
	errorMessage: string | null,
	hoveredCompanyId: number | null
}

export default class HeaderSwitchCompany extends React.Component<ComponentProps, ComponentState>
{
	companyService: ApiAdminCompanyService;

	private menuCompanyRef = createRef<HTMLDivElement>();

	constructor(props: any)
	{
		super(props);

		this.state = this.initState();

		// Services
		this.companyService = new ApiAdminCompanyService();
	}

	render(): ReactElement
	{
		if (!this.props.companyContext) {
			return <LoaderComponent/>;
		}

		return (
			<>
				<div ref={ this.menuCompanyRef } style={ HeaderSwitchCompanyStyle.container() }>
					<div style={ HeaderSwitchCompanyStyle.switch(this.props.isThemeDark) } onClick={ this.handleOpenClientMenu.bind(this) }>
						{ this.getCurrentCompanyName() }
						<BsChevronExpand style={ { marginLeft: '15px' } } className="icon bi-chevron-expand"/>
					</div>
					{ this.selectCompanyContent(this.state.isOpen) }
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		if (this.props.companyContext.fetchCompanies) {
			await this.props.companyContext.fetchCompanies();
		}
		document.addEventListener('mousedown', this.handleCloseClientMenu);
	}

	componentWillUnmount(): void
	{
		document.removeEventListener('mousedown', this.handleCloseClientMenu);
	}

	private initState(): ComponentState
	{
		return {
			isOpen: false,
			isLoading: true,
			errorMessage: null,
			hoveredCompanyId: null
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private selectCompanyContent(isOpen: boolean): ReactElement
	{
		// Filter company
		const filteredCompanies = this.props.companyContext.companies.filter(
			(company: CompanyInterface) => company.id !== (this.props.companyContext.authCompany && this.props.companyContext.authCompany.id)
		);

		return (
			<div style={ { ...TransitionStyle.transitionAbsolute(isOpen), top: 40, left: '260px' } }>
				{ isOpen &&
          <div style={ CardStyle.main('', true) }>
            <div style={ CardStyle.container() }>
              <div style={ { display: 'flex', gap: 10, alignItems: 'center' } }>
                <div style={ HeaderSwitchCompanyStyle.cardOpenImage() }>
                  <div style={ { fontSize: 35 } }>🧑‍💻</div>
                </div>
                <div style={ { display: 'flex', flexDirection: 'column', height: '100%' } }>
                  <span style={ FontStyle.littleGrey() }>Entreprise active</span>
                  <div style={ FontStyle.normalMedium() }>
										{ this.props.companyContext.authCompany && this.props.companyContext.authCompany.name }
									</div>
                </div>
              </div>
            </div>
						{ filteredCompanies.length > 0 && <div style={ HeaderSwitchCompanyStyle.cardOpenMenuCompany() }>
              <div>
								{ filteredCompanies.map((company: CompanyInterface) => (
									<li
										style={ MenuAdminStyle.menuItem('3px 9px', this.state.hoveredCompanyId, company.id ) }
										key={ company.id }
										value={ company.id }
										data-company-name={ company.name }
										onClick={ this.onSwitchCompany.bind(this)}
										onMouseEnter={ () => this.setState({ hoveredCompanyId: company.id }) }
										onMouseLeave={ () => this.setState({ hoveredCompanyId: null }) }
									>
										<div>{ company.name }</div>
										<span style={ { marginLeft: '5px', color: '#5D5C588D' } }>({ company.city })</span>
									</li>
								)) }
              </div>
            </div> }
          </div>
				}
			</div>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Open / Close menu methods" defaultstate="collapsed">

	private handleOpenClientMenu()
	{
		this.setState({ isOpen: true });
	}

	handleCloseClientMenu = (event: MouseEvent): void => {
		if (this.menuCompanyRef.current && !this.menuCompanyRef.current.contains(event.target as Node)) {
			setTimeout(() => {
				this.setState({ isOpen: false });
			}, 300);
		}
	};
	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private getCurrentCompanyName(): string
	{
		const { companyContext } = this.props;
		return companyContext?.authCompany?.name ?? 'No Company Selected';
	}

	private onSwitchCompany(event: React.MouseEvent<HTMLLIElement>): void
	{
		const target = event.currentTarget as HTMLLIElement;
		const companyId = target.getAttribute('value');
		const companyName = target.dataset.companyName;
		// Display Loader
		this.setState({ isOpen: false });

		if (companyId) {
			this.switchCompany(parseInt(companyId), companyName as string);
		}
	}

	private async switchCompany(companyId: number, companyName: string): Promise<void>
	{
		try {
			await this.props.companyContext.refreshCompany(companyId);
			// Set Flash message
			this.props.flashMessageContext.flashMessage(
				'Changement d\'entreprise réussi',
				`Vous êtes maintenant connecté sur ${ companyName }`,
				'success'
			);
			// Navigate to /admin
			this.props.navigation('/admin');
		} catch (error) {
			console.error('Error switching company:', error);
			this.props.flashMessageContext.flashMessage(
				'Erreur lors du changement d\'entreprise',
				'Une erreur est survenue lors du changement d\'entreprise. Veuillez réessayer.',
				'error'
			);
		}
	}

	//</editor-fold>
}
