import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import FormLabel from '@/Modules/App/Components/Atom/Form/FormLabel';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export default class Textarea extends React.Component
  <{
    label?: string;
    value?: string;
    name: string;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
    isLoading?: boolean;
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    rows?: number;
    cols?: number;
    width?: string;
    backgroundColor?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    required?: boolean;
  }, any>
{

  render(): ReactElement {
    return (
      this.props.isLoading
        ? <div style={{ width: this.props.width || '100%', paddingLeft: '10px' }}>
          <Skeleton height={14} width="100%" />
        </div>
        : <>
          <div style={{ width: '100%' }}>
            {this.props.label && <FormLabel content={this.props.label} isRequired={this.props.required} />}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: (this.props.width) ? this.props.width : '100%',
              alignItems: 'center',
              fontSize: CssVariableEnum['--font-size-main'],
              lineHeight: '20px',
              position: 'relative',
              borderRadius: CssVariableEnum['--border-radius-main'],
              boxShadow: CssVariableEnum['--input-box-shadow'],
              backgroundColor: this.props.disabled ? CssVariableEnum['--color-grey-200'] :
                (this.props.backgroundColor || CssVariableEnum['--color-grey-25']),
              cursor: 'text',
              padding: CssVariableEnum['--input-padding'],
              height: 'auto',
              minHeight: CssVariableEnum['--input-height'],
              ...this.props.style
            }}>
              <textarea
                value={this.props.value}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                onKeyDown={this.props.onKeyDown}
                style={{
                  opacity: this.props.disabled ? 0.4 : 1,
                  fontSize: 'inherit',
                  lineHeight: 'inherit',
                  border: 'none',
                  background: 'none',
                  width: '100%',
                  padding: 0,
                  outline: 0,
                  resize: 'none',
                }}
                name={this.props.name}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                rows={this.props.rows || 3}
                cols={this.props.cols}
              />
            </div>
          </div>
        </>
    );
  }
}