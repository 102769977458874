import React from 'react';
import { BsBarChart } from 'react-icons/bs';

export default class DashboardView extends React.Component
{
	render()
	{
		return (
			<>
				<h1 className="h1">
					<BsBarChart className="icon icon-people icon--orange"/>
					Dashboard
				</h1>
			</>
		)
	}
}
