import React, { ReactElement } from 'react';
import { Router as RemixRouter } from '@remix-run/router/dist/router';
import NotFoundView from '@/Modules/App/View/NotFoundView';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import DashboardView from '@/Modules/Dashboard/View/DashboardView';
import AuthViewWrapper from '@/Modules/Auth/Wrapper/AuthViewWrapper';
import AuthClientViewWrapper from '@/Modules/AuthClient/Wrapper/AuthClientViewWrapper';
import { checkProtectedRoute } from '@/Loader/CheckProtectedRoute';
import { FlashMessageProvider } from '@/Provider/FlashMessageProvider';
import CollaboratorViewWrapper from '@/Modules/Collaborator/Wrapper/CollaboratorViewWrapper';
import LayoutWrapper from '@/Modules/App/Wrapper/LayoutWrapper';
import UserAdminViewWrapper from '@/Modules/User/Admin/Wrapper/UserAdminViewWrapper';
import ClientAdminViewWrapper from '@/Modules/Client/Admin/Wrapper/ClientAdminViewWrapper';
import RespondInvitationViewWrapper from '@/Modules/Collaborator/Wrapper/RespondInvitationViewWrapper';
import ForgotPasswordViewWrapper from '@/Modules/App/Wrapper/ForgotPasswordViewWrapper';
import '@/Assets/sass/main.scss';
import PricingAdminViewWrapper from '@/Modules/Pricing/Admin/Wrapper/PricingAdminViewWrapper';
import LegalNoticeAdminViewWrapper from '@/Modules/LegalNotice/Admin/Wrapper/LegalNoticeViewWrapper';
import ModalProvider from '@/Provider/ModalProvider';
import DashboardAdminViewWrapper from '@/Modules/Dashboard/Admin/Wrapper/DashboardAdminViewWrapper';
import AskForgotPasswordViewWrapper from '@/Modules/App/Wrapper/AskForgotPasswordViewWrapper';
import FormBuilderAdminViewWrapper from '@/Modules/FormBuilder/Admin/Wrapper/FormBuilderAdminViewWrapper';
import AuthCompanyViewWrapper from '@/Modules/AuthCompany/Wrapper/AuthCompanyViewWrapper';
import DashboardDeveloperView from '@/Modules/Dashboard/Developer/DashboardDeveloperView';
import ParticularLegalNoticeViewWrapper
  from '@/Modules/LegalNotice/Particular/Wrapper/ParticularLegalNoticeViewWrapper';
import PaymentCallbackWrapper from '@/Modules/App/Wrapper/PaymentCallbackWrapper';
import CreateLegalNoticeViewWrapper from '@/Modules/LegalNotice/Admin/Wrapper/CreateLegalNoticeViewWrapper';
import PaymentViewWrapper from '@/Modules/Payment/Wrapper/PaymentViewWrapper';
import LegalNoticeViewWrapper from '@/Modules/LegalNotice/Wrapper/LegalNoticeViewWrapper';
import CreateLegalNoticeGuideViewWrapper from '@/Modules/LegalNotice/Wrapper/CreateLegalNoticeGuideViewWrapper';
import LegalNoticeAdminValidateWrapper from '@/Modules/LegalNotice/Admin/Wrapper/LegalNoticeAdminValidateWrapper';
import LegalNoticeAdminPaymentWrapper from '@/Modules/LegalNotice/Admin/Wrapper/LegalNoticeAdminPaymentWrapper';

export class App
{
  router(): RemixRouter
  {
    return createBrowserRouter([
      {
        path: '/auth',
        element: this.layoutWrapperOutlet('auth'),
        children: [
          {
            index: true,
            element: <AuthViewWrapper/>
          },
          {
            path: 'client',
            element: <AuthClientViewWrapper/>,
            loader: () => checkProtectedRoute()
          },
          {
            path: 'company',
            element: <AuthCompanyViewWrapper/>,
            loader: () => checkProtectedRoute()
          }
        ]
      },
      {
        path: '/',
        element: this.layoutWrapperOutlet('default'),
        handle: {
          crumb: () => ({ title: "Accueil", path: "/" })
        },
        children: [
          {
            index: true,
            element: <DashboardView/>,
            handle: {
              crumb: () => ({ title: "Tableaux de bord", path: "/" })
            },
            loader: () => checkProtectedRoute()
          },
          {
            path: 'collaborators',
            element: <CollaboratorViewWrapper/>,
            handle: {
              crumb: () => ({ title: "Collaborateurs", path: "/collaborators" })
            },
            loader: () => checkProtectedRoute()
          },
          {
            path: 'legal-notices',
            element: <LegalNoticeViewWrapper/>,
            handle: {
              crumb: () => ({ title: "Annonces légales", path: "/legal-notices" })
            },
            loader: () => checkProtectedRoute()
          },
          {
            path: '/legal-notices/create',
            element: <CreateLegalNoticeGuideViewWrapper/>,
            handle: {
              crumb: () => ({ title: "Créer une annonce légale", path: "/legal-notices/create" })
            },
            loader: () => checkProtectedRoute()
          },
        ]
      },
      {
        path: '/developer',
        element: this.layoutWrapperOutlet('developer'),
        handle: {
          crumb: () => ({ title: "Accueil", path: "/developer" })
        },
        children: [
          {
            index: true,
            element: <DashboardDeveloperView/>,
            handle: {
              crumb: () => ({ title: "Tableaux de bord", path: "/" })
            },
            loader: () => checkProtectedRoute(false, false, false, true)
          },
          {
            path: '/developer/users',
            element: <UserAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Utilisateurs", path: "/developer/users", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, false, false, true)
          },
          {
            path: '/developer/clients',
            element: <ClientAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Donneur d'ordre", path: "/developer/clients", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, false, false, true)
          }
        ]
      },
      {
        path: '/admin',
        element: this.layoutWrapperOutlet('admin'),
        handle: {
          crumb: () => ({ title: "Accueil", path: "/admin" })
        },
        children: [
          {
            index: true,
            element: <DashboardAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Tableaux de bord", path: "/", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/users',
            element: <UserAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Utilisateurs", path: "/admin/users", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/clients',
            element: <ClientAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Donneur d'ordre", path: "/admin/clients", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/settings/form-builder',
            element: <FormBuilderAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Form Builder", path: "/admin/settings/form-builder", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true, true)
          },
          {
            path: '/admin/settings/pricing',
            element: <PricingAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Tarifications", path: "/admin/settings/pricing", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true, true)
          },
          {
            path: '/admin/legal-notice',
            element: <LegalNoticeAdminViewWrapper />,
            handle: {
              crumb: () => ({ title: "Annonces légales", path: "/admin/legal-notice/fast", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notice/create',
            element: <CreateLegalNoticeViewWrapper />,
            handle: {
              crumb: () => ({ title: "Création d'annonces légales (rapide)", path: "/admin/legal-notice/fast/create", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notice/validate',
            element: <LegalNoticeAdminValidateWrapper />,
            handle: {
              crumb: () => ({ title: "Annonces légales", path: "/admin/legal-notice/fast", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
          {
            path: '/admin/legal-notice/payment',
            element: <LegalNoticeAdminPaymentWrapper />,
            handle: {
              crumb: () => ({ title: "Annonces légales", path: "/admin/legal-notice/fast", isAdmin: true })
            },
            loader: () => checkProtectedRoute(false, true)
          },
        ]
      },
      {
        path: '/',
        element: this.layoutWrapperOutlet('public'),
        children: [
          {
            path: '/particular/legal-notice/',
            element: <ParticularLegalNoticeViewWrapper />
          },
          {
            path: 'not-found',
            element: <NotFoundView/>
          },
          {
            path: 'respond-invitation/:code',
            element: <RespondInvitationViewWrapper/>
          },
          {
            path: 'ask-forgot-password',
            element: <AskForgotPasswordViewWrapper/>
          },
          {
            path: 'forgot-password/:token',
            element: <ForgotPasswordViewWrapper/>
          },
          {
            path: '*',
            element: <NotFoundView/>
          }
        ]
      },
      {
        path: '/payment/callback',
        element: this.layoutWrapperOutlet('public'),
        children: [
          {
            path: 'success',
            element: <PaymentCallbackWrapper paymentStatus="success"/>
          },
          {
            path: 'failure',
            element: <PaymentCallbackWrapper paymentStatus="failure"/>
          },
          {
            path: 'notify',
            element: <PaymentCallbackWrapper paymentStatus="notify"/>
          }
        ]
      },
      {
        path: '/payment/billing',
        element: this.layoutWrapperOutlet('public'),
        children: [
          {
            index: true,
            element: <PaymentViewWrapper />
          },
        ]
      }
    ]);
  }

	private layoutWrapperOutlet(type: 'developer' | 'admin' | 'auth' | 'public' | 'default'): ReactElement
	{
		return (
			<FlashMessageProvider>
				<ModalProvider>
					<LayoutWrapper layoutType={ type }>
						<Outlet/>
					</LayoutWrapper>
				</ModalProvider>
			</FlashMessageProvider>
		);
	}
}
