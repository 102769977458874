import React, { ReactElement } from 'react';
import { Form, Row } from 'react-bootstrap';
import { ClientModel, ExtendedClientModel } from '@/Modules/Client/Model/ClientModel';
import { handleChangeInput } from '@/Utils/InputFormUtils';
import { ApiAppService } from '@/Service/Api/ApiAppService';
import AddressModel from '@/Modules/Client/Model/AddressModel';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { ApiAdminUserService } from '@/Service/Admin/ApiAdminUserService';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { UserRoleEnum } from '@/Modules/User/Enum/UserRoleEnum';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import ActiveStatusEnum from '@/Enum/ActiveStatusEnum';
import Radio from '@/Modules/App/Components/Atom/Form/Radio';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { CompanySellsyInterface } from '@/Modules/Client/Interface/CompanySellsyInterface';
import { dateFormat } from '@/Utils/DateUtils';
import { ApiAdminSellsyService } from '@/Service/Admin/ApiAdminSellsyService';
import { CreateClientFormStyle } from '@/Modules/Client/Style/CreateClientFormStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import CreateUserFormComponent, {
	FormDataCreateUserInterface
} from '@/Modules/User/Components/Form/CreateUserFormComponent';
import { ModalContextType } from '@/Provider/ModalProvider';
import Skeleton from 'react-loading-skeleton';

interface ComponentProps
{
	authContext: AuthContextType,
	authCompanyContext?: AuthCompanyContextType,
	onFormSubmit: (formData: ExtendedClientModel) => void,
	isFromAdmin: boolean,
	flashMessageContext: FlashMessageContextType | null,
	modalContext: ModalContextType | null,
	isLoadingModal?: (isLoading: boolean) => void
}

interface ComponentState
{
	formData: ClientModel,
	isEmailCollaboratorOwnerManual: boolean,
	userSearchResults: UserInterface[],
	selectedUser: UserInterface | null,
	companySellsySearchResults: CompanySellsyInterface[],
	selectedCompanySellsy: CompanySellsyInterface | null,
	isLoadingSellsySearch: boolean,
	isUserSelected: boolean,
	isFormFieldDisabled: boolean,
	sirenErrorMessage: string,
	isAddNewUser: boolean,
	isSearchUserOpen: boolean,
	isSearchCompanySellsyOpen: boolean,
	errorMessage: string | null,
	userRole: string | null,
	userSearch: string | null
}

export default class CreateClientFormComponent extends React.Component<ComponentProps, ComponentState>
{
	appService: ApiAppService;
	userService: ApiAdminUserService;
	apiClientService: ApiAdminClientService;
	apiClientSellsyService: ApiAdminSellsyService;
	inputUserRef: React.RefObject<HTMLDivElement> | null;
	userDropDownRef: React.RefObject<HTMLDivElement> | null;
	companySellsyDropDownRef: React.RefObject<HTMLDivElement> | null;
	createUserModal: ModalContextType | null;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Service
		this.appService = new ApiAppService();
		this.userService = new ApiAdminUserService();
		this.apiClientService = new ApiAdminClientService();
		this.apiClientSellsyService = new ApiAdminSellsyService();

		// Modal
		this.createUserModal = this.props.modalContext || null;

		// Ref
		this.inputUserRef = React.createRef();
		this.userDropDownRef = React.createRef();
		this.companySellsyDropDownRef = React.createRef();

		// Bind
		this.submitHandler = this.submitHandler.bind(this);
		this.callApiSiren = this.callApiSiren.bind(this);
		this.fillFormField = this.fillFormField.bind(this);
		this.onChangeUserSearch = this.onChangeUserSearch.bind(this);
		this.resetFormField = this.resetFormField.bind(this);
		this.searchUser = this.searchUser.bind(this);
		this.handleUserSelect = this.handleUserSelect.bind(this);
		this.resetUserSelection = this.resetUserSelection.bind(this);
		this.onClosedAddNewUser = this.onClosedAddNewUser.bind(this);
		this.onCreateNewUser = this.onCreateNewUser.bind(this);
		this.onClickAddNewUser = this.onClickAddNewUser.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.onChangeEmailCollaboratorOwner = this.onChangeEmailCollaboratorOwner.bind(this);
		this.onSelectedIsUserMailForCollaborator = this.onSelectedIsUserMailForCollaborator.bind(this);
		this.onChangeSellsyId = this.onChangeSellsyId.bind(this);
	}

	render(): ReactElement
	{
		return (
			<>
				{ this.newClientFormContent() }
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	componentDidMount()
	{
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside(event: MouseEvent): void
	{
		if (!this.inputUserRef?.current?.contains(event.target as Node) &&
			!this.userDropDownRef?.current?.contains(event.target as Node)) {
			this.setState({ isSearchUserOpen: false });
		}
	}

	private initState(): ComponentState
	{
		return {
			formData: new ClientModel(),
			isEmailCollaboratorOwnerManual: true,
			userSearchResults: [],
			selectedUser: null,
			companySellsySearchResults: [],
			selectedCompanySellsy: {} as CompanySellsyInterface,
			isLoadingSellsySearch: false,
			isUserSelected: false,
			isFormFieldDisabled: true,
			sirenErrorMessage: '',
			isAddNewUser: false,
			isSearchUserOpen: false,
			isSearchCompanySellsyOpen: false,
			errorMessage: '',
			userRole: null,
			userSearch: null
		};
	}

	//</editor-fold>

	//<editor-fold desc="Content methods" defaultstate="collapsed">

	private newClientFormContent(): ReactElement
	{
		return (
			<>
				<div style={{ maxHeight: 700, overflowY: 'scroll'}}>
					<div style={ { display: 'flex', marginBottom: (this.state.sirenErrorMessage) ? '0px' : '15px' } }>
						<Input
							label={ 'Siren' }
							type={ 'text' }
							name={ 'create-client-siren' }
							placeholder={ '555 555 555' }
							value={ this.state.formData.siren || '' }
							onChange={ (event) => this.onSirenEventField(event) }
							onPaste={ (event) => this.onSirenEventField(event) }
						/>
					</div>

					{ this.state.sirenErrorMessage &&
            <div className="error-message" role="alert">{ this.state.sirenErrorMessage }</div>
					}

					<div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
						<div style={ { flex: '1 0 50%' } }>
							<Input
								label={ 'Société' }
								type="text"
								style={ { display: 'flex' } }
								name="name"
								placeholder="Votre société"
								disabled={ this.state.isFormFieldDisabled }
								value={ this.state.formData.name || '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							/>
						</div>
						<div style={ { flex: '0 1 200px' } }>
							<Input
								label={ 'APE/NAF' }
								type="text"
								name="apeCode"
								placeholder="3456A"
								disabled={ this.state.isFormFieldDisabled }
								value={ this.state.formData.apeCode || '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							/>
						</div>

						<div style={ { flex: '1 0 1' } }>
							<Input
								label={ 'Téléphone' }
								type="phone"
								name="phone"
								placeholder="046725XXXX"
								value={ this.state.formData.phone || '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							/>
						</div>
					</div>
					<div style={ { display: 'flex', gap: 20, width: '100%', marginBottom: '15px' } }>
						<div style={ { flex: '0 1 0' } }>
							<Input
								label={ 'Numéro' }
								type="number"
								width={ '150px' }
								style={ { display: 'flex' } }
								name="address.number"
								disabled={ this.state.isFormFieldDisabled }
								value={ this.state.formData.address.number != null ? this.state.formData.address.number : '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							/>
						</div>
						<div style={ { flex: '1 0 0' } }>
							<Input
								label={ 'Rue' }
								type="text"
								style={ { display: 'flex', width: '100%' } }
								name="address.street"
								placeholder="rue camille"
								disabled={ this.state.isFormFieldDisabled }
								value={ this.state.formData.address.street || '' }
								onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
							/>
						</div>
					</div>
					<div style={ { display: 'flex', marginBottom: '15px' } }>
						<Input
							label={ 'Complément' }
							type="text"
							style={ { display: 'flex' } }
							name="address.additionalData"
							placeholder="Résidence - Appt"
							disabled={ this.state.isFormFieldDisabled }
							value={ this.state.formData.address.additionalData || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
						/>

					</div>
					<div style={ { display: 'flex', gap: '20px' } }>
						<Input
							label={ 'Code Postal' }
							width={ '100%' }
							type="text"
							name="address.zipCode"
							placeholder="34000"
							disabled={ this.state.isFormFieldDisabled }
							value={ this.state.formData.address.zipCode || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
						/>

						<Input
							label={ 'Ville' }
							width={ '100%' }
							type="text"
							placeholder="Montpellier"
							name="address.city"
							disabled={ this.state.isFormFieldDisabled }
							value={ this.state.formData.address.city || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
						/>

						<Input
							label={ 'Pays' }
							width={ '100%' }
							type="text"
							name="address.country"
							placeholder="France"
							disabled={ this.state.isFormFieldDisabled }
							value={ this.state.formData.address.country || '' }
							onChange={ (event) => handleChangeInput(event, this.setState.bind(this)) }
						/>
					</div>
					<Form onSubmit={ this.submitHandler }>
						{ !this.props.isFromAdmin &&
              <Row>
                <Form.Group className="mb-3" id="formGridCheckbox">
                  <Form.Text className="text-small">
                    <Form.Check type="checkbox" label="" className="mw-20"/>
                    Lorem ipsum dolor
                    sit amet, consectetur adipiscing elit. Vestibulum enim erat, congue at elit ac, elementum
                    pellentesque
                    dui. Etiam rutrum tellus in aliquam pellentesque. Praesent volutpat auctor metus. Nulla facilisi. In
                    mauris nisi, facilisis et dolor posuere, tristique congue urna.
                  </Form.Text>
                </Form.Group>
              </Row>
						}
						{
							this.props.isFromAdmin &&
              <React.Fragment key={ this.state.toString() }>
                <hr/>

                <div style={ { ...FontStyle.h3(), marginBottom: 10 } }>Associé un Utilisateur</div>
                <div style={ { display: 'flex', position: 'relative', } }>
									{ this.associateUserSearchHtml() }
                </div>

								{ this.state.selectedUser &&
                  <>
                    <hr/>
                    <Title type={ 'h5' }>Collaborateur propriétaire</Title>
                    <div style={ { marginTop: '10px' } }>
                      <Radio
                        label="Voulez-vous utiliser l'email de l'utilisateur pour le collaborateur propriétaire ?"
                        options={ [{ label: 'Oui', value: 'true' }, { label: 'Non', value: 'false' }] }
                        name="is-email-collaborator-owner"
                        selectedValue={ this.state.isEmailCollaboratorOwnerManual.toString() }
                        onSelectedOption={ (event: any) => this.onSelectedIsUserMailForCollaborator(event) }
                      />

											{ !this.state.isEmailCollaboratorOwnerManual &&
                        <div style={ { marginTop: '10px' } }>
                          <Input
                            type="text"
                            width={ '100%' }
                            name={ 'email-collaborator-owner' }
                            label={ 'Renseignez l\'email du collaborateur propriétaire' }
                            placeholder="Ex: john.doe@hje.com"
                            onChange={ (event) => this.onChangeEmailCollaboratorOwner(event) }
                            value={ this.state.formData.emailCollaboratorOwner || '' }
                            style={ { flex: '0 0 300px' } }
                            onFocus={ () => this.setState({ isSearchUserOpen: true }) }
                          />
                        </div>
											}
                    </div>
                  </>
								}
              </React.Fragment>
						}

						{ this.props.authContext
							&& (this.props.authContext.user.role === UserRoleEnum.ROLE_SUPER_ADMIN.value
							|| this.props.authContext.user.role === UserRoleEnum.ROLE_ADMIN.value) &&
							this.associateSellyCompanyContent()
						}

						<div className="input-submit mt-3">
							<Button
								type="default-blue"
								onClick={ (event: any) => this.submitHandler(event) }
							>
								Créer le donneur d'ordre
							</Button>
						</div>
					</Form>
				</div>
			</>
		);
	}

	//</editor-fold>

	//<editor-fold desc="Siren methods" defaultstate="collapsed">

	async onSirenEventField(event: React.ChangeEvent<any>): Promise<any>
	{
		const cleanSirenValue = (value: string) => value.replace(/\s+/g, '');

		// Init vars
		let inputSirenValue = event.target.value;
		inputSirenValue = cleanSirenValue(inputSirenValue);

		await this.callApiSiren(inputSirenValue);
	}

	private async callApiSiren(inputSirenValue: string): Promise<void>
	{
		this.setState({ formData: { ...this.state.formData, siren: inputSirenValue } });

		if (inputSirenValue.length === 9) {
			try {
				this.props.isLoadingModal && this.props.isLoadingModal(true);
				const responseSiren = await this.appService.sirenInsee(inputSirenValue);

				// Error message
				if (responseSiren.errorMessage) {
					this.setState({ sirenErrorMessage: responseSiren.errorMessage });
					return;
				}

				// Fill form field
				this.fillFormField(responseSiren);
			} catch (error: any) {
				console.log('something wrong on app siren: ', error);
			}
		} else {
			this.resetFormField();
		}
	}

	private fillFormField(responseSiren: any)
	{
		this.setState({
			formData: {
				...this.state.formData,
				name: responseSiren.name || '',
				apeCode: responseSiren.apeCode || '',
				address: AddressModel.prepare(responseSiren.addressDto || {})
			},
			sirenErrorMessage: '',
			isFormFieldDisabled: false
		}, () => this.props.isLoadingModal && this.props.isLoadingModal(false));
	}

	private resetFormField()
	{
		this.setState(prevState =>
		{
			return {
				formData: this.prepareFormData(prevState),
				sirenErrorMessage: '',
				isFormFieldDisabled: true
			};
		});
	}

	private prepareFormData(prevState: any)
	{
		const newFormData = new ClientModel();
		newFormData.siren = prevState.formData.siren || '';
		newFormData.phone = prevState.formData.phone || '';
		newFormData.name = '';
		newFormData.apeCode = '';
		newFormData.address = new AddressModel();

		return newFormData;
	}

	//</editor-fold>

	//<editor-fold desc="Search user methods" defaultstate="collapsed">

	private async searchUser(term: string): Promise<void>
	{
		const results = await this.userService.search(term, UserRoleEnum.ROLE_USER.value);
		this.setState({ userSearchResults: results });
	}

	private async onChangeUserSearch(event: any): Promise<any>
	{
		const userSearch = event.target.value;
		this.setState({ userSearch }, async () =>
		{
			await this.searchUser(userSearch);
		});
	}

	private handleUserSelect(event: any, user: UserInterface): void
	{
		this.setState(prevState => ({
			...prevState,
			isUserSelected: true,
			selectedUser: user

		}), () => event.stopPropagation());
	}

	private onClickAddNewUser(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		//this.setState({ isAddNewUser: true });
		if (this.createUserModal) {
			this.createUserModal.content(
				'Ajouter un utilisateur',
				<CreateUserFormComponent
					key={ this.state.errorMessage }
					isAdmin={ true }
					onFormSubmit={ this.onCreateNewUser }
					allowedUserRole={ false }
					errorMessage={ this.state.errorMessage || '' }
					resetErrorMessage={ () => this.setState({ errorMessage: '' }) }
				/>
			);
		}
	}

	private resetUserSelection(event: any): void
	{
		event.stopPropagation();

		this.setState(prevState => ({
			...prevState,
			isUserSelected: false,
			selectedUser: null,
			isSearchUserOpen: true
		}));
	}

	private associateUserSearchHtml(): ReactElement
	{
		return (
			<>
				{ !this.state.isUserSelected ? (
					<>
						<div ref={ this.inputUserRef } style={ { position: 'relative', width: '100%' } }>
							<Input
								type="text"
								width={ '100%' }
								name={ 'associate-user' }
								label={ 'Associer un utilisateur' }
								placeholder="Rechercher un utilisateur"
								autocomplete={ 'off' }
								onChange={ (event) => this.onChangeUserSearch(event) }
								style={ { flex: '0 0 300px' } }
								value={ this.state.userSearch || '' }
								onFocus={ () => this.setState({ isSearchUserOpen: true }) }
							/>
						</div>
						{ this.state.isSearchUserOpen && (
							<>
								<div
									ref={ this.userDropDownRef }
									style={ CreateClientFormStyle.containerSearchUserStyle() }>
									<div style={ CreateClientFormStyle.headerSearchUserStyle() }>
										<Button
											type={ 'inline-default-blue' }
											style={ { marginBottom: 5 } }
											onClick={ (event: any) => this.onClickAddNewUser(event) }>
											Ajouter un utilisateur
										</Button>
									</div>
									{ this.state.userSearchResults.length > 0 &&
                    <div style={ { overflowY: 'scroll' } }>
                      <div style={ { maxHeight: 150 } }>
												{ this.state.userSearchResults.map((user: UserInterface) => (
													<div
														key={ user.id }
														style={ { cursor: 'pointer', padding: '5px 10px' } }
														onClick={ (event: any) => this.handleUserSelect(event, user) }
													>
														<span
															style={ { fontWeight: 500 } }
														>
															{ this.stringToCapitalize(user.lastname) } { this.stringToCapitalize(user.firstname) }
														</span>
														<span style={ { fontSize: 12, color: CssVariableEnum['--color-grey-400'] } }>
															&nbsp; { user.email }
														</span>
														<span style={ CreateClientFormStyle.bodyUserTagStyle() }>#{ user.tag }</span>
													</div>
												)) }
                      </div>
                    </div>
									}

									{ this.state.userSearchResults.length === 0 &&
                    <div>Aucun utilisateur trouvé</div>
									}
								</div>
							</>
						) }
					</>
				) : (
					<>
						<div style={ { display: 'flex', width: '100%', alignItems: 'flex-end' } }>
							<Input
								type="text"
								width={ '100%' }
								name={ 'associate-user' }
								label={ 'Associer un utilisateur' }
								onChange={ () => null }
								style={ { flex: '1 0 0%' } }
								value={ this.state.selectedUser &&
									`${ (this.state.selectedUser as UserInterface).lastname } ${ (this.state.selectedUser as UserInterface).firstname }(${ (this.state.selectedUser as UserInterface).email }) #${ (this.state.selectedUser as UserInterface).tag }` }
							/>

							<Button
								type={ 'default' }
								onClick={ (event: any) => this.resetUserSelection(event) }
							>
								Modifier
							</Button>
						</div>
					</>
				) }
			</>
		);
	}

	private async onCreateNewUser(formData: FormDataCreateUserInterface, event: any): Promise<void>
	{
		event.stopPropagation();
		formData.status = ActiveStatusEnum.ACTIVE.value;
		formData.role = UserRoleEnum.ROLE_USER.value;

		try {
			this.createUserModal && this.createUserModal.isLoading(true);
			const newUser = await this.userService.create(formData);

			if (newUser.errorMessage && this.createUserModal) {
				this.setState({
					errorMessage: newUser.errorMessage,
				});
				this.createUserModal.isLoading(false);
				this.createUserModal.errorMessage('Attention, tous les champs sont requis');
				return;
			}

			this.setState({ isAddNewUser: false });
			this.props.flashMessageContext?.flashMessage(
				'Nouvel Utilisateur',
				'L\'utilisateur ' + formData.firstname + formData.lastname + ' a bien été créé',
				'success'
			);

			this.setState({ isUserSelected: true, selectedUser: newUser });
			this.createUserModal && this.createUserModal.isLoading(false);
			this.createUserModal && this.createUserModal.isOpen(false);

		} catch (error: any) {
			console.log('Issue from create User [CreateClientFormComponent]', error.errorMessage);
		}
	}

	private onClosedAddNewUser(event: any, isAddNewUser: boolean): void
	{
		event.stopPropagation();
		this.setState({ isAddNewUser });
	}

	private stringToCapitalize(stringToConvert: string): string
	{
		return stringToConvert[0].toUpperCase() + stringToConvert.slice(1).toLowerCase();
	}

	//</editor-fold>

	//<editor-fold desc="Sellsy methods" defaultstate="collapsed">

	private associateSellyCompanyContent(): ReactElement
	{
		return (
			<>
				<hr/>
				<div style={ {
					position: 'relative',
					backgroundColor: CssVariableEnum['--color-purple-100'],
					borderRadius: 15,
					padding: 15,
					border: `1px solid ${ CssVariableEnum['--color-purple-300'] }`
				} }>
					<div style={ {
						...FontStyle.h3(CssVariableEnum['--color-purple-500']),
						marginBottom: 10
					} }>
						Rechercher ce client sur Sellsy
					</div>
					<div style={ { display: 'flex', width: '100%' } }>
						{ this.state.selectedCompanySellsy && this.state.selectedCompanySellsy?.id ? (
							this.selectedCompanySellsyContent()
						) : (
							<div style={ { display: 'flex', alignItems: 'flex-end' } }>
								{ (!this.state.formData.isSellsyCreated)
									? this.sellsySearchFormContent()
									: this.sellsyAddNewCompanyContent()
								}
							</div>
						) }
					</div>
				</div>
			</>
		);
	}

	private selectedCompanySellsyContent(): ReactElement
	{
		return (
			<>
				<div style={ CreateClientFormStyle.cardSellsyStyle() }>
					<div
						key={ this.state.selectedCompanySellsy?.id }
						style={ { cursor: 'pointer', padding: '5px 10px' } }
					>
						<div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
							<div style={ { fontWeight: 600, fontSize: '18px' } }>
								{ this.state.selectedCompanySellsy?.name }
							</div>
							<div style={ { fontWeight: 500, fontSize: '14px', color: CssVariableEnum['--color-grey-500'] } }>
								#{ this.state.selectedCompanySellsy?.auxiliary_code }
							</div>
						</div>
						<div style={ { marginBottom: '10px' } }>
							<span style={ { fontSize: '14px', fontWeight: 500 } }>ID: </span>
							<span style={ { fontSize: '14px' } }>{ this.state.selectedCompanySellsy?.id }</span>
						</div>
						<div style={ { marginBottom: '10px' } }>
							<span style={ { fontSize: '14px', fontWeight: 500 } }>Créé le: </span>
							<span style={ { fontSize: '14px' } }>
								{ dateFormat(this.state.selectedCompanySellsy?.created as string) }
							</span>
						</div>
						<div style={ { fontSize: '14px', color: CssVariableEnum['--color-grey-400'] } }>
							{ this.state.selectedCompanySellsy?.email }
						</div>
					</div>

					<Button
						style={ { marginTop: '22px', minWidth: '90px', position: 'relative' } }
						type="tag-blue"
						onClick={ (event: any) => this.cancelSearchSellsy(event) }
						disabled={ this.state.isLoadingSellsySearch }
					>
						Annuler
					</Button>
				</div>
			</>
		);
	}

	private sellsySearchFormContent(): ReactElement
	{
		const { isLoadingSellsySearch, isSearchCompanySellsyOpen, formData } = this.state;

		return (
			<>
				<div style={ { position: 'relative' } }>
					<div style={ {
						fontSize: 12,
						lineHeight: '14px',
						marginBottom: 10,
						color: CssVariableEnum['--color-grey-500'],
						width: 300
					} }
					>
						Pour rechercher ce client, saisissez son identifiant Sellsy dans le champ de recherche ci-dessous.
					</div>
					<div style={ { display: 'flex', alignItems: 'center' } }>
						{ isLoadingSellsySearch
							? <Skeleton height={ 32 } width={ 300 } baseColor={ CssVariableEnum['--color-grey-200'] }/>
							: <Input
								type="text"
								width={ '300px' }
								name={ 'ext-sellsy-id' }
								onChange={ (event) => this.onChangeSellsyId(event) }
								value={ formData.extSellsyId || '' }
							/>
						}
						<Button
							label={ 'Rechercher' }
							style={ { minWidth: '90px', position: 'relative' } }
							type="default-purple"
							onClick={ (event: any) => this.searchCompanySellsy(event) }
							disabled={ isLoadingSellsySearch }
						/>
						<Button
							label={ 'Créer un client' }
							type={ 'default-dark' }
							style={ { minWidth: '205px', position: 'relative' } }
							onClick={ (event: any) => this.onClickNewCompanySellsy(event) }
							disabled={ isLoadingSellsySearch }
						/>
					</div>
					{ isSearchCompanySellsyOpen &&
						(
							<React.Fragment>
								<div
									ref={ this.companySellsyDropDownRef }
									style={ CreateClientFormStyle.selectOptionSellsyStyle() }
								>
									{ this.state.companySellsySearchResults.length > 0 &&
                    <div style={ { overflowY: 'scroll' } }>
                      <div style={ { maxHeight: 150 } }>
												{ this.state.companySellsySearchResults.map((companySellsy: CompanySellsyInterface) => (
													<div
														key={ companySellsy.id }
														style={ { cursor: 'pointer', padding: '5px 10px' } }
														onClick={ (event: any) => this.onCompanySellsySelect(event, companySellsy) }
													>
														<div style={ { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }>
															<div
																style={ { fontWeight: 600, fontSize: 14, color: CssVariableEnum['--color-grey-900'] } }>
																{ companySellsy.name }
															</div>
															<div style={ {
																fontWeight: 500,
																fontSize: '14px',
																color: CssVariableEnum['--color-grey-500']
															} }>
																#{ companySellsy.auxiliary_code }
															</div>
														</div>
														<div>
															<span style={ {
																fontSize: '14px',
																fontWeight: 500,
																color: CssVariableEnum['--color-grey-400']
															} }>ID: </span>
															<span style={ { fontSize: '14px' } }>{ companySellsy.id }</span>
														</div>
														<div>
															<span style={ {
																fontSize: '14px',
																fontWeight: 500,
																color: CssVariableEnum['--color-grey-400']
															} }>Créé le: </span>
															<span style={ { fontSize: '14px' } }>{ dateFormat(companySellsy.created) }</span>
														</div>
														<div>
															<span style={ {
																fontSize: '14px',
																fontWeight: 500,
																color: CssVariableEnum['--color-grey-400']
															} }>email: </span>
															<span style={ { fontSize: '14px' } }>{ companySellsy.email }</span>
														</div>
													</div>
												)) }
                      </div>
                    </div>
									}

									{ this.state.companySellsySearchResults.length === 0 &&
                    <>
                      <div style={ { display: 'flex', justifyContent: 'space-between' } }>
                        <div style={ { marginTop: '5px' } }>Aucune fiche Sellsy trouvée</div>
                        <Button
                          type={ 'inline-default' }
                          style={ { marginBottom: 5 } }
                          onClick={ () => this.setState({ isSearchCompanySellsyOpen: false }) }>
                          X
                        </Button>
                      </div>
                    </>
									}
								</div>
							</React.Fragment>
						)
					}
				</div>
			</>
		);
	}

	private sellsyAddNewCompanyContent(): ReactElement
	{
		return (
			<>
				<div style={ {
					backgroundColor: CssVariableEnum['--color-purple-50'],
					border: `1px solid ${ CssVariableEnum['--color-purple-500'] }`,
					borderRadius: '6px',
					padding: '5px 10px'
				} }>
					<div style={ { display: 'flex' } }>
						<img
							style={ {
								width: '35px',
								marginRight: '15px',
								padding: '5px',
								borderRadius: '5px',
								backgroundColor: CssVariableEnum['--color-purple-200']
							} }
							src="/img/logo-sellsy.png" alt="Sellsy Logo"
						/>

						<div style={ { color: CssVariableEnum['--color-purple-500'] } }>
							Le client sera créé automatique pendant la création du Donneur d'ordre
						</div>
					</div>

					<Button
						type={ 'danger' }
						style={ { display: 'flex', justifyContent: 'end', minWidth: '205px', position: 'relative' } }
						onClick={ (event: any) => this.cancelCreatedSellsy(event) }
						disabled={ this.state.isLoadingSellsySearch }
					>
						Annuler
					</Button>
				</div>
			</>
		);
	}

	private onChangeSellsyId(event: any): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				extSellsyId: event.target.value
			},
		}));
	}

	private async searchCompanySellsy(event: any): Promise<void>
	{
		event.preventDefault();
		event.stopPropagation();

		const companyId = (this.props.authCompanyContext?.authCompany)
			? this.props.authCompanyContext?.authCompany?.id
			: this.props.authContext.user?.company?.id
		;

		this.setState({ isLoadingSellsySearch: true });
		if (this.props.authCompanyContext) {
			const companySearchResult = await this.apiClientSellsyService.searchCompanyById(
				this.state.formData.extSellsyId,
				companyId
			);
			this.setState({
				companySellsySearchResults: companySearchResult,
				isSearchCompanySellsyOpen: true,
				isLoadingSellsySearch: false
			});
		}
	}

	private onClickNewCompanySellsy(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				isSellsyCreated: true,
				extSellsyId: null
			}
		}));
	}

	private cancelCreatedSellsy(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				isSellsyCreated: false
			}
		}));
	}

	private cancelSearchSellsy(event: any): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState({ selectedCompanySellsy: {} as CompanySellsyInterface });
	}

	private onCompanySellsySelect(event: any, companySellsy: CompanySellsyInterface): void
	{
		event.preventDefault();
		event.stopPropagation();

		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				extSellsyId: companySellsy.id
			},
			selectedCompanySellsy: companySellsy,
			isSearchCompanySellsyOpen: false
		}));
	}

	//</editor-fold>

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	private onChangeEmailCollaboratorOwner(event: any): void
	{
		this.setState((prevState: any) => ({
			formData: {
				...prevState.formData,
				emailCollaboratorOwner: event.target.value || ''
			},
		}));
	}

	private onSelectedIsUserMailForCollaborator(event: any): void
	{
		if (event === 'true') {
			this.setState((prevState: any) => ({
				isEmailCollaboratorOwnerManual: true,
				formData: {
					...prevState.formData,
					emailCollaboratorOwner: ''
				},
			}));
		} else {
			this.setState((prevState: any) => ({
				isEmailCollaboratorOwnerManual: false,
				formData: {
					...prevState.formData,
					emailCollaboratorOwner: ''
				}
			}));
		}
	}

	private async submitHandler(event: React.FormEvent<HTMLFormElement>): Promise<any>
	{
		event.preventDefault();
		event.stopPropagation();

		let updatedFormData: ExtendedClientModel | ClientModel = this.state.formData;

		if (this.props.isFromAdmin && this.state.selectedUser) {
			updatedFormData = {
				...this.state.formData,
				userId: (this.state.selectedUser as UserInterface).id
			};
		}

		console.log(updatedFormData);

		this.props.modalContext?.isLoading(true);
		this.props.onFormSubmit(updatedFormData as ClientModel);
	}

	//</editor-fold>
}