import React, { ReactElement } from 'react';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import LegalNoticeTypeEnum from '@/Enum/LegalNoticeTypeEnum';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';

interface ComponentProps
{
  formData: any,
  category: FormBuilderCategoryInterface|null,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export default class ConsumerFormComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        <div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
          <div style={ { flex: '1 0 50%' } }>
            <Input
              type="text"
              label="Dénomination"
              name="name"
              style={ { display: 'flex' } }
              value={ this.props.formData.name || '' }
              onChange={ this.props.onChange }
              disabled={ !!(this.props.category
                && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                && !this.props.formData.siren)
              }
            />
          </div>
          { this.props.category && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
            &&
            <div style={ { flex: '0 1 30%' } }>
              <Input
                type="text"
                style={ { display: 'flex' } }
                label="RCS"
                name="rcs"
                value={ this.props.formData.rcs || '' }
                onChange={ this.props.onChange }
                disabled={ !!(this.props.category
                  && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                  && !this.props.formData.siren)
                }
              />
            </div>
          }
        </div>
        <div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
          <div style={ { flex: '1 0 50%' } }>
            <Input
              type="text"
              label="Forme juridique"
              name="legalStatus"
              style={ { display: 'flex' } }
              value={ this.props.formData.legalStatus || '' }
              onChange={ this.props.onChange }
              disabled={ !!(this.props.category
                && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                && !this.props.formData.siren)
              }
            />
          </div>
          <div style={ { flex: '1 0 20%' } }>
            <Input
              type="number"
              style={ { display: 'flex' } }
              label="Capital"
              name="capital"
              value={ this.props.formData.capital || '' }
              onChange={ this.props.onChange }
              disabled={ !!(this.props.category
                && this.props.category.categoryType !== LegalNoticeTypeEnum.CONSTITUTION.value
                && !this.props.formData.siren)
              }
            />
          </div>
        </div>
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">
  //</editor-fold>
}