import React, { ReactElement } from 'react';
import { ClientInterface } from '@/Modules/Client/Interface/ClientInterface';
import { ApiAdminClientService } from '@/Service/Admin/ApiAdminClientService';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import Swal from 'sweetalert2';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';

interface ComponentProps
{
	client: ClientInterface | null;
	onSelectedClient: (client: ClientInterface) => void
}

interface ComponentState
{
	selectedClient: ClientInterface | null,
	clientSearchResult: ClientInterface[],
	isTempClientLoading: boolean
}

export default class SearchClientComponent extends React.Component<ComponentProps, ComponentState>
{
	clientService: ApiAdminClientService;
	state = {
		selectedClient: this.props.client || null,
		clientSearchResult: [] as ClientInterface[],
		isTempClientLoading: false
	};

	constructor(props: any)
	{
		super(props);

		// Service
		this.clientService = new ApiAdminClientService();
		// Bind
		this.searchClient = this.searchClient.bind(this);
		this.onClientSelected = this.onClientSelected.bind(this);
		this.resetClientSelection = this.resetClientSelection.bind(this);
	}

	render(): ReactElement
	{
		if (this.state.isTempClientLoading) {
			return <LoaderComponent/>;
		}

		return (
			<>
				{ !this.state.selectedClient ? (
					<>
						<Input
							type="text"
							width={ '100%' }
							autocomplete={ 'off' }
							name={ 'associate-client-legal-notice' }
							placeholder="Rechercher un donneur d'ordre"
							onChange={ (event) => this.onChangeClientSearch(event) }
							style={ { flex: '0 0 300px' } }
						/>
						{ this.state.clientSearchResult.length > 0 && (
							<>
								<div
									style={ {
										position: 'absolute',
										zIndex: 200,
										backgroundColor: CssVariableEnum['--color-white'],
										borderRadius: '8px',
										boxShadow: '0 2px 20px #00000029',
										width: '100%',
										padding: '5px 10px',
										top: 35,
										fontWeight: 500,
										border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
										transitionProperty: 'opacity, transform',
										transitionDuration: '270ms',
										transitionTimingFunction: 'ease'
									} }
								>
									<div style={ { overflowY: 'scroll' } }>
										<div style={ { maxHeight: 150 } }>
											{ this.state.clientSearchResult.map((client: ClientInterface) => (
												<div
													key={ client.id }
													style={ { cursor: 'pointer', padding: '5px 10px' } }
													onClick={ () => this.onClientSelected(client) }
												>
													<span
														style={ { fontWeight: 500 } }>{ this.stringToCapitalize(client.name) }
													</span>
												</div>
											)) }
										</div>
									</div>
								</div>
							</>
						) }
					</>
				) : (
					<>
						<div style={ { display: 'flex', width: '100%', alignItems: 'flex-end' } }>
							<Input
								type="text"
								width={ '100%' }
								name={ 'associate-user' }
								onChange={ () => null }
								style={ { flex: '1 0 0%' } }
								value={ this.state.selectedClient &&
									`${ (this.state.selectedClient as ClientInterface).name }`
								}
							/>
							<Button
								type={ 'default' }
								onClick={ this.resetClientSelection }
								disabled={ true } // TEMP FOR PREPROD
							>
								Modifier
							</Button>
						</div>
					</>
				) }
			</>
		);
	}

	//<editor-fold desc="Private methods" defaultstate="collapsed">

	async componentDidMount(): Promise<void>
	{
		await this.tempClientForPreprod();
	}

	componentDidUpdate(prevProps: any)
	{
		if (this.props.client !== prevProps.client) {
			this.setState({ selectedClient: this.props.client });
		}
	}

	/**
	 * THIS IS TEMP FOR TESTING ON PREPROD
	 * REMOVE AFTER TESTING !!!
	 *
	 * @private
	 */
	private async tempClientForPreprod(): Promise<void> {
		this.setState({ isTempClientLoading: true });
		try {
			const tempsClient = await this.clientService.search('TEST PREPROD');
			this.setState({ selectedClient: tempsClient[0], clientSearchResult: tempsClient });
			this.props.onSelectedClient(tempsClient[0]); // Notifies the parent about the selected client
		} catch (error) {
			console.error('Error fetching TEST PREPROD client:', error);
		} finally {
			this.setState({ isTempClientLoading: false });
		}
	}

	private async onChangeClientSearch(event: React.ChangeEvent<any>)
	{
		const term = event.target.value;
		await this.searchClient(term);
	}

	private async searchClient(term: string): Promise<void>
	{
		const results = await this.clientService.search(term);
		this.setState({ clientSearchResult: results });
	}

	private onClientSelected(client: ClientInterface): void
	{
		this.setState({ selectedClient: client });
		this.props.onSelectedClient(client);
	}

	private async resetClientSelection(): Promise<void>
	{
		const result = await Swal.fire({
			title: 'Voulez-vous changer de client ?',
			text: 'Si vous changez de client, le formulaire qui suit va se réinitialiser',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Oui, changez-le !',
			cancelButtonText: 'Annuler'
		});

		if (!result.isConfirmed) {
			return;
		} else {
			this.setState({ selectedClient: null, clientSearchResult: [] });
		}
	}

	private stringToCapitalize(stringToConvert: string): string
	{
		return stringToConvert[0].toUpperCase() + stringToConvert.slice(1).toLowerCase();
	}

	//</editor-fold>
}