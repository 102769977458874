import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class LayoutAdminStyle
{
	static mainLayoutGrid(): CSSProperties
	{
		return {
			height: '100%',
			display: 'grid',
			gridTemplateRows: '50px auto',
			position: 'fixed',
			width: '100%',
			top: 0,
			maxWidth: '100vw'
		};
	}

	static menuHeaderContainer(isThemeDark: boolean): CSSProperties
	{
		return {
			border: 'none',
			maxWidth: '100vw',
			backgroundColor: (isThemeDark) ? '#1f1e21' : CssVariableEnum['--color-grey-25'],
			padding: '0 25px',
			height: '100%',
			transition: 'background-color 200ms ease'
		};
	}

	static menuHeaderGrid(): CSSProperties
	{
		return {
			display: 'grid',
			gridTemplateColumns: 'calc(240px - 25px) auto',
			height: '100%'
		};
	}

	static menuHeaderLogo(): CSSProperties
	{
		return { display: 'flex', alignItems: 'center', height: '100%' };
	}

	static menuHeaderMainGrid(): CSSProperties
	{
		return {
			display: 'grid',
			gridTemplateColumns: '40% auto',
			alignItems: 'center',
			height: '100%'
		};
	}

	static warningBlockToDevelopMode(): CSSProperties
	{
		return {
			display: 'inline-flex',
			backgroundColor: CssVariableEnum['--color-green-100'],
			color: CssVariableEnum['--color-green-500'],
			position: 'relative',
			transitionProperty: 'opacity, transform',
			transitionDuration: '500ms',
			transitionTimingFunction: 'ease',
			padding: '5px 10px',
			borderRadius: '8px',
			textAlign: 'center'
		};
	}

	static menuHeaderProfilAndDate(): CSSProperties
	{
		return {
			position: 'relative',
			display: 'flex',
			gap: 30,
			height: '100%',
			alignItems: 'center',
			justifyContent: 'flex-end'
		};
	}

	static sidebarAndMainContentGrid(): CSSProperties
	{
		return { display: 'grid', gridTemplateColumns: '240px auto' };
	}

	static menuSidebar(isThemeDark: boolean): CSSProperties
	{
		return {
			padding: '0 25px',
			border: 'none',
			backgroundColor: (isThemeDark) ? '#1f1e21' : CssVariableEnum['--color-grey-25'],
			color: (isThemeDark) ? CssVariableEnum['--color-white'] : CssVariableEnum['--color-grey-900'],
			transition: 'background-color 200ms ease',
			maxHeight: 'calc(100vh - 50px)',
			height: '100%'
		};
	}

	static transitionBackground(isThemeDark: boolean): CSSProperties
	{
		return {
			backgroundColor: (isThemeDark) ? '#1f1e21' : CssVariableEnum['--color-grey-25'],
			transition: 'background-color 200ms ease'
		};
	}

	static mainContent(): CSSProperties
	{
		return {
			height: '100%',
			borderTopLeftRadius: '26px',
			backgroundColor: CssVariableEnum['--color-grey-100'],
			maxWidth: 'calc(100vw - 240px)',
			maxHeight: 'calc(100vh - 50px)',
			width: '100%',
			overflow:'auto'
		};
	}
}