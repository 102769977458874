import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentView from '@/Modules/Payment/View/PaymentView';
import { FlashMessageContext } from '@/Provider/FlashMessageProvider';

const CreateLegalNoticeAdminViewWrapper = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const flashMessageContext = useContext(FlashMessageContext);

  return <PaymentView
    location={ location }
    flashMessageContext={ flashMessageContext }
    navigation={ navigate }
    { ...props }
  />;
};

export default CreateLegalNoticeAdminViewWrapper;