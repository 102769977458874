import React from 'react';
import { stringAvatar } from '@/Utils/AvatarUtils';
import LoaderFullPageComponent from '@/Modules/App/Components/LoaderFullPageComponent';

export default class AvatarComponent extends React.Component
  <any, { onAvatarClick?: () => void }>
{
  render() {
    if (!this.props.authContext.user) {
      return <LoaderFullPageComponent/>;
    }

    return (
      <>
        <div
          className="avatar"
          style={ { backgroundColor: stringAvatar(this.props.authContext.user.firstname, this.props.authContext.user.lastname).color } }
          onClick={ this.props.onAvatarClick }
        >
          { stringAvatar(this.props.authContext.user.firstname, this.props.authContext.user.lastname).avatar }
        </div>
      </>
    );
  }
}