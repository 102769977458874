import React, { createRef, ReactElement } from 'react';
import { BsBoxArrowRight } from 'react-icons/bs';
import { dateHeader } from '@/Utils/DateUtils';
import CardActionComponent from '@/Modules/App/Components/Atom/Card/CardActionComponent';
import { AuthContext } from '@/Provider/AuthProvider';
import { AuthContextType } from '@/Provider/Interface/Auth/AuthContextType';
import { NavigateFunction } from 'react-router-dom';
import MainNavigationAdminComponent from '@/Modules/App/Components/Admin/Menu/MainNavigationAdminComponent';
import AvatarComponentWrapper from '@/Modules/App/Wrapper/AvatarComponentWrapper';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import HeaderSwitchCompany from '@/Modules/App/Components/HeaderSwitchCompany';
import { AuthCompanyContextType } from '@/Provider/Interface/AuthCompany/AuthCompanyContextType';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { UserRoleEnum } from '@/Enum/UserRoleEnum';
import LoaderComponent from '@/Modules/App/Components/LoaderComponent';
import { LayoutAdminStyle } from '@/Modules/App/Style/Layout/LayoutAdminStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { LuMoon } from 'react-icons/lu';

interface LayoutProps
{
	authContext: AuthContextType,
	companyContext: AuthCompanyContextType,
	flashMessageContext: FlashMessageContextType,
	children?: React.ReactNode,
	navigation: NavigateFunction
}

interface LayoutState
{
	isAvatarCardOpen: boolean,
	isModalOpen: boolean,
	isThemeDark: boolean
}

export default class LayoutAdmin extends React.Component<LayoutProps, LayoutState>
{
	static contextType = AuthContext;
	navigation: NavigateFunction;
	menuActionRef = createRef<HTMLDivElement>();
	ENV: any = process.env.REACT_APP_ENV;

	constructor(props: any)
	{
		super(props);

		// State
		this.state = this.initState();

		// Navigate
		this.navigation = props.navigation;
	}

	render(): ReactElement
	{
		if (!this.props.authContext.user) {
			return <LoaderComponent height={ '100vh' }/>;
		}

		return (
			<>
				<div style={ LayoutAdminStyle.mainLayoutGrid() }>
					<div style={ LayoutAdminStyle.menuHeaderContainer(this.state.isThemeDark) }>
						<div style={ LayoutAdminStyle.menuHeaderGrid() }>
							<div style={ LayoutAdminStyle.menuHeaderLogo() }>
								<div
									style={ { width: 100, cursor: 'pointer' } }
									onClick={ (): void => this.props.navigation(`/admin/`) }
								>
									<img src={ (this.state.isThemeDark) ? '/img/logo-publisur-white.svg' : '/img/logo-publisur-noir.svg' }
											 alt="logo publisur"/>
								</div>
							</div>
							<div style={ LayoutAdminStyle.menuHeaderMainGrid() }>
								{/* SWITCH COMPANY */ }
								<div>
									{ this.props.authContext.user.role === UserRoleEnum.ROLE_SUPER_ADMIN &&
                    <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }>
                      <HeaderSwitchCompany
                        flashMessageContext={ this.props.flashMessageContext }
                        companyContext={ this.props.companyContext }
                        authContext={ this.props.authContext }
                        navigation={ this.props.navigation }
                        isThemeDark={ this.state.isThemeDark }
                      />
											{ this.ENV === 'dev' &&
                        <div style={ LayoutAdminStyle.warningBlockToDevelopMode() }>
                          ATTENTION : Vous êtes en environnement de dev !
                        </div>
											}
                    </div>
									}
								</div>

								<div style={ LayoutAdminStyle.menuHeaderProfilAndDate() }>
									<div style={ { cursor: 'pointer' } }
											 onClick={ () => this.setState(prevState => ({ isThemeDark: !prevState.isThemeDark })) }>
										<LuMoon fontSize={ 18 }
														color={ (this.state.isThemeDark) ? CssVariableEnum['--color-white'] : CssVariableEnum['--color-grey-900'] }/>
									</div>
									<div
										style={ FontStyle.normal((this.state.isThemeDark) ? CssVariableEnum['--color-white'] : CssVariableEnum['--color-grey-900']) }>
										{ dateHeader() }
									</div>
									<AvatarComponentWrapper onAvatarClick={ this.handleAvatarClick.bind(this) }/>
									<CardActionComponent
										className="menu-action-avatar"
										buildActionsContent={ this.buildCardActionsContent() }
										menuActionRef={ this.menuActionRef }
										isOpen={ this.state.isAvatarCardOpen }
										onClose={ this.closeAvatarCardAction.bind(this) }
									/>
								</div>
							</div>
						</div>
					</div>
					<div style={ LayoutAdminStyle.sidebarAndMainContentGrid() }>
						<div style={ LayoutAdminStyle.menuSidebar(this.state.isThemeDark) }>
							<MainNavigationAdminComponent authContext={ this.props.authContext }/>
						</div>
						<main style={ LayoutAdminStyle.transitionBackground(this.state.isThemeDark) }>
							<div style={ LayoutAdminStyle.mainContent() }>
								{ this.props.children }
							</div>
						</main>
					</div>
				</div>
			</>
		);
	}

	//<editor-fold desc="View (state, didMount, ...) methods" defaultstate="collapsed">

	private initState(): LayoutState
	{
		return {
			isAvatarCardOpen: false,
			isModalOpen: false,
			isThemeDark: true,
		};
	}

	//</editor-fold>

	//<editor-fold desc="Private Method" defaultstate="collapsed">

	private closeAvatarCardAction(): void
	{
		this.setState({ isAvatarCardOpen: false });
	};

	private handleAvatarClick(): void
	{
		this.setState((prevState: LayoutState) => ({ isAvatarCardOpen: !prevState.isAvatarCardOpen }));
	};

	private buildCardActionsContent()
	{
		return [
			[
				{
					name: 'Déconnexion',
					isClickable: true,
					icon: <BsBoxArrowRight/>,
					action: this.handleLogout.bind(this)
				}
			]
		];
	}

	private handleLogout(): void
	{
		const authContext = this.context as AuthContextType;
		if (authContext && authContext.logout) {
			authContext.logout();
			this.navigation('/auth');
		}
	}

	//</editor-fold>

}