import React, { ReactElement } from 'react';
import * as Icons from 'react-icons/lu';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { Link } from 'react-router-dom';
import { MenuAdminStyle } from '@/Modules/App/Style/Menus/MenuAdminStyle';

type IconName = keyof typeof Icons;

interface ComponentProps
{
	urlPath: string,
	label: string,
	iconName: IconName,
	location: any,
	onClick?: (event: any) => void
}

export default class MenuItem extends React.Component<ComponentProps, any>
{

	constructor(props: any)
	{
		super(props);
		this.state = {
			hoveredPath: null
		};
	}

	getStyle = (path: any) =>
	{
		const { location } = this.props;

		const activeStyle = {
			color: '#FF931E',
			backgroundColor: 'rgba(255, 147, 30, 0.1)',
			opacity: 1,
			borderRadius: CssVariableEnum['--border-radius-main']
		};

		return location.pathname === path ? activeStyle : {};
	};

	render(): ReactElement
	{
		const IconComponent = Icons[this.props.iconName];

		return (
			<Link to={ this.props.urlPath }>
				<li
					style={ {
						...MenuAdminStyle.menuItem('', this.props.urlPath, this.state.hoveredPath),
						...this.getStyle(this.props.urlPath)
					} }
					onMouseEnter={ () => this.setState({ hoveredPath: this.props.urlPath }) }
					onMouseLeave={ () => this.setState({ hoveredPath: null }) }
					onClick={ this.onClick.bind(this) }
				>
					{ IconComponent && <IconComponent fontSize={ 18 }/> }
					<span style={ { marginLeft: 5 } }>{ this.props.label }</span>
				</li>
			</Link>
		);
	}

	private onClick(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void
	{
		if (this.props.onClick) {
			event.preventDefault();
			this.props.onClick(event);
		}
	}
}