import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class MenuAdminStyle
{
	static menuItem(
		padding: string = '7px 9px',
		hoveredId?: number | string | null,
		currentHoveredId?: number| string | null
	): CSSProperties
	{
		const isHovered: boolean = hoveredId === currentHoveredId;
		return {
			padding: (padding) ? padding : '7px 9px',
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer',
			backgroundColor: isHovered ? 'rgba(255, 147, 30, 0.1)' : 'transparent',
			color: isHovered ? '#FF931E' : 'inherit' ,
			transition: 'background-color 0.3s ease',
			borderRadius: '8px'
		};
	}

	static menuItemSeparateCategory(): CSSProperties
	{
		return {
			marginTop: 30,
			marginBottom: 10,
			fontSize: 12,
			fontWeight: 500,
			color: CssVariableEnum['--color-grey-500']
		};
	}
}