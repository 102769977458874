import React, { ReactElement, createContext } from 'react';
import { BsXLg } from 'react-icons/bs';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { ButtonType } from '@/Modules/App/Components/Atom/Button/Type/ButtonType';
import ModalStyle from '@/Modules/App/Style/Components/ModalStyle';
import { FontStyle } from '@/Modules/App/Style/Base/FontStyle';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import { Spinner } from 'react-bootstrap';

export interface ModalContextType
{
	isLoading: (isLoading: boolean) => void,
	content: (title: string | null, content: ReactElement) => void,
	isOpen: (isOpen: boolean) => void,
	errorMessage: (errorMessage: string | null) => void,
	actions: (actions: {
		label: string,
		buttonType?: ButtonType,
		action: ((event: any) => Promise<void>) | ((event?: any) => any)
	}[]) => void
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export default class ModalProvider extends React.Component<any, {
	isLoading: boolean,
	modalStack: {
		isLoading: boolean,
		isOpen: boolean,
		title: string | null,
		content: ReactElement | null,
		errorMessage: string | null,
		actions: {
			label: string | null,
			buttonType?: ButtonType,
			action: ((event: any) => Promise<void>) | ((event?: any) => any)
		}[] | null,
		modalRef: React.RefObject<HTMLDivElement> | null
	}[]
}>
{

	constructor(props: any)
	{
		super(props);

		// state
		this.state = {
			isLoading: false,
			modalStack: [],
		};

		// Bind
		this.isLoadingUpdated = this.isLoadingUpdated.bind(this);
		this.modalContent = this.modalContent.bind(this);
		this.isOpenModal = this.isOpenModal.bind(this);
		this.handleErrorMessage = this.handleErrorMessage.bind(this);
		this.modalActions = this.modalActions.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidUpdate(prevProps: any, prevState: any): void
	{
		if (this.state.modalStack.length > prevState.modalStack.length) {
			const newModal = this.state.modalStack[this.state.modalStack.length - 1];
			if (newModal.modalRef && newModal.modalRef.current) {
				window.addEventListener('click', this.handleClickOutside);
			}
		} else if (this.state.modalStack.length < prevState.modalStack.length) {
			if (prevState.modalStack.length > 0) {
				const oldModal = prevState.modalStack[prevState.modalStack.length - 1];
				if (oldModal.modalRef && oldModal.modalRef.current) {
					window.removeEventListener('click', this.handleClickOutside);
				}
			}
		}
	}


	render(): ReactElement
	{
		return (
			<ModalContext.Provider
				value={ {
					isLoading: this.isLoadingUpdated,
					actions: this.modalActions,
					content: this.modalContent,
					isOpen: this.isOpenModal,
					errorMessage: this.handleErrorMessage
				} }>
				{ this.state.modalStack.map((modal, index) => modal.isOpen && (
					<div key={ index } style={ ModalStyle.backdrop() }>
						<div ref={ modal.modalRef } style={ ModalStyle.container() }>
							{ modal.isLoading && (
								<div
									style={{
										position: 'absolute',
										width: '100%',
										height: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										background: 'rgba(255, 255, 255, 0.7)',
										color: CssVariableEnum['--color-grey-900'],
										borderRadius: 20,
										zIndex: 1510
									}}
								>
									<Spinner animation="border" variant="dark"/>
								</div>
							)}
							<div style={ {
								width: '100%',
								padding: '10px 20px 5px 20px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: (modal.title) ? 'space-between' : 'flex-end'
							} }>
								<div style={ { height: '100%', display: 'flex', alignItems: 'center' } }>
									{ modal.title &&
                    <div style={ FontStyle.h4() }> { modal.title }</div>
									}
								</div>
								<div>
									{ modal.actions && modal.actions.map(item => (
										<Button
											key={ item.label }
											type={ item.buttonType }
											onClick={ (event?: any) =>
											{
												if (typeof item.action === 'function') {
													item.action(event);
												} else {
													return item.action;
												}
											} }>
											{ item.label }
										</Button>
									)) }
									<Button
										type={ 'default' }
										iconLeft={ <BsXLg/> }
										onClick={ () => this.setState(prevState => ({
											modalStack: prevState.modalStack.slice(0, -1)
										})) }
									/>
								</div>
							</div>
							{ modal.errorMessage &&
                <div style={ ModalStyle.errorMessage() }>
                  <div style={ {
										padding: '5px 20px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									} }>
										{ modal.errorMessage }
                    <Button
                      type={ 'closed-message' }
                      iconLeft={ <BsXLg/> }
                      onClick={ (event: any) =>
											{
												event.stopPropagation();
												this.setState(prevState =>
												{
													const modalStack = [...prevState.modalStack];
													const lastModal = modalStack[modalStack.length - 1];
													if (lastModal) {
														lastModal.errorMessage = null;
													}
													return { modalStack };
												});
											} }
                    />
                  </div>
                </div>
							}
							{ modal.content }
						</div>
					</div>
				)) }

				{ this.props.children }
			</ModalContext.Provider>
		);
	}

	private isOpenModal(isOpen: boolean): void
	{
		if (isOpen) {
			const lastModal = this.state.modalStack[this.state.modalStack.length - 1];
			if (lastModal) {
				this.setState(prevState => ({
					modalStack: prevState.modalStack.map((modal, index) =>
						index === prevState.modalStack.length - 1 ? { ...modal, isOpen: true } : modal
					)
				}));
			}
		} else {
			this.setState(prevState => ({
				modalStack: prevState.modalStack.slice(0, -1)
			}));
		}
	}

	private handleErrorMessage(errorMessage: string | null): void
	{
		this.setState(prevState =>
		{
			const modalStack = [...prevState.modalStack];
			if (modalStack.length > 0) {
				modalStack[modalStack.length - 1].errorMessage = errorMessage;
			}
			return { modalStack };
		});
	}

	private modalActions(actions: {
		label: string,
		action: ((event: any) => Promise<void>) | ((event?: any) => any)
	}[]): void
	{
		this.setState(prevState =>
		{
			const modalStack = [...prevState.modalStack];
			const lastModal = modalStack[modalStack.length - 1];
			if (lastModal) {
				lastModal.actions = actions;
			}
			return { modalStack };
		});
	}

	private modalContent(title: string | null, content: ReactElement): void
	{
		this.setState(prevState => ({
			modalStack: [
				...prevState.modalStack,
				{
					isOpen: true,
					title,
					content,
					errorMessage: null,
					actions: [],
					modalRef: React.createRef<HTMLDivElement>(),
					isLoading: false
				}
			]
		}));
	}

	private handleClickOutside(event: MouseEvent): void
	{
		const modalIndex = this.state.modalStack.length - 1;
		const modal = this.state.modalStack[modalIndex];
		if (modal && modal.modalRef
			&& modal.modalRef.current
			&& !modal.modalRef.current.contains(event.target as Node)
		) {
			if (this.canCloseModal(modalIndex)) {
				this.setState(prevState =>
				{
					const modalStack = prevState.modalStack.slice(0, -1);
					return { modalStack };
				});
			}
		}
	}

	private isLoadingUpdated(isLoading: boolean): void
	{
		this.setState(prevState =>
		{
			const modalStack = [...prevState.modalStack];
			const lastModal = modalStack[modalStack.length - 1];
			if (lastModal) {
				lastModal.isLoading = isLoading;
			}
			return { modalStack };
		});
	}


	private canCloseModal(modalIndex: number): boolean
	{
		const modal = this.state.modalStack[modalIndex];
		if (modal && modal.modalRef?.current) {
			const inputs = modal.modalRef.current.querySelectorAll('input');
			const selectors = modal.modalRef.current.querySelectorAll('.custom-select');

			const inputArray = Array.from(inputs);
			for (let input of inputArray) {
				if (input.value.trim() !== '') return false;
			}

			const selectorArray = Array.from(selectors);
			for (let selector of selectorArray) {
				let textContent = selector.textContent ?? '';
				if (textContent.trim() !== 'Sélectionner une Option' && textContent.trim() !== '') {
					return false;
				}
			}
		}

		return true;
	}

}


