import { CSSProperties } from 'react';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';

export class LegalNoticePriceStyle
{
  static mainContainerStyle(isPriceRenderOpen: boolean): CSSProperties
  {
    return {
      position: 'fixed',
      bottom: 0,
      right: 41,
      width: '555px',
      backgroundColor: CssVariableEnum['--color-white'],
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px',
      border: `1px solid ${ CssVariableEnum['--color-grey-200'] }`,
      padding: (isPriceRenderOpen) ? '20px' : '7px',
      height: (isPriceRenderOpen) ? 'auto' : '50px'
    };
  }

  static bodyContainerStyle(): CSSProperties
  {
    return {
      display: 'flex',
      height: '100%',
      width: '100%',
      alignItems: 'flex-end',
      gap: '20px'
    };
  }

  static compactContainerStyle(width: string): CSSProperties
  {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: CssVariableEnum['--color-grey-800'],
      borderRadius: '6px',
      color: CssVariableEnum['--color-grey-200'],
      height: 32,
      padding: '0 10px',
      marginTop: '5px',
      width: width
    };
  }

  static subRenderContainerStyle(): CSSProperties
  {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: CssVariableEnum['--color-grey-800'],
      borderRadius: '6px',
      color: CssVariableEnum['--color-grey-200'],
      height: 53,
      padding: '0 10px',
      marginTop: '5px',
      width: '100%'
    };
  }

  static fullPriceLineStyle(): CSSProperties
  {
    return {
      fontSize: 12,
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center'
    };
  }

  static fullPriceFormLineStyle(): CSSProperties
  {
    return {
      fontSize: 12,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    };
  }

  static titlePriceLineStyle(): CSSProperties
  {
    return {
      display: 'flex',
      justifyContent: 'start'
    };
  }

  static priceContainerStyle(): CSSProperties
  {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '150px'
    };
  }

  static displayFlexBetween(): CSSProperties
  {
    return {
      display: 'flex',
      justifyContent: 'space-between'
    };
  }
}