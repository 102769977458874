import React from 'react';
import LocalStorageService from '@/Service/Common/LocalStorageService';
import { UserRoleEnum } from '@/Enum/UserRoleEnum';

export default class NotFoundView extends React.Component
{
  render() {
    const localStorageUser: any = LocalStorageService.getUserLocalStorage();
    return (
      <>
        <div className="not-found-container">
          <h1>404 - Page non trouvée</h1>
          <p>Désolé, la page que vous cherchez n'existe pas.</p>
          <a
            href={ this.handleHref(localStorageUser) }
          >
            Retour à l'accueil
          </a>
        </div>
      </>
    );
  }

  private handleHref(localStorageUser: any)
  {
    if (!localStorageUser) {
      return '/';
    }

    if (localStorageUser.userRole && localStorageUser.userRole === UserRoleEnum.ROLE_ADMIN) {
      return '/admin';
    } else {
      return '/';
    }
  }
}
