import React, { ReactElement } from 'react';
import Title from '@/Modules/App/Components/Atom/Title/Title';
import Input from '@/Modules/App/Components/Atom/Form/Input/Input';
import { FormBuilderCategoryInterface } from '@/Modules/FormBuilder/Interface/FormBuilderCategoryInterface';


interface ComponentProps
{
  formData: any,
  category: FormBuilderCategoryInterface|null,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  isConsumerFormComplete: boolean
}

export default class ConsumerAddressFormComponent extends React.Component<ComponentProps, any>
{
  render(): ReactElement
  {
    return (
      <>
        <Title type={ 'h6' } borderBottom={ true }> Siège Social </Title>
        <div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
          <div style={ { flex: '0 1 10%' } }>
            <Input
              type="text"
              style={ { display: 'flex' } }
              label="Numéro"
              name="address.number"
              value={ this.props.formData.address.number || '' }
              onChange={ this.props.onChange }
              disabled={ !this.props.isConsumerFormComplete }
            />
          </div>
          <div style={ { flex: '1 0 50%' } }>
            <Input
              type="text"
              style={ { display: 'flex' } }
              label="Adresse"
              name="address.street"
              value={ this.props.formData.address.street || '' }
              onChange={ this.props.onChange }
              disabled={ !this.props.isConsumerFormComplete }
            />
          </div>
        </div>
        <div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
          <Input
            type="text"
            style={ { display: 'flex' } }
            label="Complément d'adresse"
            name="address.additionalData"
            value={ this.props.formData.address.additionalData || '' }
            onChange={ this.props.onChange }
            disabled={ !this.props.isConsumerFormComplete }
          />
        </div>
        <div style={ { display: 'flex', gap: '20px', marginBottom: '15px', width: '100%' } }>
          <div style={ { display: 'inline-flex' } }>
            <Input
              type="text"
              label="Code Postal"
              name="address.zipCode"
              value={ this.props.formData.address.zipCode || '' }
              onChange={ this.props.onChange }
              disabled={ !this.props.isConsumerFormComplete }
            />
          </div>
          <Input
            type="text"
            label="Ville"
            name="address.city"
            value={ this.props.formData.address.city || '' }
            onChange={ this.props.onChange }
            disabled={ !this.props.isConsumerFormComplete }
          />
        </div>
      </>
    );
  }

  //<editor-fold desc="Private methods" defaultstate="collapsed">
  //</editor-fold>
}