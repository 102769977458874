import React from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { ApiPublicService } from '@/Service/Api/ApiPublicService';
import { UserInterface } from '@/Modules/User/Interface/UserInterface';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';

export default class AskForgotPasswordView extends React.Component
  <
    {  flashMessageContext: FlashMessageContextType, navigation: NavigateFunction, token: string },
    { email: string, newPassword: string, user: UserInterface | null, errorMessage: string|null }
  >
{
  publicService: ApiPublicService;
  state = {
    email: '',
    newPassword: '',
    user: null,
    errorMessage: null
  };

  constructor(props: any)
  {
    super(props);

    // Services
    this.publicService = new ApiPublicService();

    // Bind
    this.submitNewPassword = this.submitNewPassword.bind(this);

    // Document title
    document.title = 'Publisur - Mot de passe oublié';
  }

  async componentDidMount()
  {
    try {
      const getUserFromToken = await this.publicService.checkAccountExistFromPasswordToken(this.props.token);
      if (getUserFromToken.errorMessage) {
        this.props.navigation('/not-found');
      } else {
        this.setState({ user: getUserFromToken as UserInterface });
      }
    } catch (error) {
      console.error("Failed to fetch user:", error);
    }
  }

  render()
  {
    return (
      <>
        { this.state.user && this.formForgotPassword(this.state.user) }

        <Link to="/auth" className="mt-5">
          Annuler
        </Link>
      </>
    );
  }

  private formForgotPassword(user: UserInterface)
  {
    return (
      <>
        <h2>👋 Bonjour, { user.lastname } { user.firstname }</h2>
        <p className="mt-4 mb-4">Merci de renseigner votre nouveau mot de passe</p>

        { this.state.errorMessage
          &&<div className="error-message" role="alert">{ this.state.errorMessage }</div>
        }
        <Form className="w-25" method="post" onSubmit={ this.submitNewPassword }>
          <Form.Group className="block-field">
            <Form.Control
              type="password"
              name="newPassword"
              placeholder="Votre nouveau mot de passe"
              className="input"
              value={ this.state.newPassword }
              onChange={ (event) => this.onChangeNewPassword(event) }
              required
            />
          </Form.Group>
          <div className="input-submit">
            <Button type="submit" variant="primary" className="btn-primary--blue">
              Changer de mot de passe
            </Button>
          </div>
        </Form>
      </>
    );
  }

  private async submitNewPassword(event: React.FormEvent<HTMLFormElement>)
  {
    event.preventDefault();

    try {
      const response = await this.publicService.forgotPassword(
        { password: this.state.newPassword },
        this.props.token
      );

      if (response.errorMessage) {
        this.setState({ errorMessage: response.errorMessage });
        return;
      }

      // Set Flash message
      this.props.flashMessageContext.flashMessage(
        'Mot de passe bien enregistré',
        'Votre nouveau mot de passe a été enregistré avec succés, connectez-vous avec votre nouveau mot de passe',
        'success'
      );
      // navigate
      this.props.navigation('/auth');
      this.setState({ errorMessage: null, user: response });
    } catch (error) {
      console.log(error);
    }
  }

  private onChangeNewPassword(event: React.ChangeEvent<any>): void
  {
    this.setState({ newPassword: event.target.value });
  }
}
