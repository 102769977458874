import React from 'react';
import { FlashMessageProviderState } from '@/Provider/Interface/FlashMessage/FlashMessageProviderState';
import { BsXLg } from 'react-icons/bs';
import { FlashMessageContextType } from '@/Provider/Interface/FlashMessage/FlashMessageContextType';
import { CssVariableEnum } from '@/Enum/CssVariableEnum';
import Button from '@/Modules/App/Components/Atom/Button/Button';
import { LuXCircle, LuCheckCircle } from "react-icons/lu";

export const FlashMessageContext = React.createContext<FlashMessageContextType | null>(null);

export class FlashMessageProvider extends React.Component<any, FlashMessageProviderState>
{
	private fadeOutTimeout: NodeJS.Timeout | null = null;
	private clearMessageTimeout: NodeJS.Timeout | null = null;

	constructor(props: any)
	{
		super(props);

		this.state = {
			message: { text: null, type: 'success' },
			fadeOut: false,
		};

		// Bind
		this.setFlashMessage = this.setFlashMessage.bind(this);
	}

	setFlashMessage(message: string, additionalMessage?: string, type: 'success' | 'error' | 'info' = 'success'): void
	{
		// Clear previous TimeOut
		if (this.fadeOutTimeout) clearTimeout(this.fadeOutTimeout);
		if (this.clearMessageTimeout) clearTimeout(this.clearMessageTimeout);

		this.setState({
			message: {
				text: message,
				additional: additionalMessage,
				type: type
			},
			fadeOut: false
		});

		// Config Timeout
		this.fadeOutTimeout = setTimeout(() =>
		{
			this.setState({ fadeOut: true });
		}, 4500);

		// Clear message
		this.clearMessageTimeout = setTimeout(() =>
		{
			this.setState({ message: { text: null, type: 'success' }, fadeOut: false });
		}, 5000);
	}

	render()
	{
		// const
		const successColorBorder02 = '1px solid rgba(18,183,106, 0.2)';
		const successColorBorder04 = '1px solid rgba(18,183,106, 0.4)';
		const errorColorBorder02 = '1px solid rgba(217,45,32, 0.2)';
		const errorColorBorder04 = '1px solid rgba(217,45,32, 0.4)';


		return (
			<FlashMessageContext.Provider value={ { flashMessage: this.setFlashMessage } }>
				{ this.props.children }
				{ this.state.message && this.state.message.text && (
					<div style={ {
						position: 'fixed',
						bottom: '40px',
						right: '20px',
						minHeight: '80px',
						color: CssVariableEnum['--color-grey-25'],
						padding: '12px 15px',
						borderRadius: '6px',
						boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
						background: CssVariableEnum['--color-grey-900'],
						zIndex: 10000,
						transition: 'all 0.3s ease-in-out',
						display: 'flex',
						alignItems: 'flex-start',
						opacity: this.state.fadeOut ? 0 : 1,
						transform: this.state.fadeOut ? 'translateX(100) translateY(20)' : ''
					} }>
						<div style={ { display: 'flex', gap: '20px' } }>
							<div
								style={ {
									position: 'relative',
									justifyContent: 'center',
									alignItems: 'flex-start',
									height: '100%',
									width: '20px'
								} }>
								<div style={ {
									position: 'absolute',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									border: (this.state.message.type === 'success' ) ? successColorBorder02 : errorColorBorder02,
									height: '25px',
									width: '25px',
									borderRadius: '50%',
								} }>
									<div style={ {
										position: 'absolute',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: (this.state.message.type === 'success' ) ? successColorBorder04 : errorColorBorder04,
										height: '20px',
										width: '20px',
										borderRadius: '50%',
									} }>
										{ this.state.message.type === 'success' &&
                      <LuCheckCircle fill={ CssVariableEnum['--color-green-500'] }/>
										}
										{ this.state.message.type === 'error' &&
                      <LuXCircle  fill={ CssVariableEnum['--color-error-600'] } />
										}
									</div>
								</div>
							</div>
							<div style={ {
								fontSize: '14px',
								fontWeight: 600
							} }>
								{ this.state.message.text }
								<div style={ {
									fontSize: '14px',
									fontWeight: 400,
									color: CssVariableEnum['--color-grey-500'],
								} }>
									{ this.state.message.additional }
								</div>
							</div>
							<Button
								type={ 'closed-dark' }
								iconLeft={ <BsXLg/> }
								onClick={ () => this.setState({ fadeOut: true }) }
							/>
						</div>
					</div>
				) }
			</FlashMessageContext.Provider>
		);
	}
}